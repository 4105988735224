import React, { useEffect, useState } from 'react'
import { Typography, Paper, CardContent, Button, IconButton, Grid, ListItem, CircularProgress, Divider } from "@material-ui/core";
import { KeyboardBackspace } from '@material-ui/icons'
import { appsLoginStyles } from './styles';
import { useParams, useHistory } from 'react-router';
import { AuthServiceClient } from '../../protos/auth_grpc_web_pb';
import { AuthServerAddress } from '../../protos/server';
import GetMetadata from '../customComponents/getMetadata';
import { GeneralRequest } from '../../protos/auth_pb';
import { useDispatch, useSelector } from 'react-redux';

export default function AppLoginDetails() {
    const classes = appsLoginStyles();
    const { param3 } = useParams()
    const [stat, setStat] = useState(2)
    const [btnStat, setBtnStat] = useState(0)
    const [details, setDetails] = useState({});
    const userData = useSelector(state => state.userData)
    const dispatch = useDispatch()
    const history = useHistory()
    const client = new AuthServiceClient(AuthServerAddress, null, null)
    const md = GetMetadata()
    md.authorization = userData.token

    const getDetails = () => {
        const req = new GeneralRequest();
        req.setMessage(param3)
        client.appLoginDetails(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setStat(-1)
            } else {
                const res = response.toObject()
                setDetails(res)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        getDetails();
    }, [])

    const terminateAppLogin = () => {
        if (btnStat === 0) {
            setBtnStat(2)
            const req = new GeneralRequest();
            req.setMessage(param3)
            client.blockAppLogin(req, md, (err, response) => {
                if (err) {
                    console.log(err)
                    setBtnStat(-1)
                } else {
                    const res = response.toObject()
                    if (res.message === "Success") {
                        setBtnStat(1)
                        dispatch({ type: 'REMOVE_APP_LOGINS' })
                        return
                    }
                    setBtnStat(-1)
                }
            })
        }
    }

    const renderTermBtn = () => {
        if (btnStat === 0) {
            return "Terminate Access"
        } else if (btnStat === 1) {
            return "Access Terminated"
        } else if (btnStat === 2) {
            return <CircularProgress size={25} />
        } else {
            return "Error"
        }
    }

    const render = () => {
        if (stat === 2) {
            return <center><CircularProgress size={25} /></center>
        } else if (stat === 0) {
            return < >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <IconButton
                        style={{ marginRight: '10px' }}
                        onClick={() => history.goBack()}
                    >
                        <KeyboardBackspace />
                    </IconButton>
                    <Typography variant="h6">
                        App Login Details
                    </Typography>
                </Grid>
                <br />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <img alt="Client Icon" src={details.clientlogo} className={classes.loginDetailsLogo} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6">
                                {details.clientname}
                            </Typography>
                            <Typography variant="subtitle2">
                                {details.companyname}
                            </Typography>
                            <Typography variant="subtitle1" color="error">
                                {/* {details.mytrusted}
                                <br />
                                {details.myowned} */}
                                {
                                    details.blocked ? "Access Blocked" : " "
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    {/* <Typography>
                        <Link href="#">
                            App's Website
                        </Link>
                        <br />
                        <Link href={details.terms}>
                            Terms and Condition
                        </Link>
                        <br />
                        <Link href={details.privacypolicy}>
                            Privacy Policy
                        </Link>
                    </Typography> */}
                    <br />
                    <Typography>
                        Logged in at {details.createdat}
                        <br />
                        {details.blocked ? <>Blocked on {details.blockedat}</> : <>Expiry at {details.expiryat}</>}
                    </Typography>
                    <br />
                    <Divider />
                    <br />
                    <Typography variant="h6">
                        Permissions :
                    </Typography>
                    <br />
                    <ListItem className={classes.permsList}>
                        {
                            details.grantedpermsList.map(p => {
                                return <React.Fragment>
                                    {p}
                                    <br />
                                </React.Fragment>
                            })
                        }
                    </ListItem>
                </CardContent>
                {
                    details.blocked ? <></> : <>
                        <br />
                        <Divider />
                        <br />
                        <CardContent>
                            <Button variant="outlined" color="primary" disabled={btnStat !== 0} onClick={terminateAppLogin}>
                                {renderTermBtn()}
                            </Button>
                        </CardContent>
                    </>
                }
            </>
        } else {
            return <Typography color="textSecondary">
                Something went wrong
            </Typography>
        }
    }

    return <Paper className={classes.loginDetailsPaper}>
        {render()}
    </Paper>
}