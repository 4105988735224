/**
 * @fileoverview gRPC-Web generated client stub for mazey.support
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.mazey = {};
proto.mazey.support = require('./support_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.mazey.support.SupportServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.mazey.support.SupportServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.support.CreateTicketRequest,
 *   !proto.mazey.support.GeneralResponse>}
 */
const methodDescriptor_SupportService_CreateTicket = new grpc.web.MethodDescriptor(
  '/mazey.support.SupportService/CreateTicket',
  grpc.web.MethodType.UNARY,
  proto.mazey.support.CreateTicketRequest,
  proto.mazey.support.GeneralResponse,
  /**
   * @param {!proto.mazey.support.CreateTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.support.CreateTicketRequest,
 *   !proto.mazey.support.GeneralResponse>}
 */
const methodInfo_SupportService_CreateTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.support.GeneralResponse,
  /**
   * @param {!proto.mazey.support.CreateTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.support.CreateTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.support.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.support.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.support.SupportServiceClient.prototype.createTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.support.SupportService/CreateTicket',
      request,
      metadata || {},
      methodDescriptor_SupportService_CreateTicket,
      callback);
};


/**
 * @param {!proto.mazey.support.CreateTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.support.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.support.SupportServicePromiseClient.prototype.createTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.support.SupportService/CreateTicket',
      request,
      metadata || {},
      methodDescriptor_SupportService_CreateTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.support.GeneralRequest,
 *   !proto.mazey.support.GetTicketsResponse>}
 */
const methodDescriptor_SupportService_GetTickets = new grpc.web.MethodDescriptor(
  '/mazey.support.SupportService/GetTickets',
  grpc.web.MethodType.UNARY,
  proto.mazey.support.GeneralRequest,
  proto.mazey.support.GetTicketsResponse,
  /**
   * @param {!proto.mazey.support.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GetTicketsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.support.GeneralRequest,
 *   !proto.mazey.support.GetTicketsResponse>}
 */
const methodInfo_SupportService_GetTickets = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.support.GetTicketsResponse,
  /**
   * @param {!proto.mazey.support.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GetTicketsResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.support.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.support.GetTicketsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.support.GetTicketsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.support.SupportServiceClient.prototype.getTickets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.support.SupportService/GetTickets',
      request,
      metadata || {},
      methodDescriptor_SupportService_GetTickets,
      callback);
};


/**
 * @param {!proto.mazey.support.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.support.GetTicketsResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.support.SupportServicePromiseClient.prototype.getTickets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.support.SupportService/GetTickets',
      request,
      metadata || {},
      methodDescriptor_SupportService_GetTickets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.support.GeneralRequest,
 *   !proto.mazey.support.GetTicketDetailsResponse>}
 */
const methodDescriptor_SupportService_GetTicketDetails = new grpc.web.MethodDescriptor(
  '/mazey.support.SupportService/GetTicketDetails',
  grpc.web.MethodType.UNARY,
  proto.mazey.support.GeneralRequest,
  proto.mazey.support.GetTicketDetailsResponse,
  /**
   * @param {!proto.mazey.support.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GetTicketDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.support.GeneralRequest,
 *   !proto.mazey.support.GetTicketDetailsResponse>}
 */
const methodInfo_SupportService_GetTicketDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.support.GetTicketDetailsResponse,
  /**
   * @param {!proto.mazey.support.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GetTicketDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.support.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.support.GetTicketDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.support.GetTicketDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.support.SupportServiceClient.prototype.getTicketDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.support.SupportService/GetTicketDetails',
      request,
      metadata || {},
      methodDescriptor_SupportService_GetTicketDetails,
      callback);
};


/**
 * @param {!proto.mazey.support.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.support.GetTicketDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.support.SupportServicePromiseClient.prototype.getTicketDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.support.SupportService/GetTicketDetails',
      request,
      metadata || {},
      methodDescriptor_SupportService_GetTicketDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.support.UpdateTicketRequest,
 *   !proto.mazey.support.GeneralResponse>}
 */
const methodDescriptor_SupportService_UpdateTicket = new grpc.web.MethodDescriptor(
  '/mazey.support.SupportService/UpdateTicket',
  grpc.web.MethodType.UNARY,
  proto.mazey.support.UpdateTicketRequest,
  proto.mazey.support.GeneralResponse,
  /**
   * @param {!proto.mazey.support.UpdateTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.support.UpdateTicketRequest,
 *   !proto.mazey.support.GeneralResponse>}
 */
const methodInfo_SupportService_UpdateTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.support.GeneralResponse,
  /**
   * @param {!proto.mazey.support.UpdateTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.support.UpdateTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.support.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.support.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.support.SupportServiceClient.prototype.updateTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.support.SupportService/UpdateTicket',
      request,
      metadata || {},
      methodDescriptor_SupportService_UpdateTicket,
      callback);
};


/**
 * @param {!proto.mazey.support.UpdateTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.support.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.support.SupportServicePromiseClient.prototype.updateTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.support.SupportService/UpdateTicket',
      request,
      metadata || {},
      methodDescriptor_SupportService_UpdateTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.support.GeneralRequest,
 *   !proto.mazey.support.GeneralResponse>}
 */
const methodDescriptor_SupportService_CloseTicket = new grpc.web.MethodDescriptor(
  '/mazey.support.SupportService/CloseTicket',
  grpc.web.MethodType.UNARY,
  proto.mazey.support.GeneralRequest,
  proto.mazey.support.GeneralResponse,
  /**
   * @param {!proto.mazey.support.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.support.GeneralRequest,
 *   !proto.mazey.support.GeneralResponse>}
 */
const methodInfo_SupportService_CloseTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.support.GeneralResponse,
  /**
   * @param {!proto.mazey.support.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.support.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.support.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.support.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.support.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.support.SupportServiceClient.prototype.closeTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.support.SupportService/CloseTicket',
      request,
      metadata || {},
      methodDescriptor_SupportService_CloseTicket,
      callback);
};


/**
 * @param {!proto.mazey.support.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.support.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.support.SupportServicePromiseClient.prototype.closeTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.support.SupportService/CloseTicket',
      request,
      metadata || {},
      methodDescriptor_SupportService_CloseTicket);
};


module.exports = proto.mazey.support;

