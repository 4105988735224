import React from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import PrimaryContact from './primaryContact';
import SocialContact from './socialContact';
import { useGetContacts } from '../../../customHooks/useGetContacts'

export default function Contact() {

    const [stat, contactDetails] = useGetContacts()

    const render = () => {
        if (stat === 2) {
            return <center>
                <CircularProgress size={30} />
            </center>
        } else if (stat === 0) {
            return <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={10}>
                    <PrimaryContact contactDetails={contactDetails} />
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <SocialContact contactDetails={contactDetails} />
                </Grid>
            </Grid>
        } else {
            return <Typography variant="body1" color="textSecondary" >
                Unable to load data
            </Typography>
        }
    }

    return render()

}