import React, { useState } from "react";
import './style.css';
import { Grid, Typography, Link as MLink } from "@material-ui/core";
import { Link } from 'react-router-dom';
import RegisterStep1 from "./step1";
import RegisterStep2 from "./step2";
import RegisterStep3 from "./step3";
import RegisterStep4 from "./step4";
import RegisterStep5 from "./step5";
import RegisterStep6 from "./step6";

export default function Register() {
	const [step, setStep] = useState(1);
	const [values, setValues] = useState({
		collegeCode: "",
		firstName: "",
		lastName: "",
		email: "",
		userID: "",
		userRole: "s",
		username: "",
		password: "",
	})

	const render = () => {
		switch (step) {
			case 1:
				return <RegisterStep1 values={values} setValues={setValues} setStep={setStep} />
			case 2:
				return <RegisterStep2 values={values} setValues={setValues} setStep={setStep} />
			case 3:
				return <RegisterStep3 values={values} setValues={setValues} setStep={setStep} />
			case 4:
				return <RegisterStep4 values={values} setValues={setValues} setStep={setStep} />
			case 5:
				return <RegisterStep5 setStep={setStep} />
			case 6:
				return <RegisterStep6 values={values} />
			default:
				return <></>
		}
	}

	const renderFooter = () => {
		if (step === 2) {
			return <>
				<Typography variant="body1" color="textSecondary">
					By proceeding with the registration,
					<br />
					you agree with our terms and conditions and privacy policy,
					<br />
					both of which can be found&nbsp;
					<MLink href="https://www.mazeyard.com/terms" >
						here
					</MLink>
				</Typography>
				<br />
			</>
		} else if (step === 5) {
			return <>
				<Typography variant="body1" color="textSecondary">
					Think something is wrong?
				</Typography>
				<MLink href="https://www.mazeyard.com/contact" className='link'>
					Contact us
				</MLink>
				<br />
			</>
		} else if (step === 6) {
			return <Link to="/" className='link'>
				<Typography variant="body1" color="primary">
					Back to Home
				</Typography>
			</Link>
		}
	}

	return <div className='register_container'>
		<Grid
			container
			direction="column"
			justify="space-between"
			alignItems="center"
			className='registerGrid'
		>
			<div className='header' />
			{render()}
			<div className='footer'>
				{renderFooter()}
			</div>
		</Grid>
	</div>
}