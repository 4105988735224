import { makeStyles } from '@material-ui/core/styles';

export const tabStyles = makeStyles((theme) => ({
    tabBar: {
        position: 'fixed',
        top: 62,
        height: 90,
        zIndex: 1,
        padding: '25px 0 16px 0',
        backgroundColor: theme.palette.background.page,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            top: 55,
            height: 75,
            padding: '15px 0 13px 0',
        },
    },
    tabViewComponent: {
        marginTop: 50,
    }
}));