import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from '../styles';
import CollegeInfo from './collegeInfo';

export default function Educational() {
    const classes = useStyles();

    return <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={10}>
                <CollegeInfo />
            </Grid>
            {/* <Grid item xs={12} md={10}>
                <Paper className={classes.paper}>

                </Paper>
            </Grid> */}
        </Grid>
    </div>
}