import React from "react";
import { Card, CardContent, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { useStyles } from "./styles";


export default function SetupStep2(props) {
    const classes = useStyles();

    const handleChange = (event) => {
        props.setAccUpdate({ ...props.accUpdate, ug: event.target.value })
    };

    return <React.Fragment>
        <Card className={classes.card} >
            <CardContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Gender</FormLabel>
                    <br />
                    <RadioGroup aria-label="gender" name="gender1" value={props.accUpdate.ug} onChange={handleChange}>
                        <FormControlLabel value="f" control={<Radio />} label="Female" />
                        <FormControlLabel value="m" control={<Radio />} label="Male" />
                        <FormControlLabel value="t" control={<Radio />} label="Transgender" />
                    </RadioGroup>
                </FormControl>
            </CardContent>
            <CardContent>
                <Button variant="contained" color="primary" onClick={() => props.setStep(3)}  >
                    Next
                </Button>
            </CardContent>
        </Card>
    </React.Fragment>
}