import React, { useState } from 'react'
import { useStyles } from '../styles';
import { Typography, TextField, Paper, Grid, CircularProgress, Button, InputAdornment } from '@material-ui/core';
import { DoneAll, Facebook, Instagram, LinkedIn, Twitter } from '@material-ui/icons';
import { AccountsServiceClient } from '../../../../protos/accounts_grpc_web_pb';
import { SetContactDetailsRequest } from '../../../../protos/accounts_pb';
import { AccountsServerAddress } from '../../../../protos/server';
import GetMetadata from '../../../customComponents/getMetadata';
import { useSelector } from 'react-redux';

export default function SocialContact(props) {
    const classes = useStyles();

    const [values, setValues] = useState({
        linkedin: props.contactDetails[2].contactvalue,
        facebook: props.contactDetails[3].contactvalue,
        instagram: props.contactDetails[4].contactvalue,
        twitter: props.contactDetails[5].contactvalue,
    })
    const [stat, setStat] = useState(0)
    const userData = useSelector(state => state.userData)

    const changeValue = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value })
        setStat(0)
    }

    const getContact = (i) => {
        switch (i) {
            case 0:
                return ["linkedin", values.linkedin]
            case 1:
                return ["facebook", values.facebook]
            case 2:
                return ["instagram", values.instagram]
            case 3:
                return ["twitter", values.twitter]
            default:
                return ["", ""]
        }
    }

    const handleSubmit = () => {
        setStat(2)
        const accClient = new AccountsServiceClient(AccountsServerAddress, null, null)
        var md = GetMetadata()
        for (var i = 0; i < 4; i++) {
            const req = new SetContactDetailsRequest();
            const cont = getContact(i)
            req.setContacttype(cont[0])
            req.setContactvalue(cont[1])

            md.authorization = userData.token
            accClient.setContactDetails(req, md, (err, response) => {
                if (err) {
                    console.log(err)
                    setStat(-1)
                } else {
                    setStat(1)
                }
            })
        }
    }

    const renderSubmitBtn = () => {
        if (stat === 0) {
            return <Button
                variant="contained"
                color="primary"
                className={classes.contactBtns}
                onClick={handleSubmit}
            >
                Update
            </Button>
        } else if (stat === 2) {
            return <Button variant="outlined" color="primary" disabled>
                <CircularProgress size={25} />
            </Button>
        } else if (stat === 1) {
            return <Button variant="outlined" color="primary" disabled>
                <DoneAll />
            </Button>
        } else {
            return <Button variant="outlined" disabled>
                Error
            </Button>
        }
    }

    return <React.Fragment >
        <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom color="textPrimary" align="left">
                Social Contact Details
            </Typography>
            <Typography variant="body2" color="textSecondary" align="left">
                Will be public on your profile
            </Typography>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={9}>
                    <TextField
                        className={classes.contactInput}
                        id="linkedin-id"
                        name="linkedin"
                        value={values.linkedin}
                        onChange={changeValue}
                        placeholder="LinkedIn profile id"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LinkedIn />&nbsp;&nbsp;/&nbsp;
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className={classes.contactInput}
                        id="facebook-id"
                        name="facebook"
                        value={values.facebook}
                        onChange={changeValue}
                        placeholder="Facebook profile id"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Facebook />&nbsp;&nbsp;/&nbsp;
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className={classes.contactInput}
                        id="instagram-id"
                        name="instagram"
                        value={values.instagram}
                        onChange={changeValue}
                        placeholder="Instagram handle"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Instagram />&nbsp;&nbsp;/&nbsp;
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className={classes.contactInput}
                        id="twitter-id"
                        name="twitter"
                        value={values.twitter}
                        onChange={changeValue}
                        placeholder="Twitter handle"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Twitter />&nbsp;&nbsp;/&nbsp;
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={9}>
                    {renderSubmitBtn()}
                </Grid>
            </Grid>
            <br />
        </Paper>
    </React.Fragment>
}