import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
            padding: -10
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.primary.main,
        // color: theme.palette.primary.main,
        // boxShadow: 'none',
        // borderBottom: '1px solid ' + theme.palette.divider,
    },
    appBarLogo: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    toolbarLogo: {
        height: '40px',
        marginTop: '7px',
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    sectionDesktop: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },

    // appbar
    logoutBtn: {
        color: theme.palette.error.main,
    },

    // sidebar
    selected: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    sideSubHead: {
        textAlign: 'left',
    },
}));