import React from 'react'
import { Tabs, Tab } from "@material-ui/core";
import { TabPanel, a11yProps } from '../customComponents/tabPanel';
import SwipeableViews from 'react-swipeable-views';
import { tabStyles } from './tabStyles';

export default function TabView(props) {
    const classes = tabStyles()
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleTabIndex = (index) => {
        setTabIndex(index);
    };

    return <React.Fragment>
        <Tabs
            value={tabIndex}
            onChange={handleTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className={classes.tabBar}
        >
            {
                props.labels.map((label, index) => {
                    return <Tab label={label} {...a11yProps(index)} key={index} />
                })
            }
        </Tabs>
        <SwipeableViews
            axis='x'
            index={tabIndex}
            onChangeIndex={handleTabIndex}
        >
            {
                props.viewComponents.map((comp, index) => {
                    return <div className={classes.tabViewComponent}>
                        <TabPanel value={tabIndex} index={index} key={index}>
                            {comp}
                        </TabPanel>
                    </div>
                })
            }
        </SwipeableViews>
    </React.Fragment>
}