import React from "react";
import { Card, CardContent, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useStyles } from "./styles";


export default function SetupStep3(props) {
    const classes = useStyles();

    const handleStream = (event) => {
        props.setAccUpdate({ ...props.accUpdate, stream: event.target.value });
    }

    const renderStreams = () => {
        return <FormControl className={classes.input}>
            <InputLabel id="stream-select-label">Select Stream</InputLabel>
            <Select
                labelId="stream-select-label"
                id="stream-select-label"
                value={props.accUpdate.stream}
                onChange={handleStream}
            >
                {
                    props.accData.usercollege.institutestreamsList.map((s) => {
                        return <MenuItem value={s.seref} key={s.seref} >{s.streamname}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    }

    const handleField = (event) => {
        props.setAccUpdate({ ...props.accUpdate, field: event.target.value })
    }

    const renderFields = () => {
        if (props.accUpdate.stream === "") {
            return <></>
        }
        return <FormControl className={classes.input}>
            <InputLabel id="field-select-label">Select Branch</InputLabel>
            <Select
                labelId="field-select-label"
                id="field-select-label"
                value={props.accUpdate.field}
                onChange={handleField}
            >
                {
                    props.accData.usercollege.institutefieldsList.map((b) => {
                        if (b.bid === props.accUpdate.stream) {
                            return <MenuItem value={b.beref} key={b.beref} >{b.fieldname}</MenuItem>
                        }
                        return <></>
                    })
                }
            </Select>
        </FormControl>
    }

    return <Card className={classes.card} >
        <CardContent>
            {renderStreams()}
            <br />
            <br />
            {renderFields()}
        </CardContent>
        <br />
        <CardContent>
            <Button
                variant="contained"
                color="primary"
                disabled={props.accUpdate.stream === "" || props.accUpdate.field === ""}
                onClick={() => props.setStep(4)}
            >
                Next
            </Button>
        </CardContent>
    </Card>
}