import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetActivityRequest } from '../../protos/auth_pb';
import { AuthServiceClient } from '../../protos/auth_grpc_web_pb';
import { AuthServerAddress } from '../../protos/server';
import GetMetadata from '../customComponents/getMetadata';

export function useGetActivities() {
    const [stat, setStat] = useState(2)
    const [activities, setActivities] = useState([])

    const userData = useSelector(state => state.userData)
    const securityActivities = useSelector(state => state.securityActivities)

    const dispatch = useDispatch()
    const authCient = new AuthServiceClient(AuthServerAddress, null, null)
    var md = GetMetadata()

    const fetchActivities = (token) => {
        const req = new GetActivityRequest();
        md.authorization = token

        authCient.getActivity(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setStat(-1)
            } else {
                const res = response.toObject()
                const setActs = { type: 'SET_ACTIVITIES', activities: res.useractivitiesList };
                dispatch(setActs);
                setActivities(res.useractivitiesList)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        if (securityActivities.length === 0) {
            fetchActivities(userData.token)
        } else {
            setActivities(securityActivities)
            setStat(0)
        }
    }, [])

    return [stat, activities]
}