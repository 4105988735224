/**
 * @fileoverview gRPC-Web generated client stub for mazey.accounts
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.mazey = {};
proto.mazey.accounts = require('./accounts_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.mazey.accounts.AccountsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.mazey.accounts.AccountsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.accounts.CheckAvailabilityRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodDescriptor_AccountsService_Availability = new grpc.web.MethodDescriptor(
  '/mazey.accounts.AccountsService/Availability',
  grpc.web.MethodType.UNARY,
  proto.mazey.accounts.CheckAvailabilityRequest,
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.CheckAvailabilityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.accounts.CheckAvailabilityRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodInfo_AccountsService_Availability = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.CheckAvailabilityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.accounts.CheckAvailabilityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.accounts.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.accounts.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.accounts.AccountsServiceClient.prototype.availability =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.accounts.AccountsService/Availability',
      request,
      metadata || {},
      methodDescriptor_AccountsService_Availability,
      callback);
};


/**
 * @param {!proto.mazey.accounts.CheckAvailabilityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.accounts.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.accounts.AccountsServicePromiseClient.prototype.availability =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.accounts.AccountsService/Availability',
      request,
      metadata || {},
      methodDescriptor_AccountsService_Availability);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.accounts.AccountSettingUpRequest,
 *   !proto.mazey.accounts.AccountSettingUpResponse>}
 */
const methodDescriptor_AccountsService_AccountSettingUp = new grpc.web.MethodDescriptor(
  '/mazey.accounts.AccountsService/AccountSettingUp',
  grpc.web.MethodType.UNARY,
  proto.mazey.accounts.AccountSettingUpRequest,
  proto.mazey.accounts.AccountSettingUpResponse,
  /**
   * @param {!proto.mazey.accounts.AccountSettingUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.AccountSettingUpResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.accounts.AccountSettingUpRequest,
 *   !proto.mazey.accounts.AccountSettingUpResponse>}
 */
const methodInfo_AccountsService_AccountSettingUp = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.accounts.AccountSettingUpResponse,
  /**
   * @param {!proto.mazey.accounts.AccountSettingUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.AccountSettingUpResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.accounts.AccountSettingUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.accounts.AccountSettingUpResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.accounts.AccountSettingUpResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.accounts.AccountsServiceClient.prototype.accountSettingUp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.accounts.AccountsService/AccountSettingUp',
      request,
      metadata || {},
      methodDescriptor_AccountsService_AccountSettingUp,
      callback);
};


/**
 * @param {!proto.mazey.accounts.AccountSettingUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.accounts.AccountSettingUpResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.accounts.AccountsServicePromiseClient.prototype.accountSettingUp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.accounts.AccountsService/AccountSettingUp',
      request,
      metadata || {},
      methodDescriptor_AccountsService_AccountSettingUp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.accounts.ContactUsRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodDescriptor_AccountsService_ContactUs = new grpc.web.MethodDescriptor(
  '/mazey.accounts.AccountsService/ContactUs',
  grpc.web.MethodType.UNARY,
  proto.mazey.accounts.ContactUsRequest,
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.ContactUsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.accounts.ContactUsRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodInfo_AccountsService_ContactUs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.ContactUsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.accounts.ContactUsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.accounts.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.accounts.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.accounts.AccountsServiceClient.prototype.contactUs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.accounts.AccountsService/ContactUs',
      request,
      metadata || {},
      methodDescriptor_AccountsService_ContactUs,
      callback);
};


/**
 * @param {!proto.mazey.accounts.ContactUsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.accounts.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.accounts.AccountsServicePromiseClient.prototype.contactUs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.accounts.AccountsService/ContactUs',
      request,
      metadata || {},
      methodDescriptor_AccountsService_ContactUs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.accounts.GeneralRequest,
 *   !proto.mazey.accounts.GetProfileResponse>}
 */
const methodDescriptor_AccountsService_GetProfile = new grpc.web.MethodDescriptor(
  '/mazey.accounts.AccountsService/GetProfile',
  grpc.web.MethodType.UNARY,
  proto.mazey.accounts.GeneralRequest,
  proto.mazey.accounts.GetProfileResponse,
  /**
   * @param {!proto.mazey.accounts.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GetProfileResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.accounts.GeneralRequest,
 *   !proto.mazey.accounts.GetProfileResponse>}
 */
const methodInfo_AccountsService_GetProfile = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.accounts.GetProfileResponse,
  /**
   * @param {!proto.mazey.accounts.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GetProfileResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.accounts.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.accounts.GetProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.accounts.GetProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.accounts.AccountsServiceClient.prototype.getProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.accounts.AccountsService/GetProfile',
      request,
      metadata || {},
      methodDescriptor_AccountsService_GetProfile,
      callback);
};


/**
 * @param {!proto.mazey.accounts.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.accounts.GetProfileResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.accounts.AccountsServicePromiseClient.prototype.getProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.accounts.AccountsService/GetProfile',
      request,
      metadata || {},
      methodDescriptor_AccountsService_GetProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.accounts.GeneralRequest,
 *   !proto.mazey.accounts.GetContactDetailsResponse>}
 */
const methodDescriptor_AccountsService_GetContactDetails = new grpc.web.MethodDescriptor(
  '/mazey.accounts.AccountsService/GetContactDetails',
  grpc.web.MethodType.UNARY,
  proto.mazey.accounts.GeneralRequest,
  proto.mazey.accounts.GetContactDetailsResponse,
  /**
   * @param {!proto.mazey.accounts.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GetContactDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.accounts.GeneralRequest,
 *   !proto.mazey.accounts.GetContactDetailsResponse>}
 */
const methodInfo_AccountsService_GetContactDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.accounts.GetContactDetailsResponse,
  /**
   * @param {!proto.mazey.accounts.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GetContactDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.accounts.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.accounts.GetContactDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.accounts.GetContactDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.accounts.AccountsServiceClient.prototype.getContactDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.accounts.AccountsService/GetContactDetails',
      request,
      metadata || {},
      methodDescriptor_AccountsService_GetContactDetails,
      callback);
};


/**
 * @param {!proto.mazey.accounts.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.accounts.GetContactDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.accounts.AccountsServicePromiseClient.prototype.getContactDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.accounts.AccountsService/GetContactDetails',
      request,
      metadata || {},
      methodDescriptor_AccountsService_GetContactDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.accounts.SetContactDetailsRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodDescriptor_AccountsService_SetContactDetails = new grpc.web.MethodDescriptor(
  '/mazey.accounts.AccountsService/SetContactDetails',
  grpc.web.MethodType.UNARY,
  proto.mazey.accounts.SetContactDetailsRequest,
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.SetContactDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.accounts.SetContactDetailsRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodInfo_AccountsService_SetContactDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.SetContactDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.accounts.SetContactDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.accounts.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.accounts.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.accounts.AccountsServiceClient.prototype.setContactDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.accounts.AccountsService/SetContactDetails',
      request,
      metadata || {},
      methodDescriptor_AccountsService_SetContactDetails,
      callback);
};


/**
 * @param {!proto.mazey.accounts.SetContactDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.accounts.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.accounts.AccountsServicePromiseClient.prototype.setContactDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.accounts.AccountsService/SetContactDetails',
      request,
      metadata || {},
      methodDescriptor_AccountsService_SetContactDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.accounts.GeneralRequest,
 *   !proto.mazey.accounts.GetUserBasicResponse>}
 */
const methodDescriptor_AccountsService_GetUserBasic = new grpc.web.MethodDescriptor(
  '/mazey.accounts.AccountsService/GetUserBasic',
  grpc.web.MethodType.UNARY,
  proto.mazey.accounts.GeneralRequest,
  proto.mazey.accounts.GetUserBasicResponse,
  /**
   * @param {!proto.mazey.accounts.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GetUserBasicResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.accounts.GeneralRequest,
 *   !proto.mazey.accounts.GetUserBasicResponse>}
 */
const methodInfo_AccountsService_GetUserBasic = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.accounts.GetUserBasicResponse,
  /**
   * @param {!proto.mazey.accounts.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GetUserBasicResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.accounts.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.accounts.GetUserBasicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.accounts.GetUserBasicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.accounts.AccountsServiceClient.prototype.getUserBasic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.accounts.AccountsService/GetUserBasic',
      request,
      metadata || {},
      methodDescriptor_AccountsService_GetUserBasic,
      callback);
};


/**
 * @param {!proto.mazey.accounts.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.accounts.GetUserBasicResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.accounts.AccountsServicePromiseClient.prototype.getUserBasic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.accounts.AccountsService/GetUserBasic',
      request,
      metadata || {},
      methodDescriptor_AccountsService_GetUserBasic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.accounts.GeneralRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodDescriptor_AccountsService_SetAbout = new grpc.web.MethodDescriptor(
  '/mazey.accounts.AccountsService/SetAbout',
  grpc.web.MethodType.UNARY,
  proto.mazey.accounts.GeneralRequest,
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.accounts.GeneralRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodInfo_AccountsService_SetAbout = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.accounts.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.accounts.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.accounts.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.accounts.AccountsServiceClient.prototype.setAbout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.accounts.AccountsService/SetAbout',
      request,
      metadata || {},
      methodDescriptor_AccountsService_SetAbout,
      callback);
};


/**
 * @param {!proto.mazey.accounts.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.accounts.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.accounts.AccountsServicePromiseClient.prototype.setAbout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.accounts.AccountsService/SetAbout',
      request,
      metadata || {},
      methodDescriptor_AccountsService_SetAbout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.accounts.ChangeUserPropertyRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodDescriptor_AccountsService_ChangeUserProperty = new grpc.web.MethodDescriptor(
  '/mazey.accounts.AccountsService/ChangeUserProperty',
  grpc.web.MethodType.UNARY,
  proto.mazey.accounts.ChangeUserPropertyRequest,
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.ChangeUserPropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.accounts.ChangeUserPropertyRequest,
 *   !proto.mazey.accounts.GeneralResponse>}
 */
const methodInfo_AccountsService_ChangeUserProperty = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.accounts.GeneralResponse,
  /**
   * @param {!proto.mazey.accounts.ChangeUserPropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.accounts.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.accounts.ChangeUserPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.accounts.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.accounts.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.accounts.AccountsServiceClient.prototype.changeUserProperty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.accounts.AccountsService/ChangeUserProperty',
      request,
      metadata || {},
      methodDescriptor_AccountsService_ChangeUserProperty,
      callback);
};


/**
 * @param {!proto.mazey.accounts.ChangeUserPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.accounts.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.accounts.AccountsServicePromiseClient.prototype.changeUserProperty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.accounts.AccountsService/ChangeUserProperty',
      request,
      metadata || {},
      methodDescriptor_AccountsService_ChangeUserProperty);
};


module.exports = proto.mazey.accounts;

