// source: accounts.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.mazey.accounts.AccountSettingUpRequest', null, global);
goog.exportSymbol('proto.mazey.accounts.AccountSettingUpResponse', null, global);
goog.exportSymbol('proto.mazey.accounts.ChangeUserPropertyRequest', null, global);
goog.exportSymbol('proto.mazey.accounts.CheckAvailabilityRequest', null, global);
goog.exportSymbol('proto.mazey.accounts.ContactDetails', null, global);
goog.exportSymbol('proto.mazey.accounts.ContactUsRequest', null, global);
goog.exportSymbol('proto.mazey.accounts.GeneralRequest', null, global);
goog.exportSymbol('proto.mazey.accounts.GeneralResponse', null, global);
goog.exportSymbol('proto.mazey.accounts.GetContactDetailsResponse', null, global);
goog.exportSymbol('proto.mazey.accounts.GetProfileResponse', null, global);
goog.exportSymbol('proto.mazey.accounts.GetUserBasicResponse', null, global);
goog.exportSymbol('proto.mazey.accounts.GetUserRequest', null, global);
goog.exportSymbol('proto.mazey.accounts.InstituteFields', null, global);
goog.exportSymbol('proto.mazey.accounts.InstituteStreams', null, global);
goog.exportSymbol('proto.mazey.accounts.PrimProperty', null, global);
goog.exportSymbol('proto.mazey.accounts.SetContactDetailsRequest', null, global);
goog.exportSymbol('proto.mazey.accounts.UserCollege', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.CheckAvailabilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.CheckAvailabilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.CheckAvailabilityRequest.displayName = 'proto.mazey.accounts.CheckAvailabilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.GetUserRequest.displayName = 'proto.mazey.accounts.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.GetUserBasicResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.GetUserBasicResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.GetUserBasicResponse.displayName = 'proto.mazey.accounts.GetUserBasicResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.GeneralResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.GeneralResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.GeneralResponse.displayName = 'proto.mazey.accounts.GeneralResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.AccountSettingUpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.AccountSettingUpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.AccountSettingUpRequest.displayName = 'proto.mazey.accounts.AccountSettingUpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.InstituteStreams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.InstituteStreams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.InstituteStreams.displayName = 'proto.mazey.accounts.InstituteStreams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.InstituteFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.InstituteFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.InstituteFields.displayName = 'proto.mazey.accounts.InstituteFields';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.UserCollege = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.accounts.UserCollege.repeatedFields_, null);
};
goog.inherits(proto.mazey.accounts.UserCollege, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.UserCollege.displayName = 'proto.mazey.accounts.UserCollege';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.AccountSettingUpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.AccountSettingUpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.AccountSettingUpResponse.displayName = 'proto.mazey.accounts.AccountSettingUpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.ContactUsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.ContactUsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.ContactUsRequest.displayName = 'proto.mazey.accounts.ContactUsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.GeneralRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.GeneralRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.GeneralRequest.displayName = 'proto.mazey.accounts.GeneralRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.ContactDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.ContactDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.ContactDetails.displayName = 'proto.mazey.accounts.ContactDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.GetContactDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.accounts.GetContactDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.mazey.accounts.GetContactDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.GetContactDetailsResponse.displayName = 'proto.mazey.accounts.GetContactDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.PrimProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.PrimProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.PrimProperty.displayName = 'proto.mazey.accounts.PrimProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.GetProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.GetProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.GetProfileResponse.displayName = 'proto.mazey.accounts.GetProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.SetContactDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.SetContactDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.SetContactDetailsRequest.displayName = 'proto.mazey.accounts.SetContactDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.accounts.ChangeUserPropertyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.accounts.ChangeUserPropertyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.accounts.ChangeUserPropertyRequest.displayName = 'proto.mazey.accounts.ChangeUserPropertyRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.CheckAvailabilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.CheckAvailabilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.CheckAvailabilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.CheckAvailabilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.CheckAvailabilityRequest}
 */
proto.mazey.accounts.CheckAvailabilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.CheckAvailabilityRequest;
  return proto.mazey.accounts.CheckAvailabilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.CheckAvailabilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.CheckAvailabilityRequest}
 */
proto.mazey.accounts.CheckAvailabilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.CheckAvailabilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.CheckAvailabilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.CheckAvailabilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.CheckAvailabilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.mazey.accounts.CheckAvailabilityRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.CheckAvailabilityRequest} returns this
 */
proto.mazey.accounts.CheckAvailabilityRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.mazey.accounts.CheckAvailabilityRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.CheckAvailabilityRequest} returns this
 */
proto.mazey.accounts.CheckAvailabilityRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ueref: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.GetUserRequest}
 */
proto.mazey.accounts.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.GetUserRequest;
  return proto.mazey.accounts.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.GetUserRequest}
 */
proto.mazey.accounts.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUeref(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUeref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user = 1;
 * @return {string}
 */
proto.mazey.accounts.GetUserRequest.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetUserRequest} returns this
 */
proto.mazey.accounts.GetUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ueref = 2;
 * @return {string}
 */
proto.mazey.accounts.GetUserRequest.prototype.getUeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetUserRequest} returns this
 */
proto.mazey.accounts.GetUserRequest.prototype.setUeref = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.GetUserBasicResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.GetUserBasicResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.GetUserBasicResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GetUserBasicResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ueref: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.GetUserBasicResponse}
 */
proto.mazey.accounts.GetUserBasicResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.GetUserBasicResponse;
  return proto.mazey.accounts.GetUserBasicResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.GetUserBasicResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.GetUserBasicResponse}
 */
proto.mazey.accounts.GetUserBasicResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUeref(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.GetUserBasicResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.GetUserBasicResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.GetUserBasicResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GetUserBasicResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUeref();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ueref = 1;
 * @return {string}
 */
proto.mazey.accounts.GetUserBasicResponse.prototype.getUeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetUserBasicResponse} returns this
 */
proto.mazey.accounts.GetUserBasicResponse.prototype.setUeref = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.mazey.accounts.GetUserBasicResponse.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetUserBasicResponse} returns this
 */
proto.mazey.accounts.GetUserBasicResponse.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.mazey.accounts.GetUserBasicResponse.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetUserBasicResponse} returns this
 */
proto.mazey.accounts.GetUserBasicResponse.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.GeneralResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.GeneralResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.GeneralResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GeneralResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.GeneralResponse}
 */
proto.mazey.accounts.GeneralResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.GeneralResponse;
  return proto.mazey.accounts.GeneralResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.GeneralResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.GeneralResponse}
 */
proto.mazey.accounts.GeneralResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.GeneralResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.GeneralResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.GeneralResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GeneralResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mazey.accounts.GeneralResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GeneralResponse} returns this
 */
proto.mazey.accounts.GeneralResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.AccountSettingUpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.AccountSettingUpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.AccountSettingUpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requesttype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ueref: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fieldref: jspb.Message.getFieldWithDefault(msg, 4, ""),
    streamref: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 6, ""),
    frontimg: jspb.Message.getFieldWithDefault(msg, 7, ""),
    backimg: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.AccountSettingUpRequest}
 */
proto.mazey.accounts.AccountSettingUpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.AccountSettingUpRequest;
  return proto.mazey.accounts.AccountSettingUpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.AccountSettingUpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.AccountSettingUpRequest}
 */
proto.mazey.accounts.AccountSettingUpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesttype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUeref(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldref(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamref(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrontimg(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackimg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.AccountSettingUpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.AccountSettingUpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.AccountSettingUpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequesttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUeref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFieldref();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStreamref();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFrontimg();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBackimg();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string requestType = 1;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.getRequesttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpRequest} returns this
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.setRequesttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ueref = 2;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.getUeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpRequest} returns this
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.setUeref = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpRequest} returns this
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fieldRef = 4;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.getFieldref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpRequest} returns this
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.setFieldref = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string streamRef = 5;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.getStreamref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpRequest} returns this
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.setStreamref = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string gender = 6;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpRequest} returns this
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string frontImg = 7;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.getFrontimg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpRequest} returns this
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.setFrontimg = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string backImg = 8;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.getBackimg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpRequest} returns this
 */
proto.mazey.accounts.AccountSettingUpRequest.prototype.setBackimg = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.InstituteStreams.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.InstituteStreams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.InstituteStreams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.InstituteStreams.toObject = function(includeInstance, msg) {
  var f, obj = {
    seref: jspb.Message.getFieldWithDefault(msg, 1, ""),
    streamname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.InstituteStreams}
 */
proto.mazey.accounts.InstituteStreams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.InstituteStreams;
  return proto.mazey.accounts.InstituteStreams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.InstituteStreams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.InstituteStreams}
 */
proto.mazey.accounts.InstituteStreams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeref(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.InstituteStreams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.InstituteStreams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.InstituteStreams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.InstituteStreams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeref();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreamname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Seref = 1;
 * @return {string}
 */
proto.mazey.accounts.InstituteStreams.prototype.getSeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.InstituteStreams} returns this
 */
proto.mazey.accounts.InstituteStreams.prototype.setSeref = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string StreamName = 2;
 * @return {string}
 */
proto.mazey.accounts.InstituteStreams.prototype.getStreamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.InstituteStreams} returns this
 */
proto.mazey.accounts.InstituteStreams.prototype.setStreamname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.InstituteFields.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.InstituteFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.InstituteFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.InstituteFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    bid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    beref: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.InstituteFields}
 */
proto.mazey.accounts.InstituteFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.InstituteFields;
  return proto.mazey.accounts.InstituteFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.InstituteFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.InstituteFields}
 */
proto.mazey.accounts.InstituteFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeref(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.InstituteFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.InstituteFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.InstituteFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.InstituteFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBeref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string BID = 1;
 * @return {string}
 */
proto.mazey.accounts.InstituteFields.prototype.getBid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.InstituteFields} returns this
 */
proto.mazey.accounts.InstituteFields.prototype.setBid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Beref = 2;
 * @return {string}
 */
proto.mazey.accounts.InstituteFields.prototype.getBeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.InstituteFields} returns this
 */
proto.mazey.accounts.InstituteFields.prototype.setBeref = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fieldName = 3;
 * @return {string}
 */
proto.mazey.accounts.InstituteFields.prototype.getFieldname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.InstituteFields} returns this
 */
proto.mazey.accounts.InstituteFields.prototype.setFieldname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.accounts.UserCollege.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.UserCollege.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.UserCollege.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.UserCollege} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.UserCollege.toObject = function(includeInstance, msg) {
  var f, obj = {
    ieref: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutestreamsList: jspb.Message.toObjectList(msg.getInstitutestreamsList(),
    proto.mazey.accounts.InstituteStreams.toObject, includeInstance),
    institutefieldsList: jspb.Message.toObjectList(msg.getInstitutefieldsList(),
    proto.mazey.accounts.InstituteFields.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.UserCollege}
 */
proto.mazey.accounts.UserCollege.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.UserCollege;
  return proto.mazey.accounts.UserCollege.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.UserCollege} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.UserCollege}
 */
proto.mazey.accounts.UserCollege.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIeref(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutename(value);
      break;
    case 3:
      var value = new proto.mazey.accounts.InstituteStreams;
      reader.readMessage(value,proto.mazey.accounts.InstituteStreams.deserializeBinaryFromReader);
      msg.addInstitutestreams(value);
      break;
    case 4:
      var value = new proto.mazey.accounts.InstituteFields;
      reader.readMessage(value,proto.mazey.accounts.InstituteFields.deserializeBinaryFromReader);
      msg.addInstitutefields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.UserCollege.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.UserCollege.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.UserCollege} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.UserCollege.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIeref();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutestreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mazey.accounts.InstituteStreams.serializeBinaryToWriter
    );
  }
  f = message.getInstitutefieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.mazey.accounts.InstituteFields.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Ieref = 1;
 * @return {string}
 */
proto.mazey.accounts.UserCollege.prototype.getIeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.UserCollege} returns this
 */
proto.mazey.accounts.UserCollege.prototype.setIeref = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string instituteName = 2;
 * @return {string}
 */
proto.mazey.accounts.UserCollege.prototype.getInstitutename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.UserCollege} returns this
 */
proto.mazey.accounts.UserCollege.prototype.setInstitutename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated InstituteStreams instituteStreams = 3;
 * @return {!Array<!proto.mazey.accounts.InstituteStreams>}
 */
proto.mazey.accounts.UserCollege.prototype.getInstitutestreamsList = function() {
  return /** @type{!Array<!proto.mazey.accounts.InstituteStreams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.accounts.InstituteStreams, 3));
};


/**
 * @param {!Array<!proto.mazey.accounts.InstituteStreams>} value
 * @return {!proto.mazey.accounts.UserCollege} returns this
*/
proto.mazey.accounts.UserCollege.prototype.setInstitutestreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mazey.accounts.InstituteStreams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.accounts.InstituteStreams}
 */
proto.mazey.accounts.UserCollege.prototype.addInstitutestreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mazey.accounts.InstituteStreams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.accounts.UserCollege} returns this
 */
proto.mazey.accounts.UserCollege.prototype.clearInstitutestreamsList = function() {
  return this.setInstitutestreamsList([]);
};


/**
 * repeated InstituteFields instituteFields = 4;
 * @return {!Array<!proto.mazey.accounts.InstituteFields>}
 */
proto.mazey.accounts.UserCollege.prototype.getInstitutefieldsList = function() {
  return /** @type{!Array<!proto.mazey.accounts.InstituteFields>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.accounts.InstituteFields, 4));
};


/**
 * @param {!Array<!proto.mazey.accounts.InstituteFields>} value
 * @return {!proto.mazey.accounts.UserCollege} returns this
*/
proto.mazey.accounts.UserCollege.prototype.setInstitutefieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.mazey.accounts.InstituteFields=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.accounts.InstituteFields}
 */
proto.mazey.accounts.UserCollege.prototype.addInstitutefields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.mazey.accounts.InstituteFields, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.accounts.UserCollege} returns this
 */
proto.mazey.accounts.UserCollege.prototype.clearInstitutefieldsList = function() {
  return this.setInstitutefieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.AccountSettingUpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.AccountSettingUpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.AccountSettingUpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    response: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userstatus: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userreference: jspb.Message.getFieldWithDefault(msg, 4, ""),
    usercollege: (f = msg.getUsercollege()) && proto.mazey.accounts.UserCollege.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.AccountSettingUpResponse}
 */
proto.mazey.accounts.AccountSettingUpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.AccountSettingUpResponse;
  return proto.mazey.accounts.AccountSettingUpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.AccountSettingUpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.AccountSettingUpResponse}
 */
proto.mazey.accounts.AccountSettingUpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserstatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserreference(value);
      break;
    case 5:
      var value = new proto.mazey.accounts.UserCollege;
      reader.readMessage(value,proto.mazey.accounts.UserCollege.deserializeBinaryFromReader);
      msg.setUsercollege(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.AccountSettingUpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.AccountSettingUpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.AccountSettingUpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserstatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserreference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUsercollege();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mazey.accounts.UserCollege.serializeBinaryToWriter
    );
  }
};


/**
 * optional string response = 1;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpResponse} returns this
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpResponse} returns this
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userStatus = 3;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.getUserstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpResponse} returns this
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.setUserstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string userReference = 4;
 * @return {string}
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.getUserreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.AccountSettingUpResponse} returns this
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.setUserreference = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional UserCollege UserCollege = 5;
 * @return {?proto.mazey.accounts.UserCollege}
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.getUsercollege = function() {
  return /** @type{?proto.mazey.accounts.UserCollege} */ (
    jspb.Message.getWrapperField(this, proto.mazey.accounts.UserCollege, 5));
};


/**
 * @param {?proto.mazey.accounts.UserCollege|undefined} value
 * @return {!proto.mazey.accounts.AccountSettingUpResponse} returns this
*/
proto.mazey.accounts.AccountSettingUpResponse.prototype.setUsercollege = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mazey.accounts.AccountSettingUpResponse} returns this
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.clearUsercollege = function() {
  return this.setUsercollege(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mazey.accounts.AccountSettingUpResponse.prototype.hasUsercollege = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.ContactUsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.ContactUsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.ContactUsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.ContactUsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.ContactUsRequest}
 */
proto.mazey.accounts.ContactUsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.ContactUsRequest;
  return proto.mazey.accounts.ContactUsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.ContactUsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.ContactUsRequest}
 */
proto.mazey.accounts.ContactUsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.ContactUsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.ContactUsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.ContactUsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.ContactUsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.mazey.accounts.ContactUsRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.ContactUsRequest} returns this
 */
proto.mazey.accounts.ContactUsRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.mazey.accounts.ContactUsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.ContactUsRequest} returns this
 */
proto.mazey.accounts.ContactUsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.GeneralRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.GeneralRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.GeneralRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GeneralRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.GeneralRequest}
 */
proto.mazey.accounts.GeneralRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.GeneralRequest;
  return proto.mazey.accounts.GeneralRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.GeneralRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.GeneralRequest}
 */
proto.mazey.accounts.GeneralRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.GeneralRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.GeneralRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.GeneralRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GeneralRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mazey.accounts.GeneralRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GeneralRequest} returns this
 */
proto.mazey.accounts.GeneralRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.ContactDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.ContactDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.ContactDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.ContactDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    contacttype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contactvalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ispublic: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.ContactDetails}
 */
proto.mazey.accounts.ContactDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.ContactDetails;
  return proto.mazey.accounts.ContactDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.ContactDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.ContactDetails}
 */
proto.mazey.accounts.ContactDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContacttype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactvalue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspublic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.ContactDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.ContactDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.ContactDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.ContactDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContacttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContactvalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIspublic();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string contactType = 1;
 * @return {string}
 */
proto.mazey.accounts.ContactDetails.prototype.getContacttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.ContactDetails} returns this
 */
proto.mazey.accounts.ContactDetails.prototype.setContacttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contactValue = 2;
 * @return {string}
 */
proto.mazey.accounts.ContactDetails.prototype.getContactvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.ContactDetails} returns this
 */
proto.mazey.accounts.ContactDetails.prototype.setContactvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isPublic = 3;
 * @return {boolean}
 */
proto.mazey.accounts.ContactDetails.prototype.getIspublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.accounts.ContactDetails} returns this
 */
proto.mazey.accounts.ContactDetails.prototype.setIspublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.accounts.GetContactDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.GetContactDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.GetContactDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.GetContactDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GetContactDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.mazey.accounts.ContactDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.GetContactDetailsResponse}
 */
proto.mazey.accounts.GetContactDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.GetContactDetailsResponse;
  return proto.mazey.accounts.GetContactDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.GetContactDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.GetContactDetailsResponse}
 */
proto.mazey.accounts.GetContactDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mazey.accounts.ContactDetails;
      reader.readMessage(value,proto.mazey.accounts.ContactDetails.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.GetContactDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.GetContactDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.GetContactDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GetContactDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mazey.accounts.ContactDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ContactDetails contacts = 1;
 * @return {!Array<!proto.mazey.accounts.ContactDetails>}
 */
proto.mazey.accounts.GetContactDetailsResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.mazey.accounts.ContactDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.accounts.ContactDetails, 1));
};


/**
 * @param {!Array<!proto.mazey.accounts.ContactDetails>} value
 * @return {!proto.mazey.accounts.GetContactDetailsResponse} returns this
*/
proto.mazey.accounts.GetContactDetailsResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mazey.accounts.ContactDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.accounts.ContactDetails}
 */
proto.mazey.accounts.GetContactDetailsResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mazey.accounts.ContactDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.accounts.GetContactDetailsResponse} returns this
 */
proto.mazey.accounts.GetContactDetailsResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.PrimProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.PrimProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.PrimProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.PrimProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    eref: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.PrimProperty}
 */
proto.mazey.accounts.PrimProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.PrimProperty;
  return proto.mazey.accounts.PrimProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.PrimProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.PrimProperty}
 */
proto.mazey.accounts.PrimProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEref(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.PrimProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.PrimProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.PrimProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.PrimProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEref();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string eref = 1;
 * @return {string}
 */
proto.mazey.accounts.PrimProperty.prototype.getEref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.PrimProperty} returns this
 */
proto.mazey.accounts.PrimProperty.prototype.setEref = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.mazey.accounts.PrimProperty.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.PrimProperty} returns this
 */
proto.mazey.accounts.PrimProperty.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.GetProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.GetProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.GetProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GetProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ueref: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    username: jspb.Message.getFieldWithDefault(msg, 6, ""),
    institution: (f = msg.getInstitution()) && proto.mazey.accounts.PrimProperty.toObject(includeInstance, f),
    stream: (f = msg.getStream()) && proto.mazey.accounts.PrimProperty.toObject(includeInstance, f),
    field: (f = msg.getField()) && proto.mazey.accounts.PrimProperty.toObject(includeInstance, f),
    validity: jspb.Message.getFieldWithDefault(msg, 10, ""),
    accounttype: jspb.Message.getFieldWithDefault(msg, 11, ""),
    about: jspb.Message.getFieldWithDefault(msg, 12, ""),
    profilepic: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.GetProfileResponse}
 */
proto.mazey.accounts.GetProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.GetProfileResponse;
  return proto.mazey.accounts.GetProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.GetProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.GetProfileResponse}
 */
proto.mazey.accounts.GetProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUeref(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 7:
      var value = new proto.mazey.accounts.PrimProperty;
      reader.readMessage(value,proto.mazey.accounts.PrimProperty.deserializeBinaryFromReader);
      msg.setInstitution(value);
      break;
    case 8:
      var value = new proto.mazey.accounts.PrimProperty;
      reader.readMessage(value,proto.mazey.accounts.PrimProperty.deserializeBinaryFromReader);
      msg.setStream(value);
      break;
    case 9:
      var value = new proto.mazey.accounts.PrimProperty;
      reader.readMessage(value,proto.mazey.accounts.PrimProperty.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidity(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccounttype(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbout(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilepic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.GetProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.GetProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.GetProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.GetProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUeref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInstitution();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mazey.accounts.PrimProperty.serializeBinaryToWriter
    );
  }
  f = message.getStream();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.mazey.accounts.PrimProperty.serializeBinaryToWriter
    );
  }
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.mazey.accounts.PrimProperty.serializeBinaryToWriter
    );
  }
  f = message.getValidity();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAccounttype();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAbout();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getProfilepic();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ueref = 2;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getUeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setUeref = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string username = 6;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PrimProperty institution = 7;
 * @return {?proto.mazey.accounts.PrimProperty}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getInstitution = function() {
  return /** @type{?proto.mazey.accounts.PrimProperty} */ (
    jspb.Message.getWrapperField(this, proto.mazey.accounts.PrimProperty, 7));
};


/**
 * @param {?proto.mazey.accounts.PrimProperty|undefined} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
*/
proto.mazey.accounts.GetProfileResponse.prototype.setInstitution = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.clearInstitution = function() {
  return this.setInstitution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mazey.accounts.GetProfileResponse.prototype.hasInstitution = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimProperty stream = 8;
 * @return {?proto.mazey.accounts.PrimProperty}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getStream = function() {
  return /** @type{?proto.mazey.accounts.PrimProperty} */ (
    jspb.Message.getWrapperField(this, proto.mazey.accounts.PrimProperty, 8));
};


/**
 * @param {?proto.mazey.accounts.PrimProperty|undefined} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
*/
proto.mazey.accounts.GetProfileResponse.prototype.setStream = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.clearStream = function() {
  return this.setStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mazey.accounts.GetProfileResponse.prototype.hasStream = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimProperty field = 9;
 * @return {?proto.mazey.accounts.PrimProperty}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getField = function() {
  return /** @type{?proto.mazey.accounts.PrimProperty} */ (
    jspb.Message.getWrapperField(this, proto.mazey.accounts.PrimProperty, 9));
};


/**
 * @param {?proto.mazey.accounts.PrimProperty|undefined} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
*/
proto.mazey.accounts.GetProfileResponse.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mazey.accounts.GetProfileResponse.prototype.hasField = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string validity = 10;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getValidity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setValidity = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string accountType = 11;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getAccounttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setAccounttype = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string about = 12;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getAbout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setAbout = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string profilePic = 13;
 * @return {string}
 */
proto.mazey.accounts.GetProfileResponse.prototype.getProfilepic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.GetProfileResponse} returns this
 */
proto.mazey.accounts.GetProfileResponse.prototype.setProfilepic = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.SetContactDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.SetContactDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.SetContactDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.SetContactDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contacttype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contactvalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ispublic: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.SetContactDetailsRequest}
 */
proto.mazey.accounts.SetContactDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.SetContactDetailsRequest;
  return proto.mazey.accounts.SetContactDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.SetContactDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.SetContactDetailsRequest}
 */
proto.mazey.accounts.SetContactDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContacttype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactvalue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspublic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.SetContactDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.SetContactDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.SetContactDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.SetContactDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContacttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContactvalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIspublic();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string contactType = 1;
 * @return {string}
 */
proto.mazey.accounts.SetContactDetailsRequest.prototype.getContacttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.SetContactDetailsRequest} returns this
 */
proto.mazey.accounts.SetContactDetailsRequest.prototype.setContacttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contactValue = 2;
 * @return {string}
 */
proto.mazey.accounts.SetContactDetailsRequest.prototype.getContactvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.SetContactDetailsRequest} returns this
 */
proto.mazey.accounts.SetContactDetailsRequest.prototype.setContactvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isPublic = 3;
 * @return {boolean}
 */
proto.mazey.accounts.SetContactDetailsRequest.prototype.getIspublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.accounts.SetContactDetailsRequest} returns this
 */
proto.mazey.accounts.SetContactDetailsRequest.prototype.setIspublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.accounts.ChangeUserPropertyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.accounts.ChangeUserPropertyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.accounts.ChangeUserPropertyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.ChangeUserPropertyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.accounts.ChangeUserPropertyRequest}
 */
proto.mazey.accounts.ChangeUserPropertyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.accounts.ChangeUserPropertyRequest;
  return proto.mazey.accounts.ChangeUserPropertyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.accounts.ChangeUserPropertyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.accounts.ChangeUserPropertyRequest}
 */
proto.mazey.accounts.ChangeUserPropertyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.accounts.ChangeUserPropertyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.accounts.ChangeUserPropertyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.accounts.ChangeUserPropertyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.accounts.ChangeUserPropertyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string property = 1;
 * @return {string}
 */
proto.mazey.accounts.ChangeUserPropertyRequest.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.ChangeUserPropertyRequest} returns this
 */
proto.mazey.accounts.ChangeUserPropertyRequest.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.mazey.accounts.ChangeUserPropertyRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.accounts.ChangeUserPropertyRequest} returns this
 */
proto.mazey.accounts.ChangeUserPropertyRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.mazey.accounts);
