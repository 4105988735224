import React from "react";
import {Paper, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
	paper: {
		width: '96%',
		maxWidth: '400px',
		display: 'inline-block',
		padding: '50px 0',
		marginTop: '100px',
	},
});

export default function Mismatch() {
	const classes = useStyles();

	return <React.Fragment>
		<Paper elevation={0} className={classes.paper}>
			<Typography variant="body1" color="textSecondary">
				We couldn't find what you are looking for.
			</Typography>
			<br />
			<br />
			<Link to="/" className='link'>
				<Typography variant="body1" color="textSecondary">
					Back to Home
				</Typography>
			</Link>
		</Paper>
	</React.Fragment>
}