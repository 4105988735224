import React from 'react'
import { CardContent, Typography } from "@material-ui/core";

export default function Suspended() {
    return <CardContent>
        <Typography variant="h5" gutterBottom>
            Eeeh! That hurts!
        </Typography>
        <br />
        <br />
        <Typography variant="body1" gutterBottom>
            Your account has been suspended because you did something unethical
            or against the terms of this website.
            <br />
            You have been notified by email regarding the same.
        </Typography>
        <br /><br /><br />
        <Typography variant="caption" display="block" gutterBottom>
            If you think this is a mistake, contact us on help page.
        </Typography>
    </CardContent>
}