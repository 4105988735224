import React, { useState } from "react";
import { Card, CardContent, Typography, TextField, Grid, Button, CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles.js";
import { AuthServiceClient } from '../protos/auth_grpc_web_pb';
import { SignupStepRequest } from '../protos/auth_pb';
import { AuthServerAddress } from '../protos/server';

export default function RegisterStep2(props) {
	const classes = useStyles();
	const [err, setErr] = useState({
		firstName: " ",
		lastName: " ",
		email: " ",
	})
	const [btnStat, setBtnStat] = useState("f");

	const changeValue = (prop) => (event) => {
		props.setValues({ ...props.values, [prop]: event.target.value });
		setErr({ ...err, [prop]: " " });
		setBtnStat("f")
	}

	const prevStep = () => {
		props.setStep(1);
	}

	const nextStep = () => {
		setErr({
			firstName: " ",
			lastName: " ",
			email: " ",
		});
		if (props.values.firstName.length > 1 && props.values.lastName.length > 1 && props.values.email.length > 7) {
			setBtnStat("l");
			const client = new AuthServiceClient(AuthServerAddress, null, null)
			const req = new SignupStepRequest();
			req.setStep(2)
			req.setFirstname(props.values.firstName)
			req.setLastname(props.values.lastName)
			req.setEmail(props.values.email)

			client.signupStep(req, {}, (respErr, response) => {
				if (respErr) {
					if (respErr.message) {
						if (respErr.message === "Invalid First Name") {
							setErr({ ...err, firstName: "Invalid First Name" })
						} else if (respErr.message === "Invalid Last Name") {
							setErr({ ...err, lastName: "Invalid Last Name" })
						} else if (respErr.message === "Invalid Email") {
							setErr({ ...err, email: "Invalid Email" })
						} else if (respErr.message === "Email Exists") {
							setErr({ ...err, email: "Email is already registered" })
						}
						setBtnStat("e");
					}
				} else {
					const res = response.toObject()
					if (res.response === "Okay") {
						props.setStep(3);
					}
				}
			})
		}
	}

	const nextBtn = () => {
		if (btnStat === "l") {
			return <Button color="primary">
				<CircularProgress size={25} />
			</Button>
		} else if (btnStat === "f") {
			return <Button variant="contained"
				color="primary"
				disabled={props.values.firstName.length < 2 || props.values.lastName.length < 2 || props.values.email.length < 8 || err.firstName !== " " || err.lastName !== " " || err.email !== " "}
				onClick={nextStep}
			>
				Next
			</Button>
		} else {
			return <Button disabled >
				Error
			</Button>
		}
	}

	return <Card className={classes.card}>
		<CardContent>
			<Typography variant="h6" color="textPrimary" gutterBottom>
				Now, something personal.
			</Typography>
			<Typography variant="body1" color="textSecondary">
				We won't sell your data, pinky promise.
			</Typography>
		</CardContent>
		<CardContent>
			<form autoComplete="off" className={classes.form} >
				<TextField
					className={classes.textField}
					id="standard-basic"
					label="First Name"
					value={props.values.firstName}
					onChange={changeValue('firstName')}
					error={err.firstName.length > 2}
					helperText={err.firstName}
				/><br />
				<TextField
					className={classes.textField}
					id="standard-basic"
					label="Last Name"
					value={props.values.lastName}
					onChange={changeValue('lastName')}
					error={err.lastName.length > 2}
					helperText={err.lastName}
				/><br />
				<TextField
					className={classes.textField}
					id="standard-basic"
					label="Personal Email"
					error={err.email.length > 2}
					value={props.values.email}
					onChange={changeValue('email')}
					helperText={err.email}
				/><br />
			</form>
		</CardContent>
		<CardContent>
			<Grid
				container
				direction="row"
				justify="space-between"
				alignItems="center"
			>
				<Button
					color="primary"
					onClick={prevStep}
				>
					Previous
				</Button>
				{nextBtn()}
			</Grid>
		</CardContent>
	</Card>
}