export const getGreetings = () => {
    const time = new Date()
    const hour = time.getHours()

    if (hour > 3 && hour < 12) {
        return "Good Morning !"
    } else if (hour > 12 && hour < 5) {
        return "Good Afternoon !"
    } else if (hour > 5 && hour < 24) {
        return "Good Evening !"
    } else {
        return "         "
    }
}