import React, {useEffect, useState} from "react";
import {Paper, CircularProgress, Typography, FormControl, InputLabel, Button, Input, InputAdornment, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Link, useParams, Redirect} from "react-router-dom";
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {AuthServiceClient} from '../protos/auth_grpc_web_pb';
import {ResetPasswordRequest} from '../protos/auth_pb';
import {AuthServerAddress} from '../protos/server';

const useStyles = makeStyles({
	paper: {
		width: '96%',
		maxWidth: '600px',
		marginTop: '50px',
		display: 'inline-block',
		padding: '60px 0',
	},
});

export default function PassLift() {
	const classes = useStyles();
	const {token, qID} = useParams();
	const [stat, setStat] = useState("l");
	const [passVis, setPassVis] = useState(false);
	const [pass, setPass] = useState("");
	const [passErr, setPassErr] = useState("n");

	const handleChange = (event) => {
		setPassErr("n");
		setPass(event.target.value);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const getToken = () => {
		const client = new AuthServiceClient(AuthServerAddress, null, null)
		const req = new ResetPasswordRequest();
		req.setRequesttype("validity")
		req.setToken(token)
		req.setQid(qID)

		client.resetPassword(req, {}, (err, response) => {
			if (err) {
				if (err.message === "Nothing Found") {
					setStat("r")
					return
				}
				setStat("e");
			} else {
				const res = response.toObject()
				if (res.response === "Okay") {
					setStat("f")
					return
				}
				setStat("r")
				return
			}
		})
	}

	useEffect(() => {
		getToken();
	}, [])

	const updatePass = () => {
		setStat("l");
		const client = new AuthServiceClient(AuthServerAddress, null, null)
		const req = new ResetPasswordRequest();
		req.setRequesttype("reset")
		req.setToken(token)
		req.setQid(qID)
		req.setPassword(pass)

		client.resetPassword(req, {}, (err, response) => {
			if (err) {
				if (err.message === "Invalid Password") {
					setPassErr("y")
					return
				}
				setStat("e1");
			} else {
				const res = response.toObject()
				if (res.response === "Success") {
					setStat("s")
					return
				}
				setStat("e1")
				return
			}
		})
	}

	const renderPassErr = () => {
		if (passErr === "y") {
			return <React.Fragment>
				<br />
				<br />
				<Typography variant="caption" color="textSecondary">
					Invalid Character in Password.
				</Typography>
			</React.Fragment>
		}
	}

	const render = () => {
		if (stat === "l") {
			return <React.Fragment>
				<br />
				<br />
				<br />
				<br />
				<CircularProgress />
			</React.Fragment>
		} else if (stat === "f") {
			return <Paper elevation={0} className={classes.paper}>
				<FormControl >
					<InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
					<Input
						id="standard-adornment-password"
						type={passVis ? 'text' : 'password'}
						value={pass}
						onChange={handleChange}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setPassVis(!passVis)}
									onMouseDown={handleMouseDownPassword}
								>
									{passVis ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>
				<br />
				<br />
				<br />
				<Button color="primary" disabled={pass.length < 8} onClick={updatePass}>
					Submit
				</Button>
				{ renderPassErr() }
				<br />
				<br />
				<Typography variant="caption" color="textSecondary">
					You better remember it this time.
				</Typography>
			</Paper>
		} else if (stat === "s") {
			return <Paper elevation={0} className={classes.paper}>
				<Typography variant="body1" color="textPrimary">
					Password has been updated.
					<br />
					Proceed to login now.
				</Typography>
				<br />
				<br />
				<Link to="/" className='link'>
					<Typography variant="body2" color="textSecondary" gutterBottom>
						Back to Home
					</Typography>
				</Link>
			</Paper>
		} else if (stat === "r") {
			return <Redirect to="/mismatch" />
		} else if (stat === "e1") {
			return <Paper className={classes.paper} elevation={0}>
				<Typography variant="subtitle2" color="error" gutterBottom>
					Something went wrong.
					<br />
					Please try again later.
				</Typography>
			</Paper>
		} else {
			return <Paper className={classes.paper} elevation={0}>
				<Typography variant="subtitle2" color="error" gutterBottom>
					Something went wrong.
					<br />
					Please try again later.
				</Typography>
				<br />
				<br />
				<Button color="primary" onClick={() => setStat("f")} >
					Retry?
				</Button>
			</Paper>
		}
	}


	return <React.Fragment>
		{ render() }
	</React.Fragment>
}