/**
 * @fileoverview gRPC-Web generated client stub for mazey.auth
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.mazey = {};
proto.mazey.auth = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.mazey.auth.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.mazey.auth.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.LoginRequest,
 *   !proto.mazey.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.LoginRequest,
  proto.mazey.auth.LoginResponse,
  /**
   * @param {!proto.mazey.auth.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.LoginResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.LoginRequest,
 *   !proto.mazey.auth.LoginResponse>}
 */
const methodInfo_AuthService_Login = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.LoginResponse,
  /**
   * @param {!proto.mazey.auth.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.mazey.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodDescriptor_AuthService_Logout = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/Logout',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.GeneralRequest,
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodInfo_AuthService_Logout = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout,
      callback);
};


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.SignupStepRequest,
 *   !proto.mazey.auth.SignupStepResponse>}
 */
const methodDescriptor_AuthService_SignupStep = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/SignupStep',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.SignupStepRequest,
  proto.mazey.auth.SignupStepResponse,
  /**
   * @param {!proto.mazey.auth.SignupStepRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.SignupStepResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.SignupStepRequest,
 *   !proto.mazey.auth.SignupStepResponse>}
 */
const methodInfo_AuthService_SignupStep = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.SignupStepResponse,
  /**
   * @param {!proto.mazey.auth.SignupStepRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.SignupStepResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.SignupStepRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.SignupStepResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.SignupStepResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.signupStep =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/SignupStep',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignupStep,
      callback);
};


/**
 * @param {!proto.mazey.auth.SignupStepRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.SignupStepResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.signupStep =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/SignupStep',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignupStep);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.SignupRequest,
 *   !proto.mazey.auth.SignupResponse>}
 */
const methodDescriptor_AuthService_Signup = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/Signup',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.SignupRequest,
  proto.mazey.auth.SignupResponse,
  /**
   * @param {!proto.mazey.auth.SignupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.SignupResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.SignupRequest,
 *   !proto.mazey.auth.SignupResponse>}
 */
const methodInfo_AuthService_Signup = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.SignupResponse,
  /**
   * @param {!proto.mazey.auth.SignupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.SignupResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.SignupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.SignupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.SignupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.signup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/Signup',
      request,
      metadata || {},
      methodDescriptor_AuthService_Signup,
      callback);
};


/**
 * @param {!proto.mazey.auth.SignupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.SignupResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.signup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/Signup',
      request,
      metadata || {},
      methodDescriptor_AuthService_Signup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.ResetPasswordRequest,
 *   !proto.mazey.auth.ResetPasswordResponse>}
 */
const methodDescriptor_AuthService_ResetPassword = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/ResetPassword',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.ResetPasswordRequest,
  proto.mazey.auth.ResetPasswordResponse,
  /**
   * @param {!proto.mazey.auth.ResetPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.ResetPasswordResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.ResetPasswordRequest,
 *   !proto.mazey.auth.ResetPasswordResponse>}
 */
const methodInfo_AuthService_ResetPassword = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.ResetPasswordResponse,
  /**
   * @param {!proto.mazey.auth.ResetPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.ResetPasswordResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.ResetPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.ResetPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.resetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetPassword,
      callback);
};


/**
 * @param {!proto.mazey.auth.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.ResetPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.resetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.VerifyEmailRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodDescriptor_AuthService_VerifyEmail = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/VerifyEmail',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.VerifyEmailRequest,
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.VerifyEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.VerifyEmailRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodInfo_AuthService_VerifyEmail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.VerifyEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.verifyEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_AuthService_VerifyEmail,
      callback);
};


/**
 * @param {!proto.mazey.auth.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.verifyEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_AuthService_VerifyEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.ChangeCredentialsRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodDescriptor_AuthService_ChangeCredentials = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/ChangeCredentials',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.ChangeCredentialsRequest,
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.ChangeCredentialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.ChangeCredentialsRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodInfo_AuthService_ChangeCredentials = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.ChangeCredentialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.ChangeCredentialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.changeCredentials =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/ChangeCredentials',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangeCredentials,
      callback);
};


/**
 * @param {!proto.mazey.auth.ChangeCredentialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.changeCredentials =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/ChangeCredentials',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangeCredentials);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.AuthorizeAppRequest,
 *   !proto.mazey.auth.AuthorizeAppResponse>}
 */
const methodDescriptor_AuthService_AuthorizeApp = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/AuthorizeApp',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.AuthorizeAppRequest,
  proto.mazey.auth.AuthorizeAppResponse,
  /**
   * @param {!proto.mazey.auth.AuthorizeAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.AuthorizeAppResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.AuthorizeAppRequest,
 *   !proto.mazey.auth.AuthorizeAppResponse>}
 */
const methodInfo_AuthService_AuthorizeApp = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.AuthorizeAppResponse,
  /**
   * @param {!proto.mazey.auth.AuthorizeAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.AuthorizeAppResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.AuthorizeAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.AuthorizeAppResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.AuthorizeAppResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.authorizeApp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/AuthorizeApp',
      request,
      metadata || {},
      methodDescriptor_AuthService_AuthorizeApp,
      callback);
};


/**
 * @param {!proto.mazey.auth.AuthorizeAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.AuthorizeAppResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.authorizeApp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/AuthorizeApp',
      request,
      metadata || {},
      methodDescriptor_AuthService_AuthorizeApp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.GrantPermissionRequest,
 *   !proto.mazey.auth.GrantPermissionResponse>}
 */
const methodDescriptor_AuthService_GrantPermission = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/GrantPermission',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.GrantPermissionRequest,
  proto.mazey.auth.GrantPermissionResponse,
  /**
   * @param {!proto.mazey.auth.GrantPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GrantPermissionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.GrantPermissionRequest,
 *   !proto.mazey.auth.GrantPermissionResponse>}
 */
const methodInfo_AuthService_GrantPermission = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GrantPermissionResponse,
  /**
   * @param {!proto.mazey.auth.GrantPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GrantPermissionResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.GrantPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GrantPermissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GrantPermissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.grantPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/GrantPermission',
      request,
      metadata || {},
      methodDescriptor_AuthService_GrantPermission,
      callback);
};


/**
 * @param {!proto.mazey.auth.GrantPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GrantPermissionResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.grantPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/GrantPermission',
      request,
      metadata || {},
      methodDescriptor_AuthService_GrantPermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.GetTokenRequest,
 *   !proto.mazey.auth.GetTokenResponse>}
 */
const methodDescriptor_AuthService_GetToken = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/GetToken',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.GetTokenRequest,
  proto.mazey.auth.GetTokenResponse,
  /**
   * @param {!proto.mazey.auth.GetTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GetTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.GetTokenRequest,
 *   !proto.mazey.auth.GetTokenResponse>}
 */
const methodInfo_AuthService_GetToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GetTokenResponse,
  /**
   * @param {!proto.mazey.auth.GetTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GetTokenResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.GetTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GetTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GetTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.getToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/GetToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetToken,
      callback);
};


/**
 * @param {!proto.mazey.auth.GetTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GetTokenResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.getToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/GetToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodDescriptor_AuthService_BlockToken = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/BlockToken',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.GeneralRequest,
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodInfo_AuthService_BlockToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.blockToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/BlockToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_BlockToken,
      callback);
};


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.blockToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/BlockToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_BlockToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.GetActivityRequest,
 *   !proto.mazey.auth.GetActivityResponse>}
 */
const methodDescriptor_AuthService_GetActivity = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/GetActivity',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.GetActivityRequest,
  proto.mazey.auth.GetActivityResponse,
  /**
   * @param {!proto.mazey.auth.GetActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GetActivityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.GetActivityRequest,
 *   !proto.mazey.auth.GetActivityResponse>}
 */
const methodInfo_AuthService_GetActivity = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GetActivityResponse,
  /**
   * @param {!proto.mazey.auth.GetActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GetActivityResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.GetActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GetActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GetActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.getActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/GetActivity',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetActivity,
      callback);
};


/**
 * @param {!proto.mazey.auth.GetActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GetActivityResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.getActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/GetActivity',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GetSessionsResponse>}
 */
const methodDescriptor_AuthService_GetSessions = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/GetSessions',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.GeneralRequest,
  proto.mazey.auth.GetSessionsResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GetSessionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GetSessionsResponse>}
 */
const methodInfo_AuthService_GetSessions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GetSessionsResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GetSessionsResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GetSessionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GetSessionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.getSessions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/GetSessions',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetSessions,
      callback);
};


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GetSessionsResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.getSessions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/GetSessions',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetSessions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GetAppLoginsResponse>}
 */
const methodDescriptor_AuthService_GetAppLogins = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/GetAppLogins',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.GeneralRequest,
  proto.mazey.auth.GetAppLoginsResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GetAppLoginsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GetAppLoginsResponse>}
 */
const methodInfo_AuthService_GetAppLogins = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GetAppLoginsResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GetAppLoginsResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GetAppLoginsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GetAppLoginsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.getAppLogins =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/GetAppLogins',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetAppLogins,
      callback);
};


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GetAppLoginsResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.getAppLogins =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/GetAppLogins',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetAppLogins);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.AppLoginDetailsResponse>}
 */
const methodDescriptor_AuthService_AppLoginDetails = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/AppLoginDetails',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.GeneralRequest,
  proto.mazey.auth.AppLoginDetailsResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.AppLoginDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.AppLoginDetailsResponse>}
 */
const methodInfo_AuthService_AppLoginDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.AppLoginDetailsResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.AppLoginDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.AppLoginDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.AppLoginDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.appLoginDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/AppLoginDetails',
      request,
      metadata || {},
      methodDescriptor_AuthService_AppLoginDetails,
      callback);
};


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.AppLoginDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.appLoginDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/AppLoginDetails',
      request,
      metadata || {},
      methodDescriptor_AuthService_AppLoginDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodDescriptor_AuthService_BlockAppLogin = new grpc.web.MethodDescriptor(
  '/mazey.auth.AuthService/BlockAppLogin',
  grpc.web.MethodType.UNARY,
  proto.mazey.auth.GeneralRequest,
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.mazey.auth.GeneralRequest,
 *   !proto.mazey.auth.GeneralResponse>}
 */
const methodInfo_AuthService_BlockAppLogin = new grpc.web.AbstractClientBase.MethodInfo(
  proto.mazey.auth.GeneralResponse,
  /**
   * @param {!proto.mazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.mazey.auth.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mazey.auth.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mazey.auth.AuthServiceClient.prototype.blockAppLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mazey.auth.AuthService/BlockAppLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_BlockAppLogin,
      callback);
};


/**
 * @param {!proto.mazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mazey.auth.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.mazey.auth.AuthServicePromiseClient.prototype.blockAppLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mazey.auth.AuthService/BlockAppLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_BlockAppLogin);
};


module.exports = proto.mazey.auth;

