import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    blankDiv: {
        width: '100%',
        height: 30,
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
        float: 'left',
    },
    card: {
        width: '96%',
        display: 'inline-block',
        maxWidth: 400,
    },
    input: {
        width: '70%',
    },
    imgInput: {
        display: 'none',
    },
    imgInputBtn: {
        margin: '0 10px',
    },
    grid: {
        width: '100%',
        height: '100%',
    }
}));