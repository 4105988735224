import React, { useState, useEffect } from 'react';
import { useStyles } from '../styles';
import { Paper, Avatar, Grid, Typography, TextField, Button, CircularProgress, Badge } from "@material-ui/core"
import { DoneAll, Create } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AccountsServiceClient } from '../../../../protos/accounts_grpc_web_pb';
import { GeneralRequest } from '../../../../protos/accounts_pb';
import { AccountsServerAddress } from '../../../../protos/server';
import GetMetadata from '../../../customComponents/getMetadata';
import { useGetProfile } from '../../../customHooks/useGetProfile'

export default function NameCard(props) {
    const classes = useStyles()
    const [btnStat, setBtnStat] = useState(0)
    const userData = useSelector(state => state.userData)
    const profilePicSource = useSelector(state => state.profilePicSource)
    const md = GetMetadata()
    const dispatch = useDispatch()
    const [picStat, setPicStat] = useState(2)
    const [picSource, setPicSource] = useState("")

    const [stat, profileData] = useGetProfile()
    const [about, setAbout] = useState("")

    const handleAbout = (event) => {
        setAbout(event.target.value)
        setBtnStat(0)
    }

    useEffect(() => {
        if (profileData.about !== undefined) {
            setAbout(profileData.about)
        }
        if (profilePicSource === "") {
            fetchPic(userData.token)
        } else {
            setPicSource(profilePicSource)
            setPicStat(0)
        }
    }, [profileData])

    const fetchPic = (token) => {
        if (profileData.profilepic !== undefined) {
            var headers = {
                'Content-Type': 'multipart/form-data',
                'platform': md.platform,
                'vendor': md.vendor,
                'useragent': md.useragent,
                'authorization': token,
            }
            fetch(profileData.profilepic, {
                method: 'GET',
                headers: headers,
            })
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader;
                    reader.addEventListener('loadend', () => {
                        setPicSource(reader.result)
                        setPicStat(0)
                        dispatch({ type: 'SET_PROFILE_PIC', data: reader.result })
                    })
                    reader.readAsDataURL(blob);
                });
        }
    }

    const renderProfilePic = () => {
        if (picStat === 0) {
            return <img
                className={classes.avatarImg}
                id="profile_avatar"
                src={picSource}
            />
        } else {
            return <Avatar
                alt={profileData.firstname + " " + profileData.lastname}
                className={classes.avatar}
            />
        }
    }

    const renderBtn = () => {
        if (btnStat === 0) {
            return <Button
                color="primary"
                disabled={about.length > 140}
                onClick={updateAbout}
            >Update</Button>
        } else if (btnStat === 2) {
            return <Button>
                <CircularProgress size={25} />
            </Button>
        } else if (btnStat === -1) {
            return <Button disabled>Error</Button>
        } else if (btnStat === 1) {
            return <Button color="primary">
                <DoneAll />
            </Button>
        }
    }

    const updateAbout = () => {
        setBtnStat(2)
        const accClient = new AccountsServiceClient(AccountsServerAddress, null, null)
        const req = new GeneralRequest();
        req.setMessage(about)
        md.authorization = userData.token
        accClient.setAbout(req, md, (err, response) => {
            if (err) {
                console.log(err)
            } else {
                setBtnStat(1)
                dispatch({ type: 'SET_ABOUT', about: about })
            }
        })
    }

    const render = () => {
        if (stat === 2) {
            return <CircularProgress size={30} />
        } else if (stat === 0) {
            return <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <center>
                        <Badge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            badgeContent={
                                <Button onClick={props.OpenProfilePictureDialog}>
                                    <Create alt="edit button" color="primary" />
                                </Button>}
                        >
                            {renderProfilePic()}
                        </Badge>
                    </center>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                    <br />
                    <Typography variant="h5" color="textPrimary" gutterBottom align="left" >
                        {profileData.firstname} {profileData.lastname}
                    </Typography>
                    <br />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={10}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Describe Yourself"
                                multiline
                                fullWidth
                                rows={4}
                                value={about}
                                onChange={handleAbout}
                                variant="outlined"
                                disabled={btnStat === 2}
                                error={about.length > 140}
                                helperText={about.length > 140 ? "Max 140 Characters" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                            {renderBtn()}
                        </Grid>
                    </Grid>
                    <br />
                </Grid>
            </Grid>
        } else {
            return <Typography variant="body1" color="textSecondary" >
                Unable to load data
            </Typography>
        }
    }

    return <Paper className={classes.paper}>
        {render()}
    </Paper>
}