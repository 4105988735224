import { makeStyles } from '@material-ui/core/styles';

export const helpCenterStyles = makeStyles((theme) => ({
    ciPaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        marginBottom: theme.spacing(2),
        // width: '100%',
    },
    issueSelect: {
        width: '100%',
        maxWidth: '400px',
        marginBottom: theme.spacing(5),
    },
    issueText: {
        width: '100%',
        maxWidth: '800px',
        marginBottom: theme.spacing(5),
        whiteSpace: 'pre',
    },
    issueGrid: {
        // minWidth: 650,
        width: '100%',
        overflowX: 'auto',
    },
    issueLink: {
        cursor: 'pointer',
    },
    issueLinkNew: {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.ultraLight,
    },


    // ticket details
    ticketDetailsCont: {
        margin: '0 20px 20px 20px',
    },
    ticketDetailsValue: {
        color: theme.palette.text.secondary,
    },

    // communication
    communeCont: {
        width: '100%',
        margin: 'auto',
        // boxShadow: 'inset 0 0 7px #2C2C2C',
        padding: theme.spacing(1),
        // backgroundColor: theme.palette.background.page,
        textAlign: 'center',
    },
    communeCard: {
        // minWidth: '10%',
        // maxWidth: '80%',
        width: '100%',
        // marginLeft: 0,
        // padding: theme.spacing(1),
        marginTop: theme.spacing(3),
    },
    communeMsgCont: {
        width: '100%',
        border: '1px solid',
    },
    communeMsg: {
        border: '1px solid ' + theme.palette.text.disabled,
        borderRadius: '5px',
        padding: theme.spacing(1),
        maxWidth: '80%',
    },
    communeMsgClosed: {
        background: '#F0F0F0',
        borderRadius: '5px',
        padding: theme.spacing(1),
        width: '100%',
        marginTop: 30,
    },

    addMsgDialog: {
        width: '100%',
    },
}));