import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import NameCard from './nameCard';
import ProfilePictureDialog from './profilePictureDialog';

export default function Personal() {
    const [nameCardKey, setNameCardKey] = useState(0)
    const [profilePictureDialogState, setProfilePictureDialogState] = useState(false)
    const OpenProfilePictureDialog = () => {
        setProfilePictureDialogState(true)
    }
    const CloseProfilePictureDialog = () => {
        setProfilePictureDialogState(false)
    }

    return <>
        <Grid
            container
            spacing={3}
        >
            <Grid item xs={12}>
                <NameCard
                    key={nameCardKey}
                    OpenProfilePictureDialog={OpenProfilePictureDialog}
                />
            </Grid>
        </Grid>
        <ProfilePictureDialog
            profilePictureDialogState={profilePictureDialogState}
            CloseProfilePictureDialog={CloseProfilePictureDialog}
            setNameCardKey={setNameCardKey}
            nameCardKey={nameCardKey}
        />
    </>
}