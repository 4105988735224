import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralRequest } from '../../protos/auth_pb';
import { AuthServiceClient } from '../../protos/auth_grpc_web_pb';
import { AuthServerAddress } from '../../protos/server';
import GetMetadata from '../customComponents/getMetadata';

export function useGetAppLogins(loginType) {
    const [stat, setStat] = useState(2)
    const [logins, setLogins] = useState([])

    const userData = useSelector(state => state.userData)
    const appLogins = useSelector(state => state.appLogins)

    const dispatch = useDispatch()
    const authCient = new AuthServiceClient(AuthServerAddress, null, null)
    var md = GetMetadata()

    const fetchLogins = (token) => {
        const req = new GeneralRequest();
        req.setMessage(loginType)
        md.authorization = token

        authCient.getAppLogins(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setStat(-1)
            } else {
                const res = response.toObject()
                const setAppsLogins = { type: 'SET_APP_LOGINS', loginType: loginType, loginsData: res.accesslistList };
                dispatch(setAppsLogins);
                setLogins(res.accesslistList)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        if (appLogins[loginType] === undefined) {
            fetchLogins(userData.token)
        } else {
            setLogins(appLogins[loginType])
            setStat(0)
        }
    }, [])

    return [stat, logins]
}