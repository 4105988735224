import React from 'react'
import LoginsList from './loginsList'
import { useSelector } from "react-redux"
import TabView from '../tabView'

export default function AppsLoginTabs() {

    return <TabView
        labels={["Current Logins", "Past Logins"]}
        viewComponents={[
            <LoginsList type={"Active"} />,
            <LoginsList type={"Past"} />
        ]}
    />
}