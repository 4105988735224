import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { homeStyles } from './homeStyles';
import { getGreetings } from '../customComponents/getGreetings';

export default function HomeGreeting(props) {
    const classes = homeStyles()

    return <Paper className={classes.ciPaper}>
        <Typography variant="h6" align="left">
            Hello {props.userData.firstName}
        </Typography>
        <Typography align="left">
            &nbsp;&nbsp;{getGreetings()}
        </Typography>
    </Paper>
}