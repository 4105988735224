import React, { useState } from 'react'
import { Tabs, Tab } from "@material-ui/core";
import { TabPanel, a11yProps } from '../customComponents/tabPanel';
import CreateTicket from './createTicket';
import TicketsList from './ticketsList';
import { useParams } from 'react-router-dom';
import TicketDetails from './ticketDetails';

export default function HelpCenter() {
    const [value, setValue] = useState(1);
    const { param2 } = useParams();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const render = () => {
        if (param2 === "ticket") {
            return <TicketDetails />
        } else {
            return <>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="explore help issues"
                >
                    <Tab label="Create Ticket" {...a11yProps(0)} />
                    <Tab label="Open Tickets" {...a11yProps(1)} />
                    <Tab label="Closed Tickets" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <CreateTicket />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TicketsList type="open" />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TicketsList type="closed" />
                </TabPanel>
            </>
        }
    }
    return render()
}