import { makeStyles } from '@material-ui/core/styles';

export const appsLoginStyles = makeStyles((theme) => ({
    ciPaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        width: '100%',
        maxWidth: '900px',
    },
    loginDetailsPaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        width: '90%',
        maxWidth: '900px',
        [theme.breakpoints.down('md')]: {
            margin: 'auto',
        },
    },
    loginDetailsLogo: {
        // width: theme.spacing(10),
        // height: theme.spacing(10),
        border: '1px solid black',
        borderRadius: '5px',
        width: '100%',
        height: '100%',
    },
    appTitle: {
        color: theme.palette.primary.main,
    },
    permsList: {
        paddingLeft: "40px",
    },
    clientlogo: {
        marginRight: '20px',
        marginTop: '7px',
        height: "60px",
        borderRadius: '3px',
    }
}));