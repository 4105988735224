import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, CircularProgress, TextField, Button } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles } from "./styles.js";
import { InstiServiceClient } from '../protos/insti_grpc_web_pb';
import { GetInstiListRequest } from '../protos/insti_pb';
import { InstiServerAddress } from '../protos/server';
import { Link } from 'react-router-dom';

export default function RegisterStep1(props) {
	const classes = useStyles();
	const values = props.values;
	const [renStat, setRenStat] = useState("l");
	const [insti, setInsti] = useState(null);
	const [cList, setCList] = useState([])

	const nextStep = () => {
		if (insti !== null) {
			props.setValues({ ...values, collegeCode: insti.ieref })
			props.setStep(2);
		}
	}

	const getIList = () => {
		const client = new InstiServiceClient(InstiServerAddress, null, null)
		const req = new GetInstiListRequest();
		req.setToken("general")

		client.getInstiList(req, {}, (err, response) => {
			if (err) {
				setRenStat("e")
			} else {
				setCList(response.toObject().institutesList)
				setRenStat("s")
			}
		})
	}

	useEffect(() => {
		getIList();
	}, [])

	const render = () => {
		if (renStat === "l") {
			return <Typography variant="h6" color="primary">
				<br />
				<br />
				<CircularProgress />
			</Typography>
		} else if (renStat === "s") {
			return <Card className={classes.card}>
				<CardContent>
					<Typography variant="h6" color="textPrimary" gutterBottom>
						Lets get you on board.
					</Typography>
					<Typography variant="body1" color="textSecondary">
						Start by searching your college from list.
					</Typography>
				</CardContent>
				<CardContent>
					<Autocomplete
						id="insti-list"
						options={cList}
						getOptionLabel={(option) => option.instiname}
						style={{ width: 300 }}
						onChange={(event, value) => setInsti(value)}
						renderInput={(params) => <TextField {...params} label="College List" variant="outlined" />}
					/>
				</CardContent>
				<CardContent>
					<Button variant="contained" color="primary" onClick={nextStep} disabled={insti === null} >
						Next
					</Button>
				</CardContent>
			</Card>
		} else {
			return <Typography variant="h6" color="textSecondary">
				Cannot Connect Right Now.
				<br />
				Please try again later.
				<br />
				<br />
				<Link to="/" className={classes.link}>
					Back to Home
				</Link>
			</Typography>
		}
	}

	return render()
}