import React, { useState } from 'react';
import { ListItem, Grid, ListItemAvatar, ListItemText, Typography, Button, CircularProgress } from "@material-ui/core";
import { PhoneAndroid, Laptop, ErrorOutline } from '@material-ui/icons';
import { AuthServiceClient } from '../../../protos/auth_grpc_web_pb';
import GetMetadata from '../../customComponents/getMetadata';
import { GeneralRequest } from '../../../protos/auth_pb';
import { useDispatch, useSelector } from "react-redux";
import { AuthServerAddress } from '../../../protos/server';

export default function EachSession(props) {
    const [btnStat, setBtnStat] = useState(props.type === "Active" ? 0 : -2);
    const session = props.session
    const token = useSelector(state => state.token)
    const dispatch = useDispatch()

    const btn = () => {
        switch (btnStat) {
            case 0:     // default
                return <Button color="primary" onClick={terminateSession}>Terminate</Button>
            case -1:    // error
                return <Button color="secondary">Error</Button>
            case 1:     // success
                return <Button color="primary" disabled>Terminated</Button>
            case 2:     // loading
                return <Button color="primary"><CircularProgress size={25} /></Button>
            case -2:     // past session
                return <></>
            default:
                return <></>

        }
    }

    const terminateSession = () => {
        setBtnStat(2)
        const client = new AuthServiceClient(AuthServerAddress, null, null)
        const md = GetMetadata()
        const req = new GeneralRequest();
        req.setMessage(session.tokenid)
        md.authorization = token
        client.blockToken(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setBtnStat(-1)
            } else {
                const res = response.toObject()
                if (res.message === "Success") {
                    setBtnStat(1)
                    dispatch({ type: 'REMOVE_SESSIONS' })
                    return
                }
                setBtnStat(-1)
            }
        })
    }

    const getExpiry = () => {
        if (props.type === "Active") {
            return "Expires on " + session.expiry
        } else if (props.type === "Past") {
            if (session.blocked) {
                return "Blocked on " + session.blockedat
            } else {
                return "Expired on " + session.expiry
            }
        } else {
            return " ??? "
        }
    }

    const renderAvatar = () => {
        if (session.isdevicemobile) {
            return <PhoneAndroid fontSize="large" />
        } else if (session.devicedesc === "Unknown") {
            return <ErrorOutline fontSize="large" />
        } else {
            return <Laptop fontSize="large" />
        }
    }

    return <React.Fragment>
        <ListItem>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <ListItemAvatar>
                    {renderAvatar()}
                </ListItemAvatar>
                <ListItemText primary={
                    <Typography color="textPrimary">
                        {session.devicedesc}
                    </Typography>
                } secondary={
                    <React.Fragment>
                        From IP Address - {session.ipaddress}
                        <br />
                        Issued at {session.issuedat}
                        <br />
                        {getExpiry()}
                    </React.Fragment>
                } />
                {btn()}
            </Grid>
        </ListItem>
    </React.Fragment>
}