import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
	paper: {
		padding: 50,
		minWidth: 400,
		textAlign: 'center',
		marginTop: 50,
	},
});

export default function HomePage() {
	const classes = useStyles();

	return <div className='home_page'>
		<Grid
			container
			direction="column"
			justify="space-around"
			alignItems="center"
		>
			<Paper className={classes.paper} elevation={0} >
				<Link to="/register" className='link' >
					<Typography variant="body1" color="textSecondary" gutterBottom>
						Register
					</Typography>
				</Link>
				<Link to="/resetPass" className='link' >
					<Typography variant="body1" color="textSecondary" gutterBottom>
						Reset Password
					</Typography>
				</Link>
				<Link to="/dashboard" className='link' >
					<Typography variant="body1" color="textSecondary" gutterBottom>
						Service66 Dashboard
					</Typography>
				</Link>
			</Paper>
			<div className='optionsDiv'>
				<Typography variant="body1" color="textSecondary" gutterBottom>
					Have Some Trouble?
				</Typography>
				<a href="https://www.mazeyard.com/contact" className='link' >
					<Typography variant="body1" color="textSecondary" gutterBottom>
						Contact Us
					</Typography>
				</a>
			</div>
		</Grid>
	</div>
}