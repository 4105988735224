import React, { useEffect, useState } from 'react'
import { Typography, Paper, Dialog, DialogActions, DialogContent, Slide, DialogTitle, Divider, Grid, CardContent, Button, CircularProgress, TextField, IconButton } from "@material-ui/core";
import { helpCenterStyles } from './styles';
import { useLocation, useHistory } from 'react-router-dom';
import GetMetadata from '../customComponents/getMetadata';
import { SupportServiceClient } from '../../protos/support_grpc_web_pb';
import { GeneralRequest, UpdateTicketRequest } from '../../protos/support_pb';
import { SupportServerAddress } from '../../protos/server';
import { useSelector, useDispatch } from 'react-redux';
import { DoneAll, KeyboardBackspace } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function TicketDetails() {
    const classes = helpCenterStyles();
    const [stat, setStat] = useState(2)
    const [ticket, setTicket] = useState({})
    const [messages, setMessages] = useState([])
    const dispatch = useDispatch()
    const history = useHistory()
    const query = useQuery()
    const ticketId = query.get('Id')
    const userData = useSelector(state => state.userData)
    const [dialogStat, setDialogStat] = useState(false);
    const supportClient = new SupportServiceClient(SupportServerAddress, null, null)
    const md = GetMetadata()
    md.authorization = userData.token
    const [newMessage, setNewMessage] = useState("")

    const openMsgDialog = () => {
        setDialogStat(true);
    };

    const closeMsgDialog = () => {
        setDialogStat(false);
    };

    const addNewMessage = (event) => {
        setNewMessage(event.target.value)
    }

    const getTicketDetails = async () => {
        const req = new GeneralRequest();
        req.setMessage(ticketId)
        supportClient.getTicketDetails(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setStat(-1)
            } else {
                const res = response.toObject()
                setTicket(res.ticket)
                setMessages(res.messagesList)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        getTicketDetails()
    }, [])

    const addMessage = () => {
        if (newMessage.length > 1) {
            setMsgBtn(2)
            const req = new UpdateTicketRequest();
            req.setTicketid(ticketId)
            req.setMessage(newMessage.replace(/\r\n|\r|\n/g, "<br/>"))
            supportClient.updateTicket(req, md, (err, response) => {
                if (err) {
                    console.log(err)
                    setMsgBtn(-1)
                } else {
                    setMsgBtn(1)
                    getTicketDetails()
                    setNewMessage("")
                    setTimeout(() => {
                        setMsgBtn(0)
                        closeMsgDialog()
                    }, 1000)
                }
            })
        }
    }

    const [msgBtn, setMsgBtn] = useState(0)

    const renderAddMessageBtn = () => {
        switch (msgBtn) {
            case 0:
                return "Add"
            case 1:
                return <DoneAll />
            case 2:
                return <CircularProgress size={25} />
            case -1:
                return "Error"
            default:
                return ""
        }
    }

    const [closeBtn, setCloseBtn] = useState(0)

    const renderCloseBtn = () => {
        switch (closeBtn) {
            case 0:
                return "Mark Resolved"
            case 1:
                return <DoneAll />
            case 2:
                return <CircularProgress size={25} />
            case -1:
                return "Error"
            default:
                return ""
        }
    }

    const closeTicket = () => {
        setCloseBtn(2)
        const req = new GeneralRequest();
        req.setMessage(ticketId)
        supportClient.closeTicket(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setCloseBtn(-1)
            } else {
                setCloseBtn(1)
                getTicketDetails()
                dispatch({ type: 'REMOVE_ACTIVE_TICKETS' })
                dispatch({ type: 'REMOVE_CLOSED_TICKETS' })
            }
        })
    }

    const renderDialog = () => {
        return <Dialog
            open={dialogStat}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeMsgDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-slide-title" align="left">Add additional details to your ticket.</DialogTitle>
            <DialogContent>
                <TextField
                    id="issue-description"
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={20}
                    className={classes.issueText}
                    value={newMessage}
                    onChange={addNewMessage}
                    error={newMessage.length > 2048}
                    helperText={newMessage.length > 2048 ? "Max 2048 characters" : " "}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeMsgDialog}>
                    Cancel
                </Button>
                <Button
                    onClick={addMessage}
                    color="primary"
                    disabled={msgBtn !== 0}
                >
                    {renderAddMessageBtn()}
                </Button>
            </DialogActions>
        </Dialog>
    }

    const renderMessages = () => {
        return messages.map((m) => {
            return <Grid
                container
                direction="row"
                justify={m.messageby === "You" ? "flex-end" : "flex-start"}
                alignItems="center"
                className={classes.communeCard}
                key={m.messageid}
            >
                <Typography variant="body2" color="textPrimary" align={"left"} className={classes.communeMsg}>
                    {
                        m.messagetext.split("<br/>").map(function (item, idx) {
                            return <span key={idx}>
                                {item}
                                <br />
                            </span>
                        })
                    }
                </Typography>
            </Grid>
        })
    }

    const render = () => {
        if (stat === 0) {
            return <>
                <Paper className={classes.ciPaper} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <IconButton
                            style={{ marginRight: '10px' }}
                            onClick={() => history.goBack()}
                        >
                            <KeyboardBackspace />
                        </IconButton>
                        <Typography variant="h6" align="left">
                            Ticket Details
                        </Typography>
                    </Grid>
                    <br />
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Typography variant="caption" color="textPrimary" className={classes.ticketDetailsCont}>
                            Ticket ID - <span className={classes.ticketDetailsValue}>{ticketId}</span>
                            <br />
                            Status - <span className={classes.ticketDetailsValue}>{ticket.ticketstatus}</span>
                        </Typography>
                        <Typography variant="caption" color="textPrimary" className={classes.ticketDetailsCont}>
                            Created At - <span className={classes.ticketDetailsValue}>{ticket.creationtime}</span>
                            <br />
                            Last Updated At - <span className={classes.ticketDetailsValue}>{ticket.lastupdatetime}</span>
                        </Typography>
                        <Typography variant="caption" color="textPrimary" className={classes.ticketDetailsCont}>
                            Created By - <span className={classes.ticketDetailsValue}>{ticket.creator}</span>
                            <br />
                            {/* Handling Agent - <span className={classes.ticketDetailsValue}>
                                {ticket.isagentassigned ? ticket.agentid : "Not Assigned Yet"}
                            </span> */}
                        </Typography>
                    </Grid>
                </Paper>
                <br />
                <Paper className={classes.ciPaper} >
                    <Typography variant="h6" align="left">
                        {ticket.subject}
                    </Typography>
                    <br />
                    <Divider />
                    <CardContent className={classes.communeCont}>
                        {renderMessages()}
                        {/* <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            className={classes.communeCard}
                        >
                            <Typography variant="body2" color="textPrimary" align="right" className={classes.communeMsg}>
                                Hello, hi there.
                            </Typography>
                        </Grid> */}
                        {
                            ticket.ticketstatus === "Closed" ? <Typography variant="body2" color="textPrimary" align="center" className={classes.communeMsgClosed}>
                                The ticket has been closed.
                            </Typography> : <React.Fragment />
                        }
                    </CardContent>
                    <br />
                    {
                        ticket.ticketstatus === "Open" ? <React.Fragment>
                            <Divider />
                            <CardContent>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    className={classes.communeCard}
                                >
                                    <Button color="primary" onClick={openMsgDialog}>
                                        Add Message
                                    </Button>
                                    <Button color="secondary" onClick={closeTicket}>
                                        {renderCloseBtn()}
                                    </Button>
                                </Grid>
                            </CardContent>
                            {renderDialog()}
                        </React.Fragment> : <React.Fragment />
                    }
                </Paper>
            </>
        } else if (stat === 2) {
            return <CircularProgress size={25} />
        } else if (stat === 3) {
            // history.push("/mismatch")
        } else {
            return <Typography>
                Something went wrong.
            </Typography>
        }
    }

    return <React.Fragment>
        {render()}
    </React.Fragment >
}