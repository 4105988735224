import React from 'react'
import Personal from './personal/index';
import Contact from './contact';
import Educational from './educational';
import Account from './account';
import TabView from '../../tabView'

export default function AccountInfo() {

    return <TabView
        labels={["Pesonal", "Contact", "Academic", "Account"]}
        viewComponents={[<Personal />, <Contact />, <Educational />, <Account />]}
    />
}