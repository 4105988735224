import React, { useState } from "react";
import { CardContent, Button, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


export default function PendingActivation() {
    const [btnStat, setBtnStat] = useState(0)

    const btn = () => {
        if (btnStat === 0) {
            return <Button color="primary" onClick={handleSubmit}>
                Resend the email?
            </Button>
        } else if (btnStat === 2) {
            return <CircularProgress size={25} />
        } else if (btnStat === 1) {
            return <Button disabled>
                Link Sent. Check your Inbox.
            </Button>
        } else {
            return <Button disabled>
                Error
            </Button>
        }
    }

    const handleSubmit = () => {
        setBtnStat(2)
        setTimeout(() => {
            setBtnStat(-1)
        }, 859)
    }

    return <CardContent>
        <Typography variant="h5" gutterBottom>
            Stop There!
        </Typography>
        <br />
        <Typography variant="body1" gutterBottom>
            We have sent you a link on your email address to verify the ownership of it.
            <br />
            Please open it to activate your account.
        </Typography>
        <br /><br /><br />
        {btn()}
    </CardContent>
}