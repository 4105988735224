import React from "react";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { useStyles } from "./styles";


export default function SetupStep1(props) {
    const classes = useStyles();

    return <React.Fragment>
        <Card className={classes.card} >
            <CardContent>
                <Typography variant="h6" color="textPrimary">
                    Hello {props.name}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    There are few steps remaining for your account setup.
                    <br />
                    Lets complete those.
                </Typography>
            </CardContent>
            <CardContent>
                <Button variant="contained" color="primary" onClick={() => props.setStep(2)} >
                    Next
                </Button>
            </CardContent>
        </Card>
    </React.Fragment>
}