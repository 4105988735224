import React from 'react';
import { Paper, Typography, CardContent, Grid, CircularProgress } from '@material-ui/core';
import { securityStyles } from '../securityStyles';
import { useGetActivities } from '../../customHooks/useGetActivities'

export default function Activity() {
    const classes = securityStyles()

    const [stat, activities] = useGetActivities()

    const mapActivities = () => {
        return activities.map(a => {
            return <CardContent className={classes.activityCard} key={a.time}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    <Typography
                        variant="body1"
                        align="left"
                        color="textPrimary"
                        className={classes.activityType}
                    >
                        {a.activitytype}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        align="left"
                        noWrap
                        className={classes.activityData}
                    >
                        {a.time}
                        <br />
                        {
                            a.primarydata.length > 50 ? a.primarydata.substring(0, 50) + "..." : a.primarydata
                        }
                        <br />
                        {
                            a.secondarydata.length > 50 ? a.secondarydata.substring(0, 50) + "..." : a.secondarydata
                        }
                        <br />
                        {
                            a.tertiarydata.length > 50 ? a.tertiarydata.substring(0, 50) + "..." : a.tertiarydata
                        }
                    </Typography>
                </Grid>
            </CardContent>
        })
    }

    const render = () => {
        if (stat === 2) {
            return <CircularProgress size={30} />
        } else if (stat === 0) {
            return <>
                <Typography variant="h6" align="left" color="textPrimary">
                    Recent Security Activities
                </Typography>
                <Typography variant="body1" align="left" color="textSecondary">
                    Upto 20 activities within last three months.
                </Typography>
                <br />
                {mapActivities()}
            </>
        } else {
            <Typography align="left" color="textSecondary">
                Unable to load data
            </Typography>
        }
    }

    return <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={10}>
            <Paper className={classes.signinPaper}>
                {render()}
            </Paper>
        </Grid>
    </Grid>
}