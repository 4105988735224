import React, { useState } from 'react'
import { Typography, Paper, TextField, CardContent, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { helpCenterStyles } from './styles';
import GetMetadata from '../customComponents/getMetadata';
import { useDispatch, useSelector } from 'react-redux';
import { SupportServerAddress } from '../../protos/server';
import { SupportServiceClient } from '../../protos/support_grpc_web_pb.js';
import { CreateTicketRequest } from '../../protos/support_pb.js';
import { DoneAll } from '@material-ui/icons';

export default function CreateIssue() {
    const classes = helpCenterStyles();
    const dispatch = useDispatch()
    const [values, setValues] = useState({
        type: "",
        subject: "",
        desc: "",
    })
    const [btnStat, setBtnStat] = useState(0)
    const userData = useSelector(state => state.userData)

    const changeValue = (type) => (event) => {
        setBtnStat(0)
        setValues({ ...values, [type]: event.target.value })
    }

    const btn = () => {
        switch (btnStat) {
            case 0:
                return "Submit"
            case 1:
                return <DoneAll />
            case 2:
                return <CircularProgress size={25} />
            case -1:
                return "Error"
            default:
                return ""

        }
    }

    const submitTicket = () => {
        if (values.type !== "" && values.desc.length > 1 && values.subject.length > 1) {
            setBtnStat(2)
            const client = new SupportServiceClient(SupportServerAddress, null, null)
            const req = new CreateTicketRequest();
            req.setTickettype(values.type)
            req.setSubject(values.subject)
            req.setDescription(values.desc.replace(/\r\n|\r|\n/g, "<br/>"))
            const md = GetMetadata()
            md.authorization = userData.token
            client.createTicket(req, md, (err, response) => {
                if (err) {
                    console.log(err)
                    setBtnStat(-1)
                } else {
                    const res = response.toObject()
                    if (res.message === "Success") {
                        setBtnStat(1)
                        dispatch({ type: 'REMOVE_ACTIVE_TICKETS' })
                    }
                }
            })
        }
    }

    return <Paper className={classes.ciPaper}>
        <Typography variant="h6">
            Create new support issue.
        </Typography>
        <br />
        <CardContent>
            <FormControl className={classes.issueSelect}>
                <InputLabel id="issue-type-selection">Select your issue type</InputLabel>
                <Select
                    labelId="issue-type-selection"
                    id="issue-type-selection-list"
                    value={values.type}
                    onChange={changeValue("type")}
                >
                    <MenuItem value={"account"}>Account</MenuItem>
                    <MenuItem value={"security"}>Security</MenuItem>
                    <MenuItem value={"privacy"}>Privacy</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                </Select>
            </FormControl>
            <br />
            <TextField
                id="issue-subject"
                label="Subject"
                variant="outlined"
                className={classes.issueText}
                value={values.subject}
                onChange={changeValue("subject")}
                error={values.subject.length > 256}
                helperText={values.subject.length > 256 ? "Max 256 characters allowed" : " "}
            />
            <br />
            <br />
            <TextField
                id="issue-description"
                label="Description"
                variant="outlined"
                multiline
                rows={20}
                className={classes.issueText}
                value={values.desc}
                onChange={changeValue("desc")}
                error={values.desc.length > 1900}
                helperText={values.desc.length > 1900 ? "Max 1900 characters allowed" : " "}
            />
            <br />
            <br />
            <Button
                variant="outlined"
                color="primary"
                onClick={submitTicket}
                disabled={btnStat !== 0 || values.type === "" || values.desc.length < 1 || values.subject.length < 1}
            >
                {btn()}
            </Button>
        </CardContent>
    </Paper>
}