import { makeStyles } from '@material-ui/core/styles';

export const loginStyles = makeStyles((theme) => ({
    grid: {
        width: '90%',
        height: '100%',
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            height: '100vh',
            width: '100%',
        },
    },
    gridIcon: {
        marginBottom: '20px',
    },
    paper: {
        width: '100%',
        minWidth: '300px',
        maxWidth: '500px',
        height: '100%',
        minHeight: '300px',
        maxHeight: '500px',
        padding: '20px',
        [theme.breakpoints.down('md')]: {
            marginTop: '40px',
        },
    },
    logoCont: {
        height: '80px',
        textAlign: 'left',
    },
    logoHeader: {
        font: 'Serif',
        fontFamily: 'Times New Roman, Times, serif',
        fontSize: '300%',
        color: theme.palette.primary.main,
        marginTop: '-10px',
        marginBottom: '-7px',
    },
    logoSubHeader: {
        font: 'Serif',
        fontFamily: 'Times New Roman, Times, serif',
        fontSize: '120%',
        marginLeft: '3px',
        color: theme.palette.text.secondary,
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '80%',
        maxWidth: '250px',
        minWidth: '250px',
    },
}));