import React from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@material-ui/core";
import { Home, AccountBox, Security, LiveHelp, Apps } from "@material-ui/icons";
import { useStyles } from './styles';
import { Link } from "react-router-dom";

export default function SideBar(props) {
    const classes = useStyles();
    const menuItem = props.menuItem;

    const closeSideBar = () => {
        props.close()
    }

    return <React.Fragment>
        <Divider />
        <List
            subheader={
                <ListSubheader id="general-subheader" className={classes.sideSubHead}>
                    General
                </ListSubheader>
            }
        >
            <ListItem
                button
                component={Link}
                to="/dashboard/home/"
                className={menuItem.toLowerCase() === "home" ? classes.selected : classes.notSelected}
                onClick={closeSideBar}
            >
                <ListItemIcon>
                    <Home fontSize="small" className={menuItem.toLowerCase() === "home" ? classes.selected : classes.notSelected} />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
        </List>
        <Divider light />
        <List
            subheader={
                <ListSubheader id="account-subheader" className={classes.sideSubHead}>
                    Account
                </ListSubheader>
            }
        >
            <ListItem
                button
                component={Link}
                to="/dashboard/accountInfo/"
                className={menuItem.toLowerCase() === "accountinfo" ? classes.selected : classes.notSelected}
                onClick={closeSideBar}
            >
                <ListItemIcon>
                    <AccountBox fontSize="small" className={menuItem.toLowerCase() === "accountinfo" ? classes.selected : classes.notSelected} />
                </ListItemIcon>
                <ListItemText primary="Info" />
            </ListItem>
            {/* <ListItem
                button
                component={Link}
                to="/dashboard/accountPrivacy/"
                className={menuItem.toLowerCase() === "accountprivacy" ? classes.selected : classes.notSelected}
                onClick={closeSideBar}
            >
                <ListItemIcon>
                    <LockOpen fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Privacy" />
            </ListItem> */}
            <ListItem
                button
                component={Link}
                to="/dashboard/accountSecurity/"
                className={menuItem.toLowerCase() === "accountsecurity" ? classes.selected : classes.notSelected}
                onClick={closeSideBar}
            >
                <ListItemIcon>
                    <Security fontSize="small" className={menuItem.toLowerCase() === "accountsecurity" ? classes.selected : classes.notSelected} />
                </ListItemIcon>
                <ListItemText primary="Security" />
            </ListItem>
        </List>
        <Divider light />
        <List
            subheader={
                <ListSubheader id="identity-subheader" className={classes.sideSubHead}>
                    Third Party
                </ListSubheader>
            }
        >
            <ListItem
                button
                component={Link}
                to="/dashboard/appLogins/"
                className={menuItem.toLowerCase() === "applogins" ? classes.selected : classes.notSelected}
                onClick={closeSideBar}
            >
                <ListItemIcon>
                    <Apps fontSize="small" className={menuItem.toLowerCase() === "applogins" ? classes.selected : classes.notSelected} />
                </ListItemIcon>
                <ListItemText primary="App Logins" />
            </ListItem>
            {/* <ListItem
                button
                component={Link}
                to="/dashboard/configureAppsLogin/"
                className={menuItem.toLowerCase() === "configureappslogin" ? classes.selected : classes.notSelected}
                onClick={closeSideBar}
            >
                <ListItemIcon>
                    <Build fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Configure" />
            </ListItem> */}
        </List>
        <Divider light />
        <List
            subheader={
                <ListSubheader id="support-subheader" className={classes.sideSubHead}>
                    Support
                </ListSubheader>
            }
        >
            {/* <ListItem
                button
                component={Link}
                to="/dashboard/commonIssues/"
                className={menuItem.toLowerCase() === "commonissues" ? classes.selected : classes.notSelected}
                onClick={closeSideBar}
            >
                <ListItemIcon>
                    <Forum fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Common Issues" />
            </ListItem> */}
            <ListItem
                button
                component={Link}
                to="/dashboard/helpCenter/"
                className={menuItem.toLowerCase() === "helpcenter" ? classes.selected : classes.notSelected}
                onClick={closeSideBar}
            >
                <ListItemIcon>
                    <LiveHelp fontSize="small" className={menuItem.toLowerCase() === "helpcenter" ? classes.selected : classes.notSelected} />
                </ListItemIcon>
                <ListItemText primary="Help Center" />
            </ListItem>
        </List>
    </React.Fragment>
}