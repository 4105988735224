import { Typography, Paper, Grid, Link as MLink, CircularProgress, Divider, Link, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { oauthStyles } from './styles';
import { AuthServiceClient } from '../../../protos/auth_grpc_web_pb';
import { AuthorizeAppRequest, GrantPermissionRequest, Perms } from '../../../protos/auth_pb';
import { AuthServerAddress } from '../../../protos/server';
import { useSelector } from 'react-redux';
import GetMetadata from '../../customComponents/getMetadata';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// http://localhost:3000/service66/authorize/?client_id=1234123412341234&response_type=code&redirect_uri=http://localhost:3000&state=abcdefghijkl

export default function Authorize() {
    const classes = oauthStyles();
    const query = useQuery()
    const responseType = query.get('response_type')
    const clientID = query.get('client_id')
    const redirectURI = query.get('redirect_uri')
    const urlState = query.get('state')
    const token = useSelector(state => state.token)
    const [perms, setPerms] = useState([])

    const [values, setValues] = useState({})
    const [stat, setStat] = useState(10)
    const [btnStat, setBtnStat] = useState(0)

    const callServer = () => {
        setStat(2)
        // call server with given details
        const client = new AuthServiceClient(AuthServerAddress, null, null)
        const req = new AuthorizeAppRequest();
        req.setClientid(clientID);
        req.setState(urlState);
        req.setResponsetype(responseType);
        req.setRedirecturi(redirectURI);

        // http://localhost:3000/authorize?client_id=1234123412341234&state=abcdefghijklmnop&response_type=code&redirect_uri=http://localhost:3000&code_challenge=abcd&code_challenge_method=abcd

        const md = GetMetadata()
        md.Authorization = token
        client.authorizeApp(req, md, (err, response) => {
            if (err) {
                setStat(-1)
            } else {
                const res = response.toObject()
                if (res.haveerror) {
                    if (res.error === "regen") {
                        window.location.replace(redirectURI + "/?code=" + res.authcode + "&token=" + res.authtoken + "&state=" + res.state)
                        return
                    }
                    window.location.replace(redirectURI + "/?error=" + res.error + "&error_description=" + res.errordescription + "&state=" + urlState)
                } else {
                    setValues(res)
                    setPerms(res.permsList)
                    setStat(0)
                }
            }
        })
    }

    useEffect(() => {
        callServer();
    }, [])

    const handlePermChange = (i) => {
        let newPerms = [...perms]
        newPerms[i].checked = !newPerms[i].checked;
        setPerms(newPerms)
    }

    const renderPerms = () => {
        return perms.map((p, i) => {
            return <FormControlLabel
                key={p.permid}
                disabled={btnStat === 2}
                control={
                    <Checkbox
                        checked={perms[i].checked}
                        onChange={() => handlePermChange(i)}
                        color="primary"
                    />
                }
                label={p.permdesc}
                style={{ width: '98%' }}
            />
        })
    }

    const grantPerms = () => {
        setBtnStat(2)
        const client = new AuthServiceClient(AuthServerAddress, null, null)
        var newPerms = [];
        for (var i = 0; i < perms.length; i++) {
            if (perms[i].checked) {
                const gPerms = new Perms();
                gPerms.setPermid(perms[i].permid)
                gPerms.setPermname(perms[i].permname)
                gPerms.setPermdesc(perms[i].permdesc)
                newPerms.push(gPerms)
            }
        }
        const req = new GrantPermissionRequest();
        req.setPermsList(newPerms)
        req.setState(urlState)
        req.setClientid(clientID)
        req.setRedirecturi(redirectURI)
        req.setResponsetype(responseType)

        const md = GetMetadata()
        md.Authorization = token
        client.grantPermission(req, md, (err, response) => {
            if (err) {
                setStat(-1)
            } else {
                const res = response.toObject()
                window.location.replace(redirectURI + "/?code=" + res.authcode + "&token=" + res.authtoken + "&state=" + res.state)
            }
        })
    }

    const cancelPerms = () => {
        window.location.replace(redirectURI + "/?error=access_denied&error_description=access%20denied%by%20user%20on%20consent%20screen&state=" + urlState)
    }

    const renderBtn = () => {
        if (btnStat === 0) {
            return <Button color="primary" onClick={grantPerms}>
                Proceed
            </Button>
        } else if (btnStat === 2) {
            return <Button color="primary">
                <CircularProgress size={25} />
            </Button>
        } else {
            return <></>
        }
    }

    const render = () => {
        if (stat === 0) {
            return <Paper className={classes.authorizeFormPaper}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <div className={classes.logoCont}>
                        <Typography className={classes.logoHeader}>
                            MazeYard
                        </Typography>
                        <Typography className={classes.logoSubHeader}>
                            Identity Serivce66
                        </Typography>
                    </div>
                    <Typography variant="body1" color="textSecondary" align="left">
                        Login with
                        <br />
                        Service66
                    </Typography>
                </Grid>
                <Divider light />
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.justGrid}
                >
                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                    >
                        <img src={values.applogo} alt="Logo" className={classes.appLogo} />
                        <Typography variant="body1" className={classes.permMsg}>
                            Login to {values.appname}
                            <br />
                            with MY account
                        </Typography>
                        {/* <Typography variant="caption"> */}
                        {/* {values.myverified ? <React.Fragment>MazeYard Verified<br /></React.Fragment> : <React.Fragment />}
                                {values.mytrusted ? <React.Fragment>MazeYard Trusted<br /></React.Fragment> : <React.Fragment />} */}
                        {/* {values.myowned ? <React.Fragment>MazeYard Owned<br /></React.Fragment> : <React.Fragment />} */}
                        {/* </Typography> */}
                    </Grid>
                    <br />
                    <Typography variant="caption" align="center">
                        You are sharing following permissions to {values.appname}.
                        <br />
                        Uncheck any which you don't want to.
                    </Typography>
                    {/* {values.myowned ? <Typography variant="caption">We recomment to keep all checked for MazeYard owned apps.</Typography> : <React.Fragment />} */}
                    <div className={classes.permCont}>
                        {renderPerms()}
                    </div>
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        className={classes.justGrid}
                    >
                        <div>
                            <Link href={values.terms}>
                                App's Terms
                            </Link>
                            <br />
                            <Link href={values.privacypolicy}>
                                App's Privacy Policy
                            </Link>
                        </div>
                        <Button onClick={cancelPerms} disabled={btnStat === 2}>
                            Cancel
                        </Button>
                        {renderBtn()}
                    </Grid>
                </Grid>
            </Paper>
        } else if (stat === 2) {
            return <Paper className={classes.authorizePaper}>
                <br />
                <br />
                <CircularProgress size={30} />
            </Paper>
        } else if (stat === -1) {
            return <Paper className={classes.authorizePaper}>
                <Typography color="textPrimary" variant="body1">
                    Something went wrong.
                    <br />
                    Insuffiecient information provided by client.
                </Typography>
                <br />
                <MLink href="/">
                    Back to Home
                </MLink>
            </Paper>
        } else {
            return <></>
        }
    }

    return <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.grid}
    >
        {render()}
    </Grid>
}