import { makeStyles } from '@material-ui/core/styles';

export const securityStyles = makeStyles((theme) => ({
    tabBar: {
        position: 'fixed',
        top: 62,
        height: 60,
        zIndex: 1,
        padding: '6px 0',
        backgroundColor: theme.palette.background.page,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            top: 55,
        },
    },
    signinPaper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(2),
        // marginTop: 50,
    },
    activityGrid: {
        width: '100%',
        maxWidth: '100%',
    },
    signinInput: {
        marginTop: 15,
        marginBottom: 20,
        width: '100%',
        maxWidth: '450px',
    },
    activityCard: {
        // flexGrow: 3,
        maxWidth: '100%',
        margin: 0,
    },
    activityType: {
        width: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    activityData: {
        width: `calc(100% - 200px)`,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        overflowX: 'hide'
    }
}));