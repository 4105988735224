import React, { useState } from "react";
import { Card, CardContent, Typography, TextField, Button, Grid, CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles.js";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { AuthServiceClient } from '../protos/auth_grpc_web_pb';
import { SignupStepRequest } from '../protos/auth_pb';
import { AuthServerAddress } from '../protos/server';


const PurpleSwitch = withStyles({
	switchBase: {
		color: '#8982FF',
		'&$checked': {
			color: '#6C63FF',
		},
		'&$checked + $track': {
			backgroundColor: '#6C63FF',
		},
	},
	checked: {},
	track: {},
})(Switch);

export default function RegisterStep3(props) {
	const classes = useStyles();
	const [err, setErr] = useState(" ");
	const [btnStat, setBtnStat] = useState("f");

	const handleChange = (event) => {
		if (event.target.checked) {
			props.setValues({ ...props.values, userRole: "s" })
		} else {
			props.setValues({ ...props.values, userRole: "t" })
		}
	};

	const changeValue = (prop) => (event) => {
		props.setValues({ ...props.values, [prop]: event.target.value });
		setErr(" ");
	}

	const prevStep = () => {
		props.setStep(2);
	}

	const nextStep = () => {
		setBtnStat("l");
		if (props.values.userID !== "" && props.values.userRole !== "") {
			const client = new AuthServiceClient(AuthServerAddress, null, null)
			const req = new SignupStepRequest();
			req.setStep(3)
			req.setUserid(props.values.userID)
			req.setUserrole(props.values.userRole)

			client.signupStep(req, {}, (err, response) => {
				if (err) {
					setErr(err.message);
					setBtnStat("e");
					// if (err.message) {
					// 	switch (err.message) {
					// 		case "Invalid UserID":
					// 			setErr("Invalid ID");
					// 		case "Invalid User Role":
					// 			setErr("Invalid User Role");
					// 		case "ID Exists":
					// 			setErr("ID Exists");
					// 		case "Invalid ID":
					// 			setErr("Invalid ID");
					// 		case "Invalid Input":
					// 			setErr("Invalid Input");
					// 		case "Something went wrong":
					// 			setErr("Something went wrong");
					// 		default:
					// 			setErr("Something went wrong");
					// 	}
					// }
				} else {
					const res = response.toObject()
					if (res.response === "Okay") {
						setBtnStat("f");
						props.setStep(4);
					}
					setBtnStat("e");
				}
			})
			// axios({
			// 	method: 'POST',
			// 	url: '/auth/valid/step3',
			// 	data: qs.stringify({
			// 		ui: props.values.userID,
			// 		ur: props.values.userRole,
			// 	}),
			// }).then((response) => {
			// 	if (response.data === "id length err") {
			// 		setErr("Invalid ID");
			// 	} else if (response.data === "role err") {
			// 		setErr("Role Error");
			// 	} else if (response.data === "matched") {
			// 		setErr("ID is already registered");
			// 	} else if (response.data === "okay") {
			// 		setErr(" ");
			// 		props.setStep(4);
			// 	} else {
			// 		setErr("Something went wrong");
			// 	}
			// 	setBtnStat("f");
			// }, (error) => {
			// 	console.log(error);
			// 	setBtnStat("e");
			// })
		}
	}

	const nextBtn = () => {
		if (btnStat === "l") {
			return <Button color="primary" >
				<CircularProgress size={25} />
			</Button>
		} else if (btnStat === "f") {
			return <Button variant="contained"
				color="primary"
				disabled={props.values.userID.length < 5}
				onClick={nextStep}
			>
				Next
			</Button>
		} else {
			return <Button disabled >
				Error
			</Button>
		}
	}

	const render = () => {
		return <Card className={classes.card}>
			<CardContent>
				<Typography variant="h6" color="textPrimary" gutterBottom>
					A bit of Educational
				</Typography>
				<Typography variant="body1" color="textSecondary">
					We will verify the account with the same
				</Typography>
			</CardContent>
			<CardContent>
				<form autoComplete="off" className={classes.form} >
					<TextField
						className={classes.textField}
						id="standard-basic"
						label="Student/Employee ID"
						value={props.values.userID}
						error={err !== " "}
						onChange={changeValue('userID')}
						helperText={err}
					/><br />
				</form>
				<Typography component="div">
					<Grid component="label" container justify="center" alignItems="center" spacing={1}>
						<Grid item>Teacher &nbsp;&nbsp;&nbsp;&nbsp;</Grid>
						<Grid item>
							<FormControlLabel
								control={
									<PurpleSwitch checked={props.values.userRole === "s"} onChange={handleChange} />}
								label=" "
							/>
						</Grid>
						<Grid item>Student</Grid>
					</Grid>
				</Typography>
			</CardContent>
			<CardContent>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Button
						color="primary"
						onClick={prevStep}
					>
						Previous
					</Button>
					{nextBtn()}
				</Grid>
			</CardContent>
		</Card>
	}

	return render()
}