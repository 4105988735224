import React, { useState } from 'react';
import { AppBar, Menu, MenuItem, Toolbar, CssBaseline, Drawer, Hidden, IconButton, Avatar } from '@material-ui/core';
import { Menu as MenuIcon, AccountCircle } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import SideBar from './sidebar';
import { useStyles } from './styles';
import Home from './home';
import AccountInfo from './account/info';
import LogOutDialog from './logoutDialog';
import Security from './security';
import HelpCenter from './helpCenter';
import AppsLogin from './appsLogin';
import logo from '../statics/logo.png';
import logoInverted from '../statics/logo_inverted.png';

export default function Service66(props) {
    const { window } = props;
    const classes = useStyles();
    const { param1 } = useParams();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [dialogState, setDialogState] = useState(false)

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <img src={logo} alt="Logo" className={classes.toolbarLogo} />
            </div>
            <SideBar menuItem={param1} close={() => setMobileOpen(false)} />
        </div>
    );


    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem
                onClick={() => {
                    setDialogState(true)
                    handleMenuClose()
                }}
                className={classes.logoutBtn}
            >
                Logout
            </MenuItem>
            <LogOutDialog state={dialogState} setState={setDialogState} />
        </Menu>
    );

    const renderMain = () => {
        switch (param1) {
            case "home":
                return <Home />
            case "accountInfo":
                return <AccountInfo />
            // case "accountPrivacy":
            //     return <Privacy />
            case "accountSecurity":
                return <Security />
            case "appLogins":
                return <AppsLogin />
            case "helpCenter":
                return <HelpCenter />
            default:
                return <Home />
        }
    }

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Avatar alt="logo" src={logoInverted} className={classes.appBarLogo} variant="square" />
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {renderMain()}
            </main>
        </div>
    );
}
