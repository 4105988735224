import React, { useState } from "react";
import { Paper, Typography, TextField, Button, Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AuthServiceClient } from '../protos/auth_grpc_web_pb';
import { ResetPasswordRequest } from '../protos/auth_pb';
import { AuthServerAddress } from '../protos/server';

const useStyles = makeStyles({
	paper: {
		padding: '50px',
		width: '100%',
		maxWidth: '600px',
		height: '320px',
		display: 'inline-block',
		marginTop: '50px',
	},
	grid: {
		width: '100%',
		height: '100%',
	},
	input: {
		width: '290px',
	}
});

export default function ResetPassword() {
	const classes = useStyles();
	const [stat, setStat] = useState("f");
	const [email, setEmail] = useState("");

	const emailInput = (event) => {
		setEmail(event.target.value);
	}

	const render = () => {
		if (stat === "f") {
			return <>
				<Typography variant="subtitle2" gutterBottom color="textSecondary">
					Enter your email.
					<br />
					We will send you a link to reset your password.
				</Typography>
				<form noValidate autoComplete="off">
					<TextField id="standard-basic" label="Your Email" value={email} onChange={emailInput} className={classes.input} />
				</form>
				<Button color="primary" disabled={email.length < 5} onClick={submit}>
					Send
				</Button>
			</>
		} else if (stat === "l") {
			return <CircularProgress />
		} else if (stat === "s") {
			return <Typography variant="subtitle2" color="textSecondary" gutterBottom>
				Email sent.
				<br />
				Open your inbox and click the link.
			</Typography>
		} else {
			return <>
				<Typography variant="subtitle2" color="error" gutterBottom>
					Something went wrong.
					<br />
					Please try again later.
				</Typography>
				<Button color="primary" onClick={() => setStat("f")}>
					Retry?
				</Button>
			</>
		}
	}

	const submit = () => {
		setStat("l");
		const client = new AuthServiceClient(AuthServerAddress, null, null)
		const req = new ResetPasswordRequest();
		req.setRequesttype("request")
		req.setEmail(email)

		client.resetPassword(req, {}, (err, response) => {
			if (err) {
				setStat("e")
			} else {
				const res = response.toObject()
				if (res.response === "Success") {
					setStat("s")
				}
			}
		})
	}

	return <Paper elevation={0} className={classes.paper}>
		<Grid
			container
			direction="column"
			justify="space-between"
			alignItems="center"
			className={classes.grid}
		>
			{render()}
		</Grid>
	</Paper>
}