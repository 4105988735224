import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { loginStyles } from './styles';
import LoginForm from "./loginForm";
import { useSelector } from 'react-redux';
import Submitted from "./submitted";
import Suspended from "./suspended";
import PendingActivation from "./pendingActivation";
import AccountSetup from "./accSetup";

export default function Login() {
    const classes = loginStyles();
    const loginStat = useSelector(state => state.loggedIn)

    const render = () => {
        switch (loginStat) {
            case "notLogged":
                return <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.grid}
                >
                    <Paper className={classes.paper}>
                        <LoginForm />
                    </Paper>
                </Grid>
            case "submitted":
                return <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.grid}
                >
                    <Paper className={classes.paper}>
                        <Submitted />
                    </Paper>
                </Grid>
            case "suspended":
                return <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.grid}
                >
                    <Paper className={classes.paper}>
                        <Suspended />
                    </Paper>
                </Grid>
            case "pendingActivation":
                return <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.grid}
                >
                    <Paper className={classes.paper}>
                        <PendingActivation />
                    </Paper>
                </Grid>
            case "pendingUpdate":
                return <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.grid}
                ><AccountSetup />
                </Grid>
            default:
                return <React.Fragment />
        }
    }

    return <React.Fragment>
        {render()}
    </React.Fragment>
}