import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    avatar: {
        height: '100%',
        minHeight: '200px',
        minWidth: '200px',
    },
    avatarImg: {
        width: '200px',
        height: '200px',
        objectFit: 'contain',
        borderRadius: '50%',
    },
    tabsBar: {
        border: '1px solid'
    },

    permsCont: {
        [theme.breakpoints.down('sm')]: {
            order: 1,
        },
    },

    contactInput: {
        width: '100%',
        maxWidth: '400px',
        marginBottom: theme.spacing(2),
    },
    contactSelect: {
        width: '100%',
        minWidth: '200px',
        marginBottom: theme.spacing(1),
        marginTop: '-5px',
    },
    contactBtns: {
        marginTop: '15px',
    },
    instiLogo: {
        height: 100,
        width: 100,
    },

    // profile picture upload dialog
    filePaper: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 0,
        },
        width: '100%',
        minWidth: 350,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));