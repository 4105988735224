import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Paper, Typography, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams, Link } from 'react-router-dom';
import { AuthServiceClient } from '../protos/auth_grpc_web_pb';
import { VerifyEmailRequest } from '../protos/auth_pb';
import { AuthServerAddress } from '../protos/server';

const useStyles = makeStyles({
	paper: {
		padding: '50px',
		width: '100%',
		maxWidth: '600px',
		height: '320px',
		display: 'inline-block',
		marginTop: '50px',
	},
	grid: {
		width: '100%',
		height: '100%',
	}
});

export default function VerifyEmail() {
	const [stat, setStat] = useState(2)
	const classes = useStyles();
	const history = useHistory();
	const { token, ueref } = useParams();

	const render = () => {
		if (stat === 2) {
			return <CircularProgress />
		} else if (stat === 0) {
			return <React.Fragment>
				<Typography variant="subtitle2" gutterBottom color="textSecondary">
					Email address is verified.
					<br />
					Thank you for registering.
				</Typography>
				<Button color="primary" component={Link} to="/dashboard/">
					Login to Dashboard
				</Button>
			</React.Fragment>
		} else if (stat === -1) {
			return <React.Fragment>
				<Typography variant="subtitle2" gutterBottom color="textSecondary">
					Link you provided is expired and your account have been deleted.
					<br />
					You need to register again.
				</Typography>
				<Button color="primary" onClick={() => history.push('/register')}>
					Register Here
				</Button>
			</React.Fragment>
		}
	}

	const checkDetails = () => {
		const client = new AuthServiceClient(AuthServerAddress, null, null)
		const req = new VerifyEmailRequest();
		req.setToken(token)
		req.setUeref(ueref)
		client.verifyEmail(req, {}, (err, response) => {
			if (err) {
				history.push('/mismatch')
			} else {
				const res = response.toObject()
				if (res.message === "Verfied") {
					setStat(0)
				} else if (res.message === "Expired") {
					setStat(-1)
				} else {
					history.push('/mismatch')
				}
			}
		})
	}

	useEffect(() => {
		checkDetails();
	}, [])

	return <React.Fragment>
		<Paper elevation={0} className={classes.paper}>
			<Grid
				container
				direction="column"
				justify="space-between"
				alignItems="center"
				className={classes.grid}
			>
				{render()}
			</Grid>
		</Paper>
	</React.Fragment>

}