import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles";
import axios from "axios";
import { AccountsServerAddress, FileOpsAddress } from '../../../protos/server';
import { AccountsServiceClient } from '../../../protos/accounts_grpc_web_pb';
import { AccountSettingUpRequest } from '../../../protos/accounts_pb';

export default function SetupStepFinal(props) {
    const classes = useStyles();
    const imgData = new FormData();
    const [renStat, setRenStat] = useState("l");
    const d = props.accData
    const u = props.accUpdate

    useEffect(() => {
        sendImg()
    }, []);

    const sendImg = () => {
        imgData.append(
            "frontImg",
            props.accUpdate.frontImg,
            props.accUpdate.frontImg.name,
        );
        imgData.append(
            "backImg",
            props.accUpdate.backImg,
            props.accUpdate.backImg.name,
        );
        imgData.append(
            "ueref",
            d.userreference,
        );
        imgData.append(
            "firstname",
            d.firstname,
        );

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(FileOpsAddress,
            imgData, config)
            .then(res => {
                if (res.data === "Success") {
                    sendData();
                } else {
                    setRenStat("e");
                }
            })
            .catch(err => {
                console.log(err);
                setRenStat("e");
            })

    }

    const sendData = () => {
        const client = new AccountsServiceClient(AccountsServerAddress, null, null)
        const req = new AccountSettingUpRequest();
        req.setRequesttype("set");
        req.setUeref(d.userreference)
        req.setFirstname(d.firstname)
        req.setFieldref(u.field)
        req.setStreamref(u.stream)
        req.setGender(u.ug)

        client.accountSettingUp(req, {}, (err, response) => {
            if (err) {
                console.log(err)
                setRenStat("e")
            } else {
                setRenStat("s")
            }
        })
        // console.log(props.accUpdate.field);
        // console.log(props.accUpdate.stream);
        // console.log(props.accUpdate.ug);
        // axios({
        // 	method: 'POST',
        // 	url: 'http://localhost:5050/auth/accSetup/' + props.tempUeref,
        // 	data: qs.stringify({
        // 		beref: props.accUpdate.field,
        // 		seref: props.accUpdate.stream,
        // 		ug: props.accUpdate.ug,
        // 	}),
        // 	headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
        // }).then((response) => {
        // 	console.log(response);
        // 	if (response.data === "success") {
        // 		setRenStat("s");
        // 	} else {
        // 		console.log(response.data);
        // 		setRenStat("e");
        // 	}
        // }, (error) => {
        // 	console.log(error);
        // 	setRenStat("e");
        // });
    }

    const render = () => {
        if (renStat === "l") {
            return <CircularProgress />
        } else if (renStat === "s") {
            return <React.Fragment>
                <Typography variant="h6" color="textPrimary">
                    Alright!
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    That's it for now.
                    <br />
                    We will notify you on your registered email once your account is verified.
                </Typography>
            </React.Fragment>
        } else {
            return <React.Fragment>
                <Typography variant="h6" color="textPrimary">
                    Oh Ohh!
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    Something went wrong.
                    <br />
                    Please try again later.
                </Typography>
            </React.Fragment>
        }
    }

    return <React.Fragment>
        <Card className={classes.card} >
            <CardContent>
                {render()}
            </CardContent>
        </Card>
    </React.Fragment>
}