import React from 'react';
import { useParams } from 'react-router';
import AppLoginDetails from './appLoginDetails';
import AppsLoginTabs from './appsLoginTabs';

export default function AppsLogin() {
    const { param2 } = useParams();

    const render = () => {
        if (param2 === undefined || param2 === null) {
            return <AppsLoginTabs />
        } else if (param2 === "LoginId") {
            return <AppLoginDetails />
        } else {
            return <AppsLoginTabs />
        }
    }

    return <React.Fragment>
        {render()}
    </React.Fragment>
}