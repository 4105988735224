import React from "react";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { useStyles } from "./styles";

// function imgToBase64(img) {
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');
//     canvas.width = img.width;
//     canvas.height = img.height;

//     // I think this won't work inside the function from the console
//     img.crossOrigin = 'anonymous';

//     ctx.drawImage(img, 0, 0);

//     return canvas.toDataURL();
// }


export default function SetupSte4(props) {
    const classes = useStyles();

    const fileChange = (prop) => (event) => {
        props.setAccUpdate({ ...props.accUpdate, [prop]: event.target.files[0] });
    }

    const sizeErr = () => {
        if (props.accUpdate.frontImg) {
            if (props.accUpdate.frontImg.size > 819200) {
                return <Typography variant="caption" color="error">
                    Front Side image exceeds 800kb.
                    <br />
                    Please compress the file before uploading.
                </Typography>

            }
        }
        if (props.accUpdate.backImg) {
            if (props.accUpdate.backImg.size > 819200) {
                return <Typography variant="caption" color="error">
                    Back Side image exceeds 800kb.
                    <br />
                    Please compress the file before uploading.
                </Typography>

            }
        }
        return <Typography variant="caption">
            Please keep file size lower than 800kb.
        </Typography>
    }

    return <React.Fragment>
        <Card className={classes.card} >
            <CardContent>
                <Typography variant="h6" color="textPrimary">
                    Picture Time.
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    Upload both sides of your ID card.
                    <br />
                    This will be used for your account verification.
                </Typography>
            </CardContent>
            <CardContent>
                <input
                    accept="image/png, image/jpeg"
                    className={classes.imgInput}
                    id="id-front-side"
                    multiple
                    type="file"
                    onChange={fileChange('frontImg')}
                />
                <label htmlFor="id-front-side">
                    <Button
                        className={classes.imgInputBtn}
                        color={props.accUpdate.frontImg === null || props.accUpdate.frontImg.size > 819200 ? "primary" : "textPrimary"}
                        component="span">
                        Front Side
                    </Button>
                </label>
                <input
                    accept="image/png, image/jpeg"
                    className={classes.imgInput}
                    id="id-back-side"
                    multiple
                    type="file"
                    onChange={fileChange('backImg')}
                />
                <label htmlFor="id-back-side">
                    <Button className={classes.imgInputBtn} color={props.accUpdate.backImg === null || props.accUpdate.backImg.size > 819200 ? "primary" : "textPrimary"} component="span">
                        Back Side
                    </Button>
                </label>
            </CardContent>
            <CardContent>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.setStep(5)}
                    disabled={props.accUpdate.frontImg === null || props.accUpdate.backImg === null || props.accUpdate.frontImg.size > 819200 || props.accUpdate.backImg.size > 819200}
                >
                    Submit
                </Button>
            </CardContent>
            <CardContent>
                {sizeErr()}
            </CardContent>
        </Card>
    </React.Fragment>
}