import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles.js";
import { AuthServiceClient } from '../protos/auth_grpc_web_pb';
import { SignupRequest } from '../protos/auth_pb';
import { AuthServerAddress } from '../protos/server';


export default function RegisterStep6(props) {
	const classes = useStyles();
	const [ren, setRen] = useState("l");
	const values = props.values;

	const sendData = () => {
		const client = new AuthServiceClient(AuthServerAddress, null, null)
		const req = new SignupRequest();
		req.setIeref(values.collegeCode)
		req.setFirstname(values.firstName)
		req.setLastname(values.lastName)
		req.setEmail(values.email)
		req.setUserrole(values.userRole)
		req.setUserid(values.userID)
		req.setUsername(values.username)
		req.setPassword(values.password)

		client.signup(req, {}, (err, resp) => {
			if (err) {
				console.log(err)
				setRen("e");
			} else {
				setRen("s");
			}
		})
	}

	useEffect(() => {
		if (ren === "l") {
			sendData();
		}
	})

	const render = () => {
		if (ren === "l") {
			return <Card className={classes.card}>
				<CardContent>
					<Typography>
						<CircularProgress />
					</Typography>
				</CardContent>
			</Card>
		} else if (ren === "s") {
			return <Card className={classes.card}>
				<CardContent>
					<Typography variant="h6" color="textPrimary" gutterBottom>
						Great!
					</Typography>
					<Typography variant="body1" color="textSecondary">
						Your registration is successful.
					</Typography>
				</CardContent>
				<CardContent>
					<Typography variant="body1" color="textPrimary">
						We have sent an activation link to the email you provided.
						<br />
						Open that link to activate your account.
					</Typography>
				</CardContent>
			</Card>
		} else {
			return <Card className={classes.card}>
				<CardContent>
					<Typography variant="h6" color="textPrimary" gutterBottom>
						Oh oh!
					</Typography>
					<Typography variant="body1" color="textSecondary">
						Looks like something is wrong.
					</Typography>
				</CardContent>
				<CardContent>
					<Typography variant="body1" align="center" color="textPrimary">
						Maybe you entered something invalid,
						<br />
						OR
						<br />
						May be just the server is down.
					</Typography>
				</CardContent>
			</Card>
		}
	}

	return render()
}