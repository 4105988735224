import React, { useState } from "react";
import { Card, CardContent, Typography, CircularProgress, TextField, Button, Grid, FormControl, InputLabel, Input, InputAdornment, IconButton } from "@material-ui/core";
import { useStyles } from "./styles.js";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from 'clsx';
import { AuthServiceClient } from '../protos/auth_grpc_web_pb';
import { SignupStepRequest } from '../protos/auth_pb';
import { AuthServerAddress } from '../protos/server';

export default function RegisterStep4(props) {
	const classes = useStyles();
	const [err, setErr] = useState({
		username: "Letters and Numbers Only",
		password: " ",
	})
	const [passVis, setPassVis] = useState(false);
	const [btnStat, setBtnStat] = useState("f");

	const handleClickShowPassword = () => {
		setPassVis(!passVis);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};


	const changeValue = (prop) => (event) => {
		props.setValues({ ...props.values, [prop]: event.target.value });
		if (prop === "username") {
			setErr({ ...err, username: "Letters and Numbers Only" })
		} else {
			setErr({ ...err, [prop]: " " });
		}
	}

	const prevStep = () => {
		props.setStep(3);
	}

	const nextStep = () => {
		setBtnStat("l");
		if (props.values.username.length > 4 && props.values.password.length > 7) {
			const client = new AuthServiceClient(AuthServerAddress, null, null)
			const req = new SignupStepRequest();
			req.setStep(4)
			req.setUsername(props.values.username)
			req.setPassword(props.values.password)

			client.signupStep(req, {}, (err, response) => {
				if (err) {
					setBtnStat("e");
					if (err.message === "Invalid Username") {
						setErr({ ...err, username: "Something wrong here." })
					} else if (err.message === "Invalid Password") {
						setErr({ ...err, password: "Something wrong here." })
					} else if (err.message === "Username Exists") {
						setErr({ ...err, username: "Username already in use." })
					} else {
						setErr({ ...err, username: "Something went wrong" })
					}
				} else {
					const res = response.toObject()
					if (res.response === "Okay") {
						setBtnStat("f");
						props.setStep(6);
					}
					setBtnStat("e");
				}
			})
		}
	}

	const nextBtn = () => {
		if (btnStat === "l") {
			return <Button color="primary" >
				<CircularProgress />
			</Button>
		} else if (btnStat === "f") {
			return <Button variant="contained"
				color="primary"
				disabled={props.values.username.length < 6 || props.values.password.length < 8}
				onClick={nextStep}
			>
				Next
			</Button>
		} else {
			return <Button disabled >
				Error
			</Button>
		}
	}

	const render = () => {
		return <Card className={classes.card}>
			<CardContent>
				<Typography variant="h6" color="textPrimary" gutterBottom>
					Finally the Login Credentials
				</Typography>
				<Typography variant="body1" color="textSecondary">
					You'll need those to log into the App.
				</Typography>
			</CardContent>
			<CardContent>
				<form autoComplete="off" className={classes.form} >
					<TextField
						className={classes.textField}
						id="standard-basic"
						fullWidth
						label="Pick a Username"
						value={props.values.username}
						error={err.username !== "Letters and Numbers Only"}
						onChange={changeValue('username')}
						helperText={err.username}
					/><br />
					<FormControl className={clsx(classes.margin, classes.textField)}>
						<InputLabel htmlFor="standard-adornment-password">And Password</InputLabel>
						<Input
							id="standard-adornment-password"
							type={passVis ? 'text' : 'password'}
							value={props.values.password}
							onChange={changeValue('password')}
							error={err.password !== " "}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{passVis ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</form>
			</CardContent>
			<CardContent>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Button
						color="primary"
						onClick={prevStep}
					>
						Previous
					</Button>
					{nextBtn()}
				</Grid>
			</CardContent>
		</Card>
	}

	return render()
}