import { makeStyles } from '@material-ui/core/styles';

export const homeStyles = makeStyles((theme) => ({
    homeGridCont: {
        padding: theme.spacing(3),
    },
    ciPaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        width: '100%',
    },
    updatesPaperCont: {
        padding: theme.spacing(2),
    },
    updatesList: {
        boxShadow: 'inset 0 0 5px 1px ' + theme.palette.text.disabled,
        height: '400px',
        padding: 10,
        margin: '10px 0',
        overflow: 'auto',
    },
}));