import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import { Typography, CircularProgress } from "@material-ui/core";
import './accSet.css';
import { useSelector } from "react-redux";
import SetupStep1 from "./step1";
import SetupStep2 from "./step2";
import SetupStep3 from "./step3";
import SetupSte4 from "./step4";
import SetupStepFinal from "./stepFinal";
import { AccountsServiceClient } from '../../../protos/accounts_grpc_web_pb';
import { AccountSettingUpRequest } from '../../../protos/accounts_pb';
import { AccountsServerAddress } from '../../../protos/server';

export default function AccountSetup() {
    const [step, setStep] = useState(1);
    const [renStat, setRenStat] = useState("l");
    const [accData, setAccData] = useState({});
    const [accUpdate, setAccUpdate] = useState({
        ug: "f",
        stream: "",
        field: "",
        frontImg: null,
        backImg: null,
    })

    let userData = useSelector(state => state.userData);

    const render = () => {
        if (renStat === "s") {
            return renderStep()
        } else if (renStat === "l") {
            return <React.Fragment>
                <CircularProgress />
            </React.Fragment>
        } else if (renStat === "r") {
            return <Redirect to="/login" />
        } else if (renStat === "home") {
            return <Redirect to="/" />
        } else {
            return <React.Fragment>
                <Typography variant="body1" color="textSecondary">
                    <br />
                    <br />
                    Cannot Connect.
                </Typography>
            </React.Fragment>
        }
    }

    const renderStep = () => {
        switch (step) {
            case 1:
                return <SetupStep1 setStep={setStep} name={accData.firstname} />
            case 2:
                return <SetupStep2 setStep={setStep} accUpdate={accUpdate} setAccUpdate={setAccUpdate} />
            case 3:
                return <SetupStep3 setStep={setStep} accData={accData} accUpdate={accUpdate} setAccUpdate={setAccUpdate} />
            case 4:
                return <SetupSte4 setStep={setStep} accData={accData} accUpdate={accUpdate} setAccUpdate={setAccUpdate} />
            case 5:
                return <SetupStepFinal accUpdate={accUpdate} accData={accData} />
            default:
                return <SetupStep1 />
        }
    }

    const fetchData = () => {
        const client = new AccountsServiceClient(AccountsServerAddress, null, null)
        const req = new AccountSettingUpRequest();
        req.setRequesttype("get");
        req.setUeref(userData.ueref)
        req.setFirstname(userData.firstName)

        client.accountSettingUp(req, {}, (err, response) => {
            if (err) {
                console.log(err)
                setRenStat("e");
            } else {
                const res = response.toObject()
                if (res.response === "Okay") {
                    setAccData(res);
                    setRenStat("s");
                } else {
                    setRenStat("e");
                }
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, []);

    return render()
}