import React from 'react'
import SigningIn from './signin';
import Sessions from './sessions';
import Activity from './activity';
import TabView from '../tabView'
// import { Tabs, Tab } from "@material-ui/core";
// import { TabPanel, a11yProps } from '../customComponents/tabPanel';
// import SwipeableViews from 'react-swipeable-views';
// import { securityStyles } from './securityStyles';

export default function Security() {

    return <TabView
        labels={["Signing In", "Sessions", "Activity"]}
        viewComponents={[<SigningIn />, <Sessions />, <Activity />]}
    />

    // return <React.Fragment>
    //     <Tabs
    //         value={tabIndex}
    //         onChange={handleTab}
    //         indicatorColor="primary"
    //         textColor="primary"
    //         variant="scrollable"
    //         scrollButtons="auto"
    //         aria-label="scrollable auto tabs example"
    //         className={classes.tabBar}
    //     >
    //         <Tab label="Signing In" {...a11yProps(0)} />
    //         <Tab label="Sessions" {...a11yProps(1)} />
    //         <Tab label="Activity" {...a11yProps(2)} />
    //     </Tabs>
    //     <SwipeableViews
    //         axis='x'
    //         index={tabIndex}
    //         onChangeIndex={handleTabIndex}
    //     // disableLazyLoading
    //     >
    //         <TabPanel value={tabIndex} index={0}>
    //             <SigningIn />
    //         </TabPanel>
    //         <TabPanel value={tabIndex} index={1}>
    //             <Sessions />
    //         </TabPanel>
    //         <TabPanel value={tabIndex} index={2}>
    //             <Activity />
    //         </TabPanel>
    //     </SwipeableViews>
    // </React.Fragment>
}