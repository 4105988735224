import React, { useState } from 'react';
import { Paper, TextField, Input, InputAdornment, IconButton, Typography, Button, CircularProgress, Grid, CardContent } from '@material-ui/core';
import { Visibility, VisibilityOff, DoneAll } from '@material-ui/icons';
import { securityStyles } from '../securityStyles';
import { useSelector } from "react-redux";
import { AuthServiceClient } from '../../../protos/auth_grpc_web_pb';
import { AuthServerAddress } from '../../../protos/server';
import { ChangeCredentialsRequest } from '../../../protos/auth_pb';
import GetMetadata from '../../customComponents/getMetadata';


export default function SigningIn() {
    const classes = securityStyles();
    const [saveBtnStat, setSaveBtnStat] = useState(0);
    const [passVis, setPassVis] = useState(false)
    const lastPasswordChange = useSelector(state => state.lastPasswordChange)
    const userData = useSelector(state => state.userData)
    const [values, setValues] = useState({
        username: "",
        oldPassword: "",
        newPassword: "",
        error: "",
    })
    const authCient = new AuthServiceClient(AuthServerAddress, null, null)
    const req = new ChangeCredentialsRequest();
    var md = GetMetadata()

    const changeValues = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value, error: "" })
        setSaveBtnStat(0)
    }

    const submitChange = () => {
        if (values.username.length > 5 && values.username.length < 31 && values.oldPassword.length > 7 && values.oldPassword.length < 31 && values.error.length < 1) {
            setSaveBtnStat(2)
            req.setUsername(values.username)
            req.setOldpassword(values.oldPassword)
            req.setPassword(values.newPassword)
            md.authorization = userData.token
            authCient.changeCredentials(req, md, (err, response) => {
                if (err) {
                    console.log(err)
                    setValues({ ...values, error: err.message })
                    setSaveBtnStat(-1)
                } else {
                    const res = response.toObject()
                    console.log(res)
                    setSaveBtnStat(1)
                }
            })
        }
    }

    const saveBtn = () => {
        if (saveBtnStat === 0) {
            return <Button
                color="primary"
                onClick={submitChange}
                disabled={values.username.length < 6 || values.username.length > 30 || values.oldPassword.length < 8 || values.oldPassword.length > 30 || values.error.length > 0}
            >
                Update
            </Button>
        } else if (saveBtnStat === 2) {
            return <CircularProgress size={30} />
        } else if (saveBtnStat === -2) {
            return <Button disabled>
                Error
            </Button>
        } else if (saveBtnStat === 1) {
            return <Button disabled>
                <DoneAll />
            </Button>
        }
    }

    const lastPasswordChangeElem = () => {
        if (lastPasswordChange === undefined || lastPasswordChange === null) {
            return ""
        } else if (lastPasswordChange.changeTime === undefined || lastPasswordChange.changeTime === null) {
            return ""
        }
        return <Typography color="textPrimary" align="left">
            Last password change was on {lastPasswordChange.changeTime} ({lastPasswordChange.changeInterval} ago)
        </Typography>
    }

    return <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={10} >
            <Paper className={classes.signinPaper}>
                <Typography variant="h6" align="left" color="textPrimary">
                    Security Credentials
                </Typography>
                <TextField
                    id="username"
                    label="Username *"
                    name="username"
                    value={values.username}
                    onChange={changeValues}
                    className={classes.signinInput}
                    // error={err.username.length > 0}
                    // helperText={err.username}
                    disabled={saveBtnStat === 2}
                />
                <br />
                <Input
                    id="old-password"
                    type={passVis ? 'text' : 'password'}
                    className={classes.signinInput}
                    placeholder="Current Password *"
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={changeValues}
                    // error={err.password.length > 0}
                    disabled={saveBtnStat === 2}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPassVis(!passVis)}
                            >
                                {passVis ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <br />
                <Input
                    id="new-password"
                    type={passVis ? 'text' : 'password'}
                    className={classes.signinInput}
                    placeholder="New Password (Blank to keep unchanged)"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={changeValues}
                    // error={err.newPassword.length > 0}
                    disabled={saveBtnStat === 2}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPassVis(!passVis)}
                            >
                                {passVis ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <br />
                <Typography variant="caption" color="secondary">
                    {/* {err.password} {err.newPassword} {err.other} */}
                    {values.error}
                    <br />
                    <br />
                </Typography>
                {saveBtn()}
                <br />
                <br />
                <Typography variant="caption" color="textSecondary">
                    To change <strong>Username</strong>, enter new username and current password.
                    <br />
                    <strong>Username</strong> must contain only alphabets and numbers
                    and should be at least 6 characters long.
                    <br />
                    To change <strong>Password</strong>, enter current username, current password and new password.
                    <br />
                    <strong>Password</strong> should be at least 8 characters long.
                </Typography>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={10} >
            <Paper className={classes.signinPaper}>
                <Typography variant="h6" align="left" color="textPrimary">
                    Security Advisor
                </Typography>
                <br />
                <CardContent>
                    {lastPasswordChangeElem()}
                </CardContent>
            </Paper>
        </Grid>
    </Grid>
}