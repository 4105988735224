// source: support.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.mazey.support.CreateTicketRequest', null, global);
goog.exportSymbol('proto.mazey.support.GeneralRequest', null, global);
goog.exportSymbol('proto.mazey.support.GeneralResponse', null, global);
goog.exportSymbol('proto.mazey.support.GetTicketDetailsResponse', null, global);
goog.exportSymbol('proto.mazey.support.GetTicketsResponse', null, global);
goog.exportSymbol('proto.mazey.support.TicketDetails', null, global);
goog.exportSymbol('proto.mazey.support.TicketMessage', null, global);
goog.exportSymbol('proto.mazey.support.UpdateTicketRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.support.GeneralRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.support.GeneralRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.support.GeneralRequest.displayName = 'proto.mazey.support.GeneralRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.support.GeneralResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.support.GeneralResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.support.GeneralResponse.displayName = 'proto.mazey.support.GeneralResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.support.CreateTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.support.CreateTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.support.CreateTicketRequest.displayName = 'proto.mazey.support.CreateTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.support.TicketDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.support.TicketDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.support.TicketDetails.displayName = 'proto.mazey.support.TicketDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.support.GetTicketsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.support.GetTicketsResponse.repeatedFields_, null);
};
goog.inherits(proto.mazey.support.GetTicketsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.support.GetTicketsResponse.displayName = 'proto.mazey.support.GetTicketsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.support.TicketMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.support.TicketMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.support.TicketMessage.displayName = 'proto.mazey.support.TicketMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.support.GetTicketDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.support.GetTicketDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.mazey.support.GetTicketDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.support.GetTicketDetailsResponse.displayName = 'proto.mazey.support.GetTicketDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.support.UpdateTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.support.UpdateTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.support.UpdateTicketRequest.displayName = 'proto.mazey.support.UpdateTicketRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.support.GeneralRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.support.GeneralRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.support.GeneralRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.GeneralRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.support.GeneralRequest}
 */
proto.mazey.support.GeneralRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.support.GeneralRequest;
  return proto.mazey.support.GeneralRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.support.GeneralRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.support.GeneralRequest}
 */
proto.mazey.support.GeneralRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.support.GeneralRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.support.GeneralRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.support.GeneralRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.GeneralRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mazey.support.GeneralRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.GeneralRequest} returns this
 */
proto.mazey.support.GeneralRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.support.GeneralResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.support.GeneralResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.support.GeneralResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.GeneralResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.support.GeneralResponse}
 */
proto.mazey.support.GeneralResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.support.GeneralResponse;
  return proto.mazey.support.GeneralResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.support.GeneralResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.support.GeneralResponse}
 */
proto.mazey.support.GeneralResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.support.GeneralResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.support.GeneralResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.support.GeneralResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.GeneralResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mazey.support.GeneralResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.GeneralResponse} returns this
 */
proto.mazey.support.GeneralResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.support.CreateTicketRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.support.CreateTicketRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.support.CreateTicketRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.CreateTicketRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tickettype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.support.CreateTicketRequest}
 */
proto.mazey.support.CreateTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.support.CreateTicketRequest;
  return proto.mazey.support.CreateTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.support.CreateTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.support.CreateTicketRequest}
 */
proto.mazey.support.CreateTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTickettype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.support.CreateTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.support.CreateTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.support.CreateTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.CreateTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTickettype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ticketType = 1;
 * @return {string}
 */
proto.mazey.support.CreateTicketRequest.prototype.getTickettype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.CreateTicketRequest} returns this
 */
proto.mazey.support.CreateTicketRequest.prototype.setTickettype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subject = 2;
 * @return {string}
 */
proto.mazey.support.CreateTicketRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.CreateTicketRequest} returns this
 */
proto.mazey.support.CreateTicketRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.mazey.support.CreateTicketRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.CreateTicketRequest} returns this
 */
proto.mazey.support.CreateTicketRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.support.TicketDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.support.TicketDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.support.TicketDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.TicketDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tickettype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creationtime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastupdatetime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastupdateby: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isagentassigned: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    agentid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    ticketstatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    hasuserseen: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    creator: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.support.TicketDetails}
 */
proto.mazey.support.TicketDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.support.TicketDetails;
  return proto.mazey.support.TicketDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.support.TicketDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.support.TicketDetails}
 */
proto.mazey.support.TicketDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTickettype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreationtime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatetime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdateby(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsagentassigned(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketstatus(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasuserseen(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.support.TicketDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.support.TicketDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.support.TicketDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.TicketDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTickettype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreationtime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastupdatetime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastupdateby();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsagentassigned();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTicketstatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHasuserseen();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string ticketId = 1;
 * @return {string}
 */
proto.mazey.support.TicketDetails.prototype.getTicketid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setTicketid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ticketType = 2;
 * @return {string}
 */
proto.mazey.support.TicketDetails.prototype.getTickettype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setTickettype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subject = 3;
 * @return {string}
 */
proto.mazey.support.TicketDetails.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string creationTime = 4;
 * @return {string}
 */
proto.mazey.support.TicketDetails.prototype.getCreationtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setCreationtime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastUpdateTime = 5;
 * @return {string}
 */
proto.mazey.support.TicketDetails.prototype.getLastupdatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setLastupdatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lastUpdateBy = 6;
 * @return {string}
 */
proto.mazey.support.TicketDetails.prototype.getLastupdateby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setLastupdateby = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool isAgentAssigned = 7;
 * @return {boolean}
 */
proto.mazey.support.TicketDetails.prototype.getIsagentassigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setIsagentassigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string agentId = 8;
 * @return {string}
 */
proto.mazey.support.TicketDetails.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ticketStatus = 9;
 * @return {string}
 */
proto.mazey.support.TicketDetails.prototype.getTicketstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setTicketstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool hasUserSeen = 10;
 * @return {boolean}
 */
proto.mazey.support.TicketDetails.prototype.getHasuserseen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setHasuserseen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string creator = 11;
 * @return {string}
 */
proto.mazey.support.TicketDetails.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketDetails} returns this
 */
proto.mazey.support.TicketDetails.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.support.GetTicketsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.support.GetTicketsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.support.GetTicketsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.support.GetTicketsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.GetTicketsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketsList: jspb.Message.toObjectList(msg.getTicketsList(),
    proto.mazey.support.TicketDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.support.GetTicketsResponse}
 */
proto.mazey.support.GetTicketsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.support.GetTicketsResponse;
  return proto.mazey.support.GetTicketsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.support.GetTicketsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.support.GetTicketsResponse}
 */
proto.mazey.support.GetTicketsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mazey.support.TicketDetails;
      reader.readMessage(value,proto.mazey.support.TicketDetails.deserializeBinaryFromReader);
      msg.addTickets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.support.GetTicketsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.support.GetTicketsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.support.GetTicketsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.GetTicketsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mazey.support.TicketDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TicketDetails tickets = 1;
 * @return {!Array<!proto.mazey.support.TicketDetails>}
 */
proto.mazey.support.GetTicketsResponse.prototype.getTicketsList = function() {
  return /** @type{!Array<!proto.mazey.support.TicketDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.support.TicketDetails, 1));
};


/**
 * @param {!Array<!proto.mazey.support.TicketDetails>} value
 * @return {!proto.mazey.support.GetTicketsResponse} returns this
*/
proto.mazey.support.GetTicketsResponse.prototype.setTicketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mazey.support.TicketDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.support.TicketDetails}
 */
proto.mazey.support.GetTicketsResponse.prototype.addTickets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mazey.support.TicketDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.support.GetTicketsResponse} returns this
 */
proto.mazey.support.GetTicketsResponse.prototype.clearTicketsList = function() {
  return this.setTicketsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.support.TicketMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.support.TicketMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.support.TicketMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.TicketMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageby: jspb.Message.getFieldWithDefault(msg, 2, ""),
    messagetext: jspb.Message.getFieldWithDefault(msg, 3, ""),
    messagetime: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.support.TicketMessage}
 */
proto.mazey.support.TicketMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.support.TicketMessage;
  return proto.mazey.support.TicketMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.support.TicketMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.support.TicketMessage}
 */
proto.mazey.support.TicketMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageby(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessagetext(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessagetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.support.TicketMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.support.TicketMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.support.TicketMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.TicketMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageby();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessagetext();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessagetime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string messageId = 1;
 * @return {string}
 */
proto.mazey.support.TicketMessage.prototype.getMessageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketMessage} returns this
 */
proto.mazey.support.TicketMessage.prototype.setMessageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string messageBy = 2;
 * @return {string}
 */
proto.mazey.support.TicketMessage.prototype.getMessageby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketMessage} returns this
 */
proto.mazey.support.TicketMessage.prototype.setMessageby = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string messageText = 3;
 * @return {string}
 */
proto.mazey.support.TicketMessage.prototype.getMessagetext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketMessage} returns this
 */
proto.mazey.support.TicketMessage.prototype.setMessagetext = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string messageTime = 4;
 * @return {string}
 */
proto.mazey.support.TicketMessage.prototype.getMessagetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.TicketMessage} returns this
 */
proto.mazey.support.TicketMessage.prototype.setMessagetime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.support.GetTicketDetailsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.support.GetTicketDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.support.GetTicketDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.support.GetTicketDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.GetTicketDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticket: (f = msg.getTicket()) && proto.mazey.support.TicketDetails.toObject(includeInstance, f),
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    proto.mazey.support.TicketMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.support.GetTicketDetailsResponse}
 */
proto.mazey.support.GetTicketDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.support.GetTicketDetailsResponse;
  return proto.mazey.support.GetTicketDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.support.GetTicketDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.support.GetTicketDetailsResponse}
 */
proto.mazey.support.GetTicketDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mazey.support.TicketDetails;
      reader.readMessage(value,proto.mazey.support.TicketDetails.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    case 2:
      var value = new proto.mazey.support.TicketMessage;
      reader.readMessage(value,proto.mazey.support.TicketMessage.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.support.GetTicketDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.support.GetTicketDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.support.GetTicketDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.GetTicketDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mazey.support.TicketDetails.serializeBinaryToWriter
    );
  }
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mazey.support.TicketMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional TicketDetails ticket = 1;
 * @return {?proto.mazey.support.TicketDetails}
 */
proto.mazey.support.GetTicketDetailsResponse.prototype.getTicket = function() {
  return /** @type{?proto.mazey.support.TicketDetails} */ (
    jspb.Message.getWrapperField(this, proto.mazey.support.TicketDetails, 1));
};


/**
 * @param {?proto.mazey.support.TicketDetails|undefined} value
 * @return {!proto.mazey.support.GetTicketDetailsResponse} returns this
*/
proto.mazey.support.GetTicketDetailsResponse.prototype.setTicket = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mazey.support.GetTicketDetailsResponse} returns this
 */
proto.mazey.support.GetTicketDetailsResponse.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mazey.support.GetTicketDetailsResponse.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TicketMessage messages = 2;
 * @return {!Array<!proto.mazey.support.TicketMessage>}
 */
proto.mazey.support.GetTicketDetailsResponse.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.mazey.support.TicketMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.support.TicketMessage, 2));
};


/**
 * @param {!Array<!proto.mazey.support.TicketMessage>} value
 * @return {!proto.mazey.support.GetTicketDetailsResponse} returns this
*/
proto.mazey.support.GetTicketDetailsResponse.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mazey.support.TicketMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.support.TicketMessage}
 */
proto.mazey.support.GetTicketDetailsResponse.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mazey.support.TicketMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.support.GetTicketDetailsResponse} returns this
 */
proto.mazey.support.GetTicketDetailsResponse.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.support.UpdateTicketRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.support.UpdateTicketRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.support.UpdateTicketRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.UpdateTicketRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.support.UpdateTicketRequest}
 */
proto.mazey.support.UpdateTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.support.UpdateTicketRequest;
  return proto.mazey.support.UpdateTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.support.UpdateTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.support.UpdateTicketRequest}
 */
proto.mazey.support.UpdateTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.support.UpdateTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.support.UpdateTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.support.UpdateTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.support.UpdateTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ticketId = 1;
 * @return {string}
 */
proto.mazey.support.UpdateTicketRequest.prototype.getTicketid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.UpdateTicketRequest} returns this
 */
proto.mazey.support.UpdateTicketRequest.prototype.setTicketid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.mazey.support.UpdateTicketRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.support.UpdateTicketRequest} returns this
 */
proto.mazey.support.UpdateTicketRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.mazey.support);
