// source: auth.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.mazey.auth.AppLoginDetailsResponse', null, global);
goog.exportSymbol('proto.mazey.auth.AuthorizeAppRequest', null, global);
goog.exportSymbol('proto.mazey.auth.AuthorizeAppResponse', null, global);
goog.exportSymbol('proto.mazey.auth.ChangeCredentialsRequest', null, global);
goog.exportSymbol('proto.mazey.auth.Field', null, global);
goog.exportSymbol('proto.mazey.auth.GeneralRequest', null, global);
goog.exportSymbol('proto.mazey.auth.GeneralResponse', null, global);
goog.exportSymbol('proto.mazey.auth.GetActivityRequest', null, global);
goog.exportSymbol('proto.mazey.auth.GetActivityResponse', null, global);
goog.exportSymbol('proto.mazey.auth.GetAppLoginsResponse', null, global);
goog.exportSymbol('proto.mazey.auth.GetSessionsResponse', null, global);
goog.exportSymbol('proto.mazey.auth.GetTokenRequest', null, global);
goog.exportSymbol('proto.mazey.auth.GetTokenResponse', null, global);
goog.exportSymbol('proto.mazey.auth.GetUserRequest', null, global);
goog.exportSymbol('proto.mazey.auth.GrantPermissionRequest', null, global);
goog.exportSymbol('proto.mazey.auth.GrantPermissionResponse', null, global);
goog.exportSymbol('proto.mazey.auth.Institution', null, global);
goog.exportSymbol('proto.mazey.auth.LoginRequest', null, global);
goog.exportSymbol('proto.mazey.auth.LoginResponse', null, global);
goog.exportSymbol('proto.mazey.auth.OneAccess', null, global);
goog.exportSymbol('proto.mazey.auth.Perms', null, global);
goog.exportSymbol('proto.mazey.auth.ResetPasswordRequest', null, global);
goog.exportSymbol('proto.mazey.auth.ResetPasswordResponse', null, global);
goog.exportSymbol('proto.mazey.auth.Session', null, global);
goog.exportSymbol('proto.mazey.auth.SignupRequest', null, global);
goog.exportSymbol('proto.mazey.auth.SignupResponse', null, global);
goog.exportSymbol('proto.mazey.auth.SignupStepRequest', null, global);
goog.exportSymbol('proto.mazey.auth.SignupStepResponse', null, global);
goog.exportSymbol('proto.mazey.auth.Stream', null, global);
goog.exportSymbol('proto.mazey.auth.UserActivityResponse', null, global);
goog.exportSymbol('proto.mazey.auth.VerifyEmailRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.LoginRequest.displayName = 'proto.mazey.auth.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.LoginResponse.displayName = 'proto.mazey.auth.LoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.SignupStepRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.SignupStepRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.SignupStepRequest.displayName = 'proto.mazey.auth.SignupStepRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.SignupStepResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.SignupStepResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.SignupStepResponse.displayName = 'proto.mazey.auth.SignupStepResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.SignupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.SignupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.SignupRequest.displayName = 'proto.mazey.auth.SignupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.SignupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.SignupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.SignupResponse.displayName = 'proto.mazey.auth.SignupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.ResetPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.ResetPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.ResetPasswordRequest.displayName = 'proto.mazey.auth.ResetPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.ResetPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.ResetPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.ResetPasswordResponse.displayName = 'proto.mazey.auth.ResetPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.VerifyEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.VerifyEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.VerifyEmailRequest.displayName = 'proto.mazey.auth.VerifyEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.ChangeCredentialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.ChangeCredentialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.ChangeCredentialsRequest.displayName = 'proto.mazey.auth.ChangeCredentialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GeneralRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.GeneralRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GeneralRequest.displayName = 'proto.mazey.auth.GeneralRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GetUserRequest.displayName = 'proto.mazey.auth.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.Institution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.Institution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.Institution.displayName = 'proto.mazey.auth.Institution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.Stream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.Stream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.Stream.displayName = 'proto.mazey.auth.Stream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.Field = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.Field.displayName = 'proto.mazey.auth.Field';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.AuthorizeAppRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.AuthorizeAppRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.AuthorizeAppRequest.displayName = 'proto.mazey.auth.AuthorizeAppRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.Perms = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.Perms, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.Perms.displayName = 'proto.mazey.auth.Perms';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.AuthorizeAppResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.auth.AuthorizeAppResponse.repeatedFields_, null);
};
goog.inherits(proto.mazey.auth.AuthorizeAppResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.AuthorizeAppResponse.displayName = 'proto.mazey.auth.AuthorizeAppResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GrantPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.auth.GrantPermissionRequest.repeatedFields_, null);
};
goog.inherits(proto.mazey.auth.GrantPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GrantPermissionRequest.displayName = 'proto.mazey.auth.GrantPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GrantPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.GrantPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GrantPermissionResponse.displayName = 'proto.mazey.auth.GrantPermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GetTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.GetTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GetTokenRequest.displayName = 'proto.mazey.auth.GetTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GetTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.GetTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GetTokenResponse.displayName = 'proto.mazey.auth.GetTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.UserActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.UserActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.UserActivityResponse.displayName = 'proto.mazey.auth.UserActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GetActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.GetActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GetActivityRequest.displayName = 'proto.mazey.auth.GetActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GetActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.auth.GetActivityResponse.repeatedFields_, null);
};
goog.inherits(proto.mazey.auth.GetActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GetActivityResponse.displayName = 'proto.mazey.auth.GetActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.Session = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.Session, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.Session.displayName = 'proto.mazey.auth.Session';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GetSessionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.auth.GetSessionsResponse.repeatedFields_, null);
};
goog.inherits(proto.mazey.auth.GetSessionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GetSessionsResponse.displayName = 'proto.mazey.auth.GetSessionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.OneAccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.auth.OneAccess.repeatedFields_, null);
};
goog.inherits(proto.mazey.auth.OneAccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.OneAccess.displayName = 'proto.mazey.auth.OneAccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GetAppLoginsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.auth.GetAppLoginsResponse.repeatedFields_, null);
};
goog.inherits(proto.mazey.auth.GetAppLoginsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GetAppLoginsResponse.displayName = 'proto.mazey.auth.GetAppLoginsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.AppLoginDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mazey.auth.AppLoginDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.mazey.auth.AppLoginDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.AppLoginDetailsResponse.displayName = 'proto.mazey.auth.AppLoginDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mazey.auth.GeneralResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mazey.auth.GeneralResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mazey.auth.GeneralResponse.displayName = 'proto.mazey.auth.GeneralResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.LoginRequest}
 */
proto.mazey.auth.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.LoginRequest;
  return proto.mazey.auth.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.LoginRequest}
 */
proto.mazey.auth.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.mazey.auth.LoginRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.LoginRequest} returns this
 */
proto.mazey.auth.LoginRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.mazey.auth.LoginRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.LoginRequest} returns this
 */
proto.mazey.auth.LoginRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: jspb.Message.getFieldWithDefault(msg, 1, ""),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userstatus: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userreference: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userrole: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.LoginResponse}
 */
proto.mazey.auth.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.LoginResponse;
  return proto.mazey.auth.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.LoginResponse}
 */
proto.mazey.auth.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserstatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserreference(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserrole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserstatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserreference();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserrole();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string error = 1;
 * @return {string}
 */
proto.mazey.auth.LoginResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.LoginResponse} returns this
 */
proto.mazey.auth.LoginResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.mazey.auth.LoginResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.LoginResponse} returns this
 */
proto.mazey.auth.LoginResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.mazey.auth.LoginResponse.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.LoginResponse} returns this
 */
proto.mazey.auth.LoginResponse.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.mazey.auth.LoginResponse.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.LoginResponse} returns this
 */
proto.mazey.auth.LoginResponse.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string userStatus = 5;
 * @return {string}
 */
proto.mazey.auth.LoginResponse.prototype.getUserstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.LoginResponse} returns this
 */
proto.mazey.auth.LoginResponse.prototype.setUserstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string userReference = 6;
 * @return {string}
 */
proto.mazey.auth.LoginResponse.prototype.getUserreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.LoginResponse} returns this
 */
proto.mazey.auth.LoginResponse.prototype.setUserreference = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string userRole = 7;
 * @return {string}
 */
proto.mazey.auth.LoginResponse.prototype.getUserrole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.LoginResponse} returns this
 */
proto.mazey.auth.LoginResponse.prototype.setUserrole = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.SignupStepRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.SignupStepRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.SignupStepRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.SignupStepRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    step: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userrole: jspb.Message.getFieldWithDefault(msg, 6, ""),
    username: jspb.Message.getFieldWithDefault(msg, 7, ""),
    password: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.SignupStepRequest}
 */
proto.mazey.auth.SignupStepRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.SignupStepRequest;
  return proto.mazey.auth.SignupStepRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.SignupStepRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.SignupStepRequest}
 */
proto.mazey.auth.SignupStepRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStep(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserrole(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.SignupStepRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.SignupStepRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.SignupStepRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.SignupStepRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStep();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserrole();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 step = 1;
 * @return {number}
 */
proto.mazey.auth.SignupStepRequest.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mazey.auth.SignupStepRequest} returns this
 */
proto.mazey.auth.SignupStepRequest.prototype.setStep = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.mazey.auth.SignupStepRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupStepRequest} returns this
 */
proto.mazey.auth.SignupStepRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.mazey.auth.SignupStepRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupStepRequest} returns this
 */
proto.mazey.auth.SignupStepRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.mazey.auth.SignupStepRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupStepRequest} returns this
 */
proto.mazey.auth.SignupStepRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string userID = 5;
 * @return {string}
 */
proto.mazey.auth.SignupStepRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupStepRequest} returns this
 */
proto.mazey.auth.SignupStepRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string userRole = 6;
 * @return {string}
 */
proto.mazey.auth.SignupStepRequest.prototype.getUserrole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupStepRequest} returns this
 */
proto.mazey.auth.SignupStepRequest.prototype.setUserrole = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string username = 7;
 * @return {string}
 */
proto.mazey.auth.SignupStepRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupStepRequest} returns this
 */
proto.mazey.auth.SignupStepRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string password = 8;
 * @return {string}
 */
proto.mazey.auth.SignupStepRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupStepRequest} returns this
 */
proto.mazey.auth.SignupStepRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.SignupStepResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.SignupStepResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.SignupStepResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.SignupStepResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    response: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.SignupStepResponse}
 */
proto.mazey.auth.SignupStepResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.SignupStepResponse;
  return proto.mazey.auth.SignupStepResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.SignupStepResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.SignupStepResponse}
 */
proto.mazey.auth.SignupStepResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.SignupStepResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.SignupStepResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.SignupStepResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.SignupStepResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string response = 1;
 * @return {string}
 */
proto.mazey.auth.SignupStepResponse.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupStepResponse} returns this
 */
proto.mazey.auth.SignupStepResponse.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.SignupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.SignupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.SignupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.SignupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ieref: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userrole: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    username: jspb.Message.getFieldWithDefault(msg, 7, ""),
    password: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.SignupRequest}
 */
proto.mazey.auth.SignupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.SignupRequest;
  return proto.mazey.auth.SignupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.SignupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.SignupRequest}
 */
proto.mazey.auth.SignupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIeref(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserrole(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.SignupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.SignupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.SignupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.SignupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIeref();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserrole();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string Ieref = 1;
 * @return {string}
 */
proto.mazey.auth.SignupRequest.prototype.getIeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupRequest} returns this
 */
proto.mazey.auth.SignupRequest.prototype.setIeref = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.mazey.auth.SignupRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupRequest} returns this
 */
proto.mazey.auth.SignupRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.mazey.auth.SignupRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupRequest} returns this
 */
proto.mazey.auth.SignupRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.mazey.auth.SignupRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupRequest} returns this
 */
proto.mazey.auth.SignupRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string userRole = 5;
 * @return {string}
 */
proto.mazey.auth.SignupRequest.prototype.getUserrole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupRequest} returns this
 */
proto.mazey.auth.SignupRequest.prototype.setUserrole = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string userID = 6;
 * @return {string}
 */
proto.mazey.auth.SignupRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupRequest} returns this
 */
proto.mazey.auth.SignupRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string username = 7;
 * @return {string}
 */
proto.mazey.auth.SignupRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupRequest} returns this
 */
proto.mazey.auth.SignupRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string password = 8;
 * @return {string}
 */
proto.mazey.auth.SignupRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupRequest} returns this
 */
proto.mazey.auth.SignupRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.SignupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.SignupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.SignupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.SignupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    response: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.SignupResponse}
 */
proto.mazey.auth.SignupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.SignupResponse;
  return proto.mazey.auth.SignupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.SignupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.SignupResponse}
 */
proto.mazey.auth.SignupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.SignupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.SignupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.SignupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.SignupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string response = 1;
 * @return {string}
 */
proto.mazey.auth.SignupResponse.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.SignupResponse} returns this
 */
proto.mazey.auth.SignupResponse.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.ResetPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.ResetPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.ResetPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.ResetPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requesttype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    token: jspb.Message.getFieldWithDefault(msg, 3, ""),
    qid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.ResetPasswordRequest}
 */
proto.mazey.auth.ResetPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.ResetPasswordRequest;
  return proto.mazey.auth.ResetPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.ResetPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.ResetPasswordRequest}
 */
proto.mazey.auth.ResetPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesttype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.ResetPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.ResetPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.ResetPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.ResetPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequesttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string requestType = 1;
 * @return {string}
 */
proto.mazey.auth.ResetPasswordRequest.prototype.getRequesttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ResetPasswordRequest} returns this
 */
proto.mazey.auth.ResetPasswordRequest.prototype.setRequesttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.mazey.auth.ResetPasswordRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ResetPasswordRequest} returns this
 */
proto.mazey.auth.ResetPasswordRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.mazey.auth.ResetPasswordRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ResetPasswordRequest} returns this
 */
proto.mazey.auth.ResetPasswordRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string qID = 4;
 * @return {string}
 */
proto.mazey.auth.ResetPasswordRequest.prototype.getQid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ResetPasswordRequest} returns this
 */
proto.mazey.auth.ResetPasswordRequest.prototype.setQid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.mazey.auth.ResetPasswordRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ResetPasswordRequest} returns this
 */
proto.mazey.auth.ResetPasswordRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.ResetPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.ResetPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.ResetPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.ResetPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    response: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.ResetPasswordResponse}
 */
proto.mazey.auth.ResetPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.ResetPasswordResponse;
  return proto.mazey.auth.ResetPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.ResetPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.ResetPasswordResponse}
 */
proto.mazey.auth.ResetPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.ResetPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.ResetPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.ResetPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.ResetPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string response = 1;
 * @return {string}
 */
proto.mazey.auth.ResetPasswordResponse.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ResetPasswordResponse} returns this
 */
proto.mazey.auth.ResetPasswordResponse.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.VerifyEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.VerifyEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.VerifyEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.VerifyEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ueref: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.VerifyEmailRequest}
 */
proto.mazey.auth.VerifyEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.VerifyEmailRequest;
  return proto.mazey.auth.VerifyEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.VerifyEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.VerifyEmailRequest}
 */
proto.mazey.auth.VerifyEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUeref(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.VerifyEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.VerifyEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.VerifyEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.VerifyEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUeref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.mazey.auth.VerifyEmailRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.VerifyEmailRequest} returns this
 */
proto.mazey.auth.VerifyEmailRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ueref = 2;
 * @return {string}
 */
proto.mazey.auth.VerifyEmailRequest.prototype.getUeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.VerifyEmailRequest} returns this
 */
proto.mazey.auth.VerifyEmailRequest.prototype.setUeref = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.ChangeCredentialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.ChangeCredentialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.ChangeCredentialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    oldpassword: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.ChangeCredentialsRequest}
 */
proto.mazey.auth.ChangeCredentialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.ChangeCredentialsRequest;
  return proto.mazey.auth.ChangeCredentialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.ChangeCredentialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.ChangeCredentialsRequest}
 */
proto.mazey.auth.ChangeCredentialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldpassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.ChangeCredentialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.ChangeCredentialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.ChangeCredentialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOldpassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string user = 1;
 * @return {string}
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ChangeCredentialsRequest} returns this
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ChangeCredentialsRequest} returns this
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ChangeCredentialsRequest} returns this
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string oldPassword = 4;
 * @return {string}
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.getOldpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.ChangeCredentialsRequest} returns this
 */
proto.mazey.auth.ChangeCredentialsRequest.prototype.setOldpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GeneralRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GeneralRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GeneralRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GeneralRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GeneralRequest}
 */
proto.mazey.auth.GeneralRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GeneralRequest;
  return proto.mazey.auth.GeneralRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GeneralRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GeneralRequest}
 */
proto.mazey.auth.GeneralRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GeneralRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GeneralRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GeneralRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GeneralRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mazey.auth.GeneralRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GeneralRequest} returns this
 */
proto.mazey.auth.GeneralRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GetUserRequest}
 */
proto.mazey.auth.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GetUserRequest;
  return proto.mazey.auth.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GetUserRequest}
 */
proto.mazey.auth.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user = 1;
 * @return {string}
 */
proto.mazey.auth.GetUserRequest.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetUserRequest} returns this
 */
proto.mazey.auth.GetUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.Institution.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.Institution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.Institution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Institution.toObject = function(includeInstance, msg) {
  var f, obj = {
    ieref: jspb.Message.getFieldWithDefault(msg, 1, ""),
    instiname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.Institution}
 */
proto.mazey.auth.Institution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.Institution;
  return proto.mazey.auth.Institution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.Institution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.Institution}
 */
proto.mazey.auth.Institution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIeref(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstiname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.Institution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.Institution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.Institution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Institution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIeref();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstiname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ieref = 1;
 * @return {string}
 */
proto.mazey.auth.Institution.prototype.getIeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Institution} returns this
 */
proto.mazey.auth.Institution.prototype.setIeref = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string instiName = 2;
 * @return {string}
 */
proto.mazey.auth.Institution.prototype.getInstiname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Institution} returns this
 */
proto.mazey.auth.Institution.prototype.setInstiname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.Stream.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.Stream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.Stream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Stream.toObject = function(includeInstance, msg) {
  var f, obj = {
    seref: jspb.Message.getFieldWithDefault(msg, 1, ""),
    streamname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.Stream}
 */
proto.mazey.auth.Stream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.Stream;
  return proto.mazey.auth.Stream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.Stream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.Stream}
 */
proto.mazey.auth.Stream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeref(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.Stream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.Stream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.Stream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Stream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeref();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreamname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string seref = 1;
 * @return {string}
 */
proto.mazey.auth.Stream.prototype.getSeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Stream} returns this
 */
proto.mazey.auth.Stream.prototype.setSeref = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string streamName = 2;
 * @return {string}
 */
proto.mazey.auth.Stream.prototype.getStreamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Stream} returns this
 */
proto.mazey.auth.Stream.prototype.setStreamname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.Field.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.Field.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.Field} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Field.toObject = function(includeInstance, msg) {
  var f, obj = {
    beref: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.Field}
 */
proto.mazey.auth.Field.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.Field;
  return proto.mazey.auth.Field.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.Field}
 */
proto.mazey.auth.Field.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeref(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.Field.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Field.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeref();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string beref = 1;
 * @return {string}
 */
proto.mazey.auth.Field.prototype.getBeref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Field} returns this
 */
proto.mazey.auth.Field.prototype.setBeref = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fieldName = 2;
 * @return {string}
 */
proto.mazey.auth.Field.prototype.getFieldname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Field} returns this
 */
proto.mazey.auth.Field.prototype.setFieldname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.AuthorizeAppRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.AuthorizeAppRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.AuthorizeAppRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    responsetype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    redirecturi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    codechallenge: jspb.Message.getFieldWithDefault(msg, 5, ""),
    codechallengemethod: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.AuthorizeAppRequest}
 */
proto.mazey.auth.AuthorizeAppRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.AuthorizeAppRequest;
  return proto.mazey.auth.AuthorizeAppRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.AuthorizeAppRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.AuthorizeAppRequest}
 */
proto.mazey.auth.AuthorizeAppRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsetype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirecturi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodechallenge(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodechallengemethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.AuthorizeAppRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.AuthorizeAppRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.AuthorizeAppRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResponsetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRedirecturi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCodechallenge();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCodechallengemethod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string clientID = 1;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppRequest} returns this
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppRequest} returns this
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string responseType = 3;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.getResponsetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppRequest} returns this
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.setResponsetype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string redirectUri = 4;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.getRedirecturi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppRequest} returns this
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.setRedirecturi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string codeChallenge = 5;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.getCodechallenge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppRequest} returns this
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.setCodechallenge = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string codeChallengeMethod = 6;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.getCodechallengemethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppRequest} returns this
 */
proto.mazey.auth.AuthorizeAppRequest.prototype.setCodechallengemethod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.Perms.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.Perms.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.Perms} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Perms.toObject = function(includeInstance, msg) {
  var f, obj = {
    permid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    permname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    permdesc: jspb.Message.getFieldWithDefault(msg, 3, ""),
    checked: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.Perms}
 */
proto.mazey.auth.Perms.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.Perms;
  return proto.mazey.auth.Perms.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.Perms} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.Perms}
 */
proto.mazey.auth.Perms.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermdesc(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChecked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.Perms.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.Perms.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.Perms} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Perms.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPermname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPermdesc();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChecked();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string permID = 1;
 * @return {string}
 */
proto.mazey.auth.Perms.prototype.getPermid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Perms} returns this
 */
proto.mazey.auth.Perms.prototype.setPermid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string permName = 2;
 * @return {string}
 */
proto.mazey.auth.Perms.prototype.getPermname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Perms} returns this
 */
proto.mazey.auth.Perms.prototype.setPermname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string permDesc = 3;
 * @return {string}
 */
proto.mazey.auth.Perms.prototype.getPermdesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Perms} returns this
 */
proto.mazey.auth.Perms.prototype.setPermdesc = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool checked = 4;
 * @return {boolean}
 */
proto.mazey.auth.Perms.prototype.getChecked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.Perms} returns this
 */
proto.mazey.auth.Perms.prototype.setChecked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.auth.AuthorizeAppResponse.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.AuthorizeAppResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.AuthorizeAppResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.AuthorizeAppResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    haveerror: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errordescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    appname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    companyname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    applogo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    myverified: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    mytrusted: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    myowned: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    permsList: jspb.Message.toObjectList(msg.getPermsList(),
    proto.mazey.auth.Perms.toObject, includeInstance),
    privacypolicy: jspb.Message.getFieldWithDefault(msg, 13, ""),
    terms: jspb.Message.getFieldWithDefault(msg, 14, ""),
    authcode: jspb.Message.getFieldWithDefault(msg, 15, ""),
    authtoken: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.AuthorizeAppResponse}
 */
proto.mazey.auth.AuthorizeAppResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.AuthorizeAppResponse;
  return proto.mazey.auth.AuthorizeAppResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.AuthorizeAppResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.AuthorizeAppResponse}
 */
proto.mazey.auth.AuthorizeAppResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHaveerror(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrordescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplogo(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMyverified(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMytrusted(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMyowned(value);
      break;
    case 12:
      var value = new proto.mazey.auth.Perms;
      reader.readMessage(value,proto.mazey.auth.Perms.deserializeBinaryFromReader);
      msg.addPerms(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivacypolicy(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerms(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthcode(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.AuthorizeAppResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.AuthorizeAppResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.AuthorizeAppResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHaveerror();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrordescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAppname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getApplogo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMyverified();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMytrusted();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getMyowned();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPermsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.mazey.auth.Perms.serializeBinaryToWriter
    );
  }
  f = message.getPrivacypolicy();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTerms();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAuthcode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAuthtoken();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional bool haveError = 1;
 * @return {boolean}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getHaveerror = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setHaveerror = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorDescription = 3;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getErrordescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setErrordescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string appName = 6;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getAppname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setAppname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string companyName = 7;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string appLogo = 8;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getApplogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setApplogo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool myVerified = 9;
 * @return {boolean}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getMyverified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setMyverified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool myTrusted = 10;
 * @return {boolean}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getMytrusted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setMytrusted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool myOwned = 11;
 * @return {boolean}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getMyowned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setMyowned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated Perms perms = 12;
 * @return {!Array<!proto.mazey.auth.Perms>}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getPermsList = function() {
  return /** @type{!Array<!proto.mazey.auth.Perms>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.auth.Perms, 12));
};


/**
 * @param {!Array<!proto.mazey.auth.Perms>} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
*/
proto.mazey.auth.AuthorizeAppResponse.prototype.setPermsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.mazey.auth.Perms=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.auth.Perms}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.addPerms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.mazey.auth.Perms, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.clearPermsList = function() {
  return this.setPermsList([]);
};


/**
 * optional string privacyPolicy = 13;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getPrivacypolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setPrivacypolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string terms = 14;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getTerms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setTerms = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string authCode = 15;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getAuthcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setAuthcode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string authToken = 16;
 * @return {string}
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.getAuthtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AuthorizeAppResponse} returns this
 */
proto.mazey.auth.AuthorizeAppResponse.prototype.setAuthtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.auth.GrantPermissionRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GrantPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GrantPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GrantPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    responsetype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    redirecturi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    codechallenge: jspb.Message.getFieldWithDefault(msg, 5, ""),
    codechallengemethod: jspb.Message.getFieldWithDefault(msg, 6, ""),
    permsList: jspb.Message.toObjectList(msg.getPermsList(),
    proto.mazey.auth.Perms.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GrantPermissionRequest}
 */
proto.mazey.auth.GrantPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GrantPermissionRequest;
  return proto.mazey.auth.GrantPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GrantPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GrantPermissionRequest}
 */
proto.mazey.auth.GrantPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsetype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirecturi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodechallenge(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodechallengemethod(value);
      break;
    case 7:
      var value = new proto.mazey.auth.Perms;
      reader.readMessage(value,proto.mazey.auth.Perms.deserializeBinaryFromReader);
      msg.addPerms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GrantPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GrantPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GrantPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResponsetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRedirecturi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCodechallenge();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCodechallengemethod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPermsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.mazey.auth.Perms.serializeBinaryToWriter
    );
  }
};


/**
 * optional string clientID = 1;
 * @return {string}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GrantPermissionRequest} returns this
 */
proto.mazey.auth.GrantPermissionRequest.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GrantPermissionRequest} returns this
 */
proto.mazey.auth.GrantPermissionRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string responseType = 3;
 * @return {string}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.getResponsetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GrantPermissionRequest} returns this
 */
proto.mazey.auth.GrantPermissionRequest.prototype.setResponsetype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string redirectUri = 4;
 * @return {string}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.getRedirecturi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GrantPermissionRequest} returns this
 */
proto.mazey.auth.GrantPermissionRequest.prototype.setRedirecturi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string codeChallenge = 5;
 * @return {string}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.getCodechallenge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GrantPermissionRequest} returns this
 */
proto.mazey.auth.GrantPermissionRequest.prototype.setCodechallenge = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string codeChallengeMethod = 6;
 * @return {string}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.getCodechallengemethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GrantPermissionRequest} returns this
 */
proto.mazey.auth.GrantPermissionRequest.prototype.setCodechallengemethod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Perms perms = 7;
 * @return {!Array<!proto.mazey.auth.Perms>}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.getPermsList = function() {
  return /** @type{!Array<!proto.mazey.auth.Perms>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.auth.Perms, 7));
};


/**
 * @param {!Array<!proto.mazey.auth.Perms>} value
 * @return {!proto.mazey.auth.GrantPermissionRequest} returns this
*/
proto.mazey.auth.GrantPermissionRequest.prototype.setPermsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.mazey.auth.Perms=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.auth.Perms}
 */
proto.mazey.auth.GrantPermissionRequest.prototype.addPerms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.mazey.auth.Perms, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.auth.GrantPermissionRequest} returns this
 */
proto.mazey.auth.GrantPermissionRequest.prototype.clearPermsList = function() {
  return this.setPermsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GrantPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GrantPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GrantPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GrantPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authtoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GrantPermissionResponse}
 */
proto.mazey.auth.GrantPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GrantPermissionResponse;
  return proto.mazey.auth.GrantPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GrantPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GrantPermissionResponse}
 */
proto.mazey.auth.GrantPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthtoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GrantPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GrantPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GrantPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GrantPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthtoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string authCode = 1;
 * @return {string}
 */
proto.mazey.auth.GrantPermissionResponse.prototype.getAuthcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GrantPermissionResponse} returns this
 */
proto.mazey.auth.GrantPermissionResponse.prototype.setAuthcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authToken = 2;
 * @return {string}
 */
proto.mazey.auth.GrantPermissionResponse.prototype.getAuthtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GrantPermissionResponse} returns this
 */
proto.mazey.auth.GrantPermissionResponse.prototype.setAuthtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.mazey.auth.GrantPermissionResponse.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GrantPermissionResponse} returns this
 */
proto.mazey.auth.GrantPermissionResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GetTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GetTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GetTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authtoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    requesttype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    codeverifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    jti: jspb.Message.getFieldWithDefault(msg, 6, ""),
    refreshtoken: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GetTokenRequest}
 */
proto.mazey.auth.GetTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GetTokenRequest;
  return proto.mazey.auth.GetTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GetTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GetTokenRequest}
 */
proto.mazey.auth.GetTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthtoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesttype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeverifier(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJti(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GetTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GetTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GetTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthtoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequesttype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCodeverifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJti();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRefreshtoken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string authCode = 1;
 * @return {string}
 */
proto.mazey.auth.GetTokenRequest.prototype.getAuthcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenRequest} returns this
 */
proto.mazey.auth.GetTokenRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authToken = 2;
 * @return {string}
 */
proto.mazey.auth.GetTokenRequest.prototype.getAuthtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenRequest} returns this
 */
proto.mazey.auth.GetTokenRequest.prototype.setAuthtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.mazey.auth.GetTokenRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenRequest} returns this
 */
proto.mazey.auth.GetTokenRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string requestType = 4;
 * @return {string}
 */
proto.mazey.auth.GetTokenRequest.prototype.getRequesttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenRequest} returns this
 */
proto.mazey.auth.GetTokenRequest.prototype.setRequesttype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string codeVerifier = 5;
 * @return {string}
 */
proto.mazey.auth.GetTokenRequest.prototype.getCodeverifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenRequest} returns this
 */
proto.mazey.auth.GetTokenRequest.prototype.setCodeverifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string jti = 6;
 * @return {string}
 */
proto.mazey.auth.GetTokenRequest.prototype.getJti = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenRequest} returns this
 */
proto.mazey.auth.GetTokenRequest.prototype.setJti = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string refreshToken = 7;
 * @return {string}
 */
proto.mazey.auth.GetTokenRequest.prototype.getRefreshtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenRequest} returns this
 */
proto.mazey.auth.GetTokenRequest.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GetTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GetTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GetTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    jti: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refreshtoken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    validity: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userstatus: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userreference: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userrole: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GetTokenResponse}
 */
proto.mazey.auth.GetTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GetTokenResponse;
  return proto.mazey.auth.GetTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GetTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GetTokenResponse}
 */
proto.mazey.auth.GetTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setJti(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshtoken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserstatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserreference(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserrole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GetTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GetTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GetTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJti();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRefreshtoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValidity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserstatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserreference();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserrole();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.mazey.auth.GetTokenResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenResponse} returns this
 */
proto.mazey.auth.GetTokenResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string jti = 2;
 * @return {string}
 */
proto.mazey.auth.GetTokenResponse.prototype.getJti = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenResponse} returns this
 */
proto.mazey.auth.GetTokenResponse.prototype.setJti = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string refreshToken = 3;
 * @return {string}
 */
proto.mazey.auth.GetTokenResponse.prototype.getRefreshtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenResponse} returns this
 */
proto.mazey.auth.GetTokenResponse.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string validity = 4;
 * @return {string}
 */
proto.mazey.auth.GetTokenResponse.prototype.getValidity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenResponse} returns this
 */
proto.mazey.auth.GetTokenResponse.prototype.setValidity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string firstName = 5;
 * @return {string}
 */
proto.mazey.auth.GetTokenResponse.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenResponse} returns this
 */
proto.mazey.auth.GetTokenResponse.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lastName = 6;
 * @return {string}
 */
proto.mazey.auth.GetTokenResponse.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenResponse} returns this
 */
proto.mazey.auth.GetTokenResponse.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string userStatus = 7;
 * @return {string}
 */
proto.mazey.auth.GetTokenResponse.prototype.getUserstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenResponse} returns this
 */
proto.mazey.auth.GetTokenResponse.prototype.setUserstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string userReference = 8;
 * @return {string}
 */
proto.mazey.auth.GetTokenResponse.prototype.getUserreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenResponse} returns this
 */
proto.mazey.auth.GetTokenResponse.prototype.setUserreference = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string userRole = 9;
 * @return {string}
 */
proto.mazey.auth.GetTokenResponse.prototype.getUserrole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetTokenResponse} returns this
 */
proto.mazey.auth.GetTokenResponse.prototype.setUserrole = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.UserActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.UserActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.UserActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.UserActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activitytype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    primarydata: jspb.Message.getFieldWithDefault(msg, 3, ""),
    secondarydata: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tertiarydata: jspb.Message.getFieldWithDefault(msg, 5, ""),
    device: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ipaddress: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.UserActivityResponse}
 */
proto.mazey.auth.UserActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.UserActivityResponse;
  return proto.mazey.auth.UserActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.UserActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.UserActivityResponse}
 */
proto.mazey.auth.UserActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivitytype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimarydata(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondarydata(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTertiarydata(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpaddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.UserActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.UserActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.UserActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.UserActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivitytype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrimarydata();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecondarydata();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTertiarydata();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDevice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIpaddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string time = 1;
 * @return {string}
 */
proto.mazey.auth.UserActivityResponse.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.UserActivityResponse} returns this
 */
proto.mazey.auth.UserActivityResponse.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activityType = 2;
 * @return {string}
 */
proto.mazey.auth.UserActivityResponse.prototype.getActivitytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.UserActivityResponse} returns this
 */
proto.mazey.auth.UserActivityResponse.prototype.setActivitytype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string primaryData = 3;
 * @return {string}
 */
proto.mazey.auth.UserActivityResponse.prototype.getPrimarydata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.UserActivityResponse} returns this
 */
proto.mazey.auth.UserActivityResponse.prototype.setPrimarydata = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string secondaryData = 4;
 * @return {string}
 */
proto.mazey.auth.UserActivityResponse.prototype.getSecondarydata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.UserActivityResponse} returns this
 */
proto.mazey.auth.UserActivityResponse.prototype.setSecondarydata = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tertiaryData = 5;
 * @return {string}
 */
proto.mazey.auth.UserActivityResponse.prototype.getTertiarydata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.UserActivityResponse} returns this
 */
proto.mazey.auth.UserActivityResponse.prototype.setTertiarydata = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string device = 6;
 * @return {string}
 */
proto.mazey.auth.UserActivityResponse.prototype.getDevice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.UserActivityResponse} returns this
 */
proto.mazey.auth.UserActivityResponse.prototype.setDevice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ipAddress = 7;
 * @return {string}
 */
proto.mazey.auth.UserActivityResponse.prototype.getIpaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.UserActivityResponse} returns this
 */
proto.mazey.auth.UserActivityResponse.prototype.setIpaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GetActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GetActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GetActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    typ: jspb.Message.getFieldWithDefault(msg, 2, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GetActivityRequest}
 */
proto.mazey.auth.GetActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GetActivityRequest;
  return proto.mazey.auth.GetActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GetActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GetActivityRequest}
 */
proto.mazey.auth.GetActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTyp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GetActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GetActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GetActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTyp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mazey.auth.GetActivityRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetActivityRequest} returns this
 */
proto.mazey.auth.GetActivityRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string typ = 2;
 * @return {string}
 */
proto.mazey.auth.GetActivityRequest.prototype.getTyp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GetActivityRequest} returns this
 */
proto.mazey.auth.GetActivityRequest.prototype.setTyp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.mazey.auth.GetActivityRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mazey.auth.GetActivityRequest} returns this
 */
proto.mazey.auth.GetActivityRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.auth.GetActivityResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GetActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GetActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GetActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    useractivitiesList: jspb.Message.toObjectList(msg.getUseractivitiesList(),
    proto.mazey.auth.UserActivityResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GetActivityResponse}
 */
proto.mazey.auth.GetActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GetActivityResponse;
  return proto.mazey.auth.GetActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GetActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GetActivityResponse}
 */
proto.mazey.auth.GetActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mazey.auth.UserActivityResponse;
      reader.readMessage(value,proto.mazey.auth.UserActivityResponse.deserializeBinaryFromReader);
      msg.addUseractivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GetActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GetActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GetActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseractivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mazey.auth.UserActivityResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserActivityResponse userActivities = 1;
 * @return {!Array<!proto.mazey.auth.UserActivityResponse>}
 */
proto.mazey.auth.GetActivityResponse.prototype.getUseractivitiesList = function() {
  return /** @type{!Array<!proto.mazey.auth.UserActivityResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.auth.UserActivityResponse, 1));
};


/**
 * @param {!Array<!proto.mazey.auth.UserActivityResponse>} value
 * @return {!proto.mazey.auth.GetActivityResponse} returns this
*/
proto.mazey.auth.GetActivityResponse.prototype.setUseractivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mazey.auth.UserActivityResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.auth.UserActivityResponse}
 */
proto.mazey.auth.GetActivityResponse.prototype.addUseractivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mazey.auth.UserActivityResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.auth.GetActivityResponse} returns this
 */
proto.mazey.auth.GetActivityResponse.prototype.clearUseractivitiesList = function() {
  return this.setUseractivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.Session.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.Session.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.Session} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Session.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokenid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiry: jspb.Message.getFieldWithDefault(msg, 2, ""),
    issuedat: jspb.Message.getFieldWithDefault(msg, 3, ""),
    blocked: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    blockedat: jspb.Message.getFieldWithDefault(msg, 5, ""),
    devicedesc: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isdevicemobile: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    ipaddress: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.Session}
 */
proto.mazey.auth.Session.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.Session;
  return proto.mazey.auth.Session.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.Session} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.Session}
 */
proto.mazey.auth.Session.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuedat(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlocked(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicedesc(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdevicemobile(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpaddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.Session.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.Session.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.Session} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.Session.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokenid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIssuedat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBlocked();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBlockedat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDevicedesc();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsdevicemobile();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIpaddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string tokenId = 1;
 * @return {string}
 */
proto.mazey.auth.Session.prototype.getTokenid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Session} returns this
 */
proto.mazey.auth.Session.prototype.setTokenid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string expiry = 2;
 * @return {string}
 */
proto.mazey.auth.Session.prototype.getExpiry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Session} returns this
 */
proto.mazey.auth.Session.prototype.setExpiry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string issuedAt = 3;
 * @return {string}
 */
proto.mazey.auth.Session.prototype.getIssuedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Session} returns this
 */
proto.mazey.auth.Session.prototype.setIssuedat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool blocked = 4;
 * @return {boolean}
 */
proto.mazey.auth.Session.prototype.getBlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.Session} returns this
 */
proto.mazey.auth.Session.prototype.setBlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string blockedAt = 5;
 * @return {string}
 */
proto.mazey.auth.Session.prototype.getBlockedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Session} returns this
 */
proto.mazey.auth.Session.prototype.setBlockedat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string deviceDesc = 6;
 * @return {string}
 */
proto.mazey.auth.Session.prototype.getDevicedesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Session} returns this
 */
proto.mazey.auth.Session.prototype.setDevicedesc = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool isDeviceMobile = 7;
 * @return {boolean}
 */
proto.mazey.auth.Session.prototype.getIsdevicemobile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.Session} returns this
 */
proto.mazey.auth.Session.prototype.setIsdevicemobile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string ipAddress = 8;
 * @return {string}
 */
proto.mazey.auth.Session.prototype.getIpaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.Session} returns this
 */
proto.mazey.auth.Session.prototype.setIpaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.auth.GetSessionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GetSessionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GetSessionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GetSessionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetSessionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    proto.mazey.auth.Session.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GetSessionsResponse}
 */
proto.mazey.auth.GetSessionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GetSessionsResponse;
  return proto.mazey.auth.GetSessionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GetSessionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GetSessionsResponse}
 */
proto.mazey.auth.GetSessionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mazey.auth.Session;
      reader.readMessage(value,proto.mazey.auth.Session.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GetSessionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GetSessionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GetSessionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetSessionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mazey.auth.Session.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Session sessions = 1;
 * @return {!Array<!proto.mazey.auth.Session>}
 */
proto.mazey.auth.GetSessionsResponse.prototype.getSessionsList = function() {
  return /** @type{!Array<!proto.mazey.auth.Session>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.auth.Session, 1));
};


/**
 * @param {!Array<!proto.mazey.auth.Session>} value
 * @return {!proto.mazey.auth.GetSessionsResponse} returns this
*/
proto.mazey.auth.GetSessionsResponse.prototype.setSessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mazey.auth.Session=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.auth.Session}
 */
proto.mazey.auth.GetSessionsResponse.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mazey.auth.Session, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.auth.GetSessionsResponse} returns this
 */
proto.mazey.auth.GetSessionsResponse.prototype.clearSessionsList = function() {
  return this.setSessionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.auth.OneAccess.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.OneAccess.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.OneAccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.OneAccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.OneAccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokenid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientlogo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    permisssionsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    createdat: jspb.Message.getFieldWithDefault(msg, 6, ""),
    blocked: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    blockedat: jspb.Message.getFieldWithDefault(msg, 8, ""),
    expiryat: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.OneAccess}
 */
proto.mazey.auth.OneAccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.OneAccess;
  return proto.mazey.auth.OneAccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.OneAccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.OneAccess}
 */
proto.mazey.auth.OneAccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientlogo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addPermisssions(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlocked(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedat(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.OneAccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.OneAccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.OneAccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.OneAccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokenid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientlogo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPermisssionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBlocked();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBlockedat();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExpiryat();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string tokenId = 1;
 * @return {string}
 */
proto.mazey.auth.OneAccess.prototype.getTokenid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.setTokenid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string clientID = 2;
 * @return {string}
 */
proto.mazey.auth.OneAccess.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string clientName = 3;
 * @return {string}
 */
proto.mazey.auth.OneAccess.prototype.getClientname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.setClientname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string clientLogo = 4;
 * @return {string}
 */
proto.mazey.auth.OneAccess.prototype.getClientlogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.setClientlogo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string permisssions = 5;
 * @return {!Array<string>}
 */
proto.mazey.auth.OneAccess.prototype.getPermisssionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.setPermisssionsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.addPermisssions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.clearPermisssionsList = function() {
  return this.setPermisssionsList([]);
};


/**
 * optional string createdAt = 6;
 * @return {string}
 */
proto.mazey.auth.OneAccess.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool blocked = 7;
 * @return {boolean}
 */
proto.mazey.auth.OneAccess.prototype.getBlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.setBlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string blockedAt = 8;
 * @return {string}
 */
proto.mazey.auth.OneAccess.prototype.getBlockedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.setBlockedat = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string expiryAt = 9;
 * @return {string}
 */
proto.mazey.auth.OneAccess.prototype.getExpiryat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.OneAccess} returns this
 */
proto.mazey.auth.OneAccess.prototype.setExpiryat = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.auth.GetAppLoginsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GetAppLoginsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GetAppLoginsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GetAppLoginsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetAppLoginsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesslistList: jspb.Message.toObjectList(msg.getAccesslistList(),
    proto.mazey.auth.OneAccess.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GetAppLoginsResponse}
 */
proto.mazey.auth.GetAppLoginsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GetAppLoginsResponse;
  return proto.mazey.auth.GetAppLoginsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GetAppLoginsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GetAppLoginsResponse}
 */
proto.mazey.auth.GetAppLoginsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mazey.auth.OneAccess;
      reader.readMessage(value,proto.mazey.auth.OneAccess.deserializeBinaryFromReader);
      msg.addAccesslist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GetAppLoginsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GetAppLoginsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GetAppLoginsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GetAppLoginsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mazey.auth.OneAccess.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OneAccess accessList = 1;
 * @return {!Array<!proto.mazey.auth.OneAccess>}
 */
proto.mazey.auth.GetAppLoginsResponse.prototype.getAccesslistList = function() {
  return /** @type{!Array<!proto.mazey.auth.OneAccess>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mazey.auth.OneAccess, 1));
};


/**
 * @param {!Array<!proto.mazey.auth.OneAccess>} value
 * @return {!proto.mazey.auth.GetAppLoginsResponse} returns this
*/
proto.mazey.auth.GetAppLoginsResponse.prototype.setAccesslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mazey.auth.OneAccess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mazey.auth.OneAccess}
 */
proto.mazey.auth.GetAppLoginsResponse.prototype.addAccesslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mazey.auth.OneAccess, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.auth.GetAppLoginsResponse} returns this
 */
proto.mazey.auth.GetAppLoginsResponse.prototype.clearAccesslistList = function() {
  return this.setAccesslistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mazey.auth.AppLoginDetailsResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.AppLoginDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.AppLoginDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.AppLoginDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokenid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 2, ""),
    blocked: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    blockedat: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expiryat: jspb.Message.getFieldWithDefault(msg, 5, ""),
    grantedpermsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    clientid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    clientname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    companyname: jspb.Message.getFieldWithDefault(msg, 9, ""),
    clientlogo: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mytrusted: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    myowned: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    privacypolicy: jspb.Message.getFieldWithDefault(msg, 13, ""),
    terms: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.AppLoginDetailsResponse}
 */
proto.mazey.auth.AppLoginDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.AppLoginDetailsResponse;
  return proto.mazey.auth.AppLoginDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.AppLoginDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.AppLoginDetailsResponse}
 */
proto.mazey.auth.AppLoginDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlocked(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addGrantedperms(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientlogo(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMytrusted(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMyowned(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivacypolicy(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.AppLoginDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.AppLoginDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.AppLoginDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokenid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBlocked();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBlockedat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpiryat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGrantedpermsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getClientname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getClientlogo();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMytrusted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getMyowned();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getPrivacypolicy();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTerms();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string tokenId = 1;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getTokenid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setTokenid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string createdAt = 2;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool blocked = 3;
 * @return {boolean}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getBlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setBlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string blockedAt = 4;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getBlockedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setBlockedat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string expiryAt = 5;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getExpiryat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setExpiryat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string grantedPerms = 6;
 * @return {!Array<string>}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getGrantedpermsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setGrantedpermsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.addGrantedperms = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.clearGrantedpermsList = function() {
  return this.setGrantedpermsList([]);
};


/**
 * optional string clientID = 7;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string clientName = 8;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getClientname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setClientname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string companyName = 9;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string clientLogo = 10;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getClientlogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setClientlogo = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool myTrusted = 11;
 * @return {boolean}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getMytrusted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setMytrusted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool myOwned = 12;
 * @return {boolean}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getMyowned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setMyowned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string privacyPolicy = 13;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getPrivacypolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setPrivacypolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string terms = 14;
 * @return {string}
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.getTerms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.AppLoginDetailsResponse} returns this
 */
proto.mazey.auth.AppLoginDetailsResponse.prototype.setTerms = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mazey.auth.GeneralResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mazey.auth.GeneralResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mazey.auth.GeneralResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GeneralResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mazey.auth.GeneralResponse}
 */
proto.mazey.auth.GeneralResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mazey.auth.GeneralResponse;
  return proto.mazey.auth.GeneralResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mazey.auth.GeneralResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mazey.auth.GeneralResponse}
 */
proto.mazey.auth.GeneralResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mazey.auth.GeneralResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mazey.auth.GeneralResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mazey.auth.GeneralResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mazey.auth.GeneralResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mazey.auth.GeneralResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mazey.auth.GeneralResponse} returns this
 */
proto.mazey.auth.GeneralResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.mazey.auth);
