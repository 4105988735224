import React from 'react'
import { CardContent, Typography } from "@material-ui/core";

export default function Submitted() {
    return <CardContent>
        <Typography variant="h5" gutterBottom>
            Hold On!
        </Typography>
        <br />
        <Typography variant="body1" gutterBottom>
            Your account is under scrutiny.
            <br />
            We will notify you once it is activated.
        </Typography>
        <br /><br /><br />
        <Typography variant="caption" display="block" gutterBottom>
            Hold on for some time.
            <br />
            We will try our best to be as fast as possible.
        </Typography>
    </CardContent>
}