import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, CardContent, FormControl, TextField, Input, InputLabel, InputAdornment, Button, IconButton, CircularProgress, Typography, Divider } from "@material-ui/core";
import { AccountCircle, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons';
import clsx from 'clsx';
import { AuthServiceClient } from '../../protos/auth_grpc_web_pb';
import { LoginRequest } from '../../protos/auth_pb';
import { AuthServerAddress } from '../../protos/server';
import { useDispatch } from "react-redux";
import { loginStyles } from './styles';
import GetMetadata from "../customComponents/getMetadata";

export default function LoginForm() {
    const classes = loginStyles();
    const dispatch = useDispatch();
    const [btnStat, setBtnStat] = useState(0);

    const [values, setValues] = useState({
        username: '',
        password: '',
        showPassword: false,
    });
    const [errF, setErrF] = useState({
        username: {
            err: false,
            msg: " ",
        },
        password: {
            err: false,
            msg: " "
        },
        msg: " ",
    })

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        setErrF({
            ...errF, [prop]: {
                err: false,
                msg: " "
            },
            msg: " ",
        })
    };


    const submitBtn = () => {
        if (btnStat === 2) {
            return <Button className={classes.submitButton} >
                <CircularProgress size={25} />
            </Button>
        } else {
            return <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                className={classes.submitButton}
                disabled={values.username.length < 5 || values.password.length < 7}
            >
                Sign In
            </Button>
        }
    }

    const handleSubmit = () => {
        setBtnStat(2);
        const client = new AuthServiceClient(AuthServerAddress, null, null)
        const req = new LoginRequest();
        req.setUsername(values.username);
        req.setPassword(values.password);

        const md = GetMetadata()
        client.login(req, md, (err, response) => {
            if (err) {
                if (err.message === "No Match") {
                    window.navigator.vibrate(200);
                    setErrF({ ...errF, msg: "No Match" })
                } else if (err.message === "Invalid Username") {
                    window.navigator.vibrate(200);
                    setErrF({
                        ...errF, username: {
                            err: true,
                            msg: err.message,
                        }
                    })
                } else if (err.message === "Invalid Password") {
                    window.navigator.vibrate(200);
                    setErrF({
                        ...errF, password: {
                            err: true,
                            msg: err.message,
                        }
                    })
                } else {
                    setErrF({ ...errF, msg: "Something went wrong" })
                }
                setBtnStat(0);
                return
            } else {
                const res = response.toObject()
                switch (res.error) {
                    case "Running": {
                        setBtnStat("f");
                        const userData = {
                            token: res.token,
                            firstName: res.firstname,
                            lastName: res.lastname,
                            userStatus: res.userstatus,
                            ueref: res.userreference,
                            userRole: res.userrole,
                        }
                        const setUser = { type: 'USER_RUN', userData: userData };
                        dispatch(setUser);
                        localStorage.setItem('user', JSON.stringify(userData));
                        const setLogStore = { type: 'LOGIN', tokenValue: res.token };
                        dispatch(setLogStore);
                        return
                    }
                    case "Pending Update": {
                        setBtnStat(0);
                        const userData = {
                            firstName: res.firstname,
                            lastName: res.lastname,
                            userStatus: res.userstatus,
                            ueref: res.userreference,
                        }
                        const setUserData = { type: 'USER_RUN', userData: userData };
                        dispatch(setUserData);
                        const setTempLog = { type: 'TEMPLOGIN', loginStat: "pendingUpdate" };
                        dispatch(setTempLog);
                        return
                    }
                    case "Pending Activation": {
                        setBtnStat(0);
                        const setTempLog = { type: 'TEMPLOGIN', loginStat: "pendingActivation" };
                        dispatch(setTempLog);
                        return
                    }
                    case "Submitted": {
                        setBtnStat(0);
                        const setTempLog = { type: 'TEMPLOGIN', loginStat: "submitted" };
                        dispatch(setTempLog);
                        return
                    }
                    case "Account Locked": {
                        setBtnStat(0);
                        const setTempLog = { type: 'TEMPLOGIN', loginStat: "suspended" };
                        dispatch(setTempLog);
                        return
                    }
                    case "Account Suspended": {
                        setBtnStat(0);
                        const setTempLog = { type: 'TEMPLOGIN', loginStat: "suspended" };
                        dispatch(setTempLog);
                        return
                    }
                    default: {
                        setBtnStat(0);
                        setErrF({ ...errF, msg: "Something went wrong" })
                        return
                    }
                }
            }
        })
    };


    return <React.Fragment>
        <div className={classes.logoCont}>
            <Typography className={classes.logoHeader}>
                MazeYard
            </Typography>
            <Typography className={classes.logoSubHeader}>
                Identity Serivce66
            </Typography>
        </div>
        <Divider light />
        <br />
        <CardContent>
            <form noValidate autoComplete="off">
                <Grid container spacing={1} justify="center" alignItems="flex-end" >
                    <Grid item className={classes.gridIcon}>
                        <AccountCircle />
                    </Grid>
                    <Grid item>
                        <FormControl className={clsx(classes.margin, classes.textField)}>
                            <TextField
                                id="input-with-icon-grid"
                                label="Username"
                                name="username"
                                onChange={handleChange('username')}
                                autoFocus
                                fullWidth
                                error={errF.username.err}
                                helperText={errF.username.msg}
                                disabled={btnStat === 2}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1} justify="center" alignItems="flex-end">
                    <Grid item>
                        <VpnKey />
                    </Grid>
                    <Grid item>
                        <FormControl className={clsx(classes.margin, classes.textField)}>
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                error={errF.password.err}
                                onChange={handleChange('password')}
                                disabled={btnStat === 2}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setValues({ ...values, showPassword: !values.showPassword })}
                                        // onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </CardContent>
        <br />
        <Typography color="error" variant='body2'>{errF.msg}</Typography>
        <br />
        <CardContent>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Button
                    variant="text"
                    color="primary"
                    size="small"
                    component={Link}
                    to="/resetPass"
                >
                    Forgot Password?
                </Button>
                {submitBtn()}
            </Grid>
        </CardContent>
        <br />
    </React.Fragment>
}