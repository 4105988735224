import React, { useState } from 'react'
import { useStyles } from '../styles';
import { Typography, TextField, Paper, Grid, FormControl, Select, MenuItem, FormHelperText } from '@material-ui/core';

export default function PrimaryContact(props) {
    const classes = useStyles();

    const [values, setValues] = useState({
        email: props.contactDetails[0].contactvalue,
        isEmailPublic: props.contactDetails[0].ispublic,
        // phone: props.contactDetails[1].contactvalue,
        // isPhonePublic: props.contactDetails[1].ispublic,
    })

    const changeValue = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value })
    }

    // const renderEmailSubmitBtn = () => {
    //     if (stat.email === 0) {
    //         return <Button
    //             variant="contained"
    //             color="primary"
    //             disabled={values.email === props.contactDetails[0].contactvalue && values.isEmailPublic === props.contactDetails[0].ispublic && values.phone === props.contactDetails[1].contactvalue && values.isPhonePublic === props.contactDetails[1].ispublic}
    //             className={classes.contactBtns}
    //         >
    //             Update
    //         </Button>
    //     } else if (stat.email === 2) {
    //         return <Button variant="outlined" color="primary">
    //             <CircularProgress size={25} />
    //         </Button>
    //     } else if (stat.email === 1) {
    //         return <Button variant="outlined" color="primary" disabled>
    //             <DoneAll />
    //         </Button>
    //     } else {
    //         return <Button variant="outlined" disabled>
    //             Error
    //         </Button>
    //     }
    // }

    return <React.Fragment >
        <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom color="textPrimary" align="left">
                Primary Contact Details
            </Typography>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                    <TextField
                        id="email-address"
                        label="Email Address"
                        name="email"
                        value={values.email}
                        onChange={changeValue}
                        className={classes.contactInput}
                        disabled
                    // error={err.username.length > 0}
                    // helperText={err.username}
                    // disabled={saveBtnStat === "l"}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                        <FormHelperText>Visibility</FormHelperText>
                        <Select
                            id="email-visibility"
                            name="isEmailPublic"
                            value={values.isEmailPublic}
                            onChange={changeValue}
                            // displayEmpty
                            fullWidth
                            disabled
                            className={classes.contactSelect}
                            inputProps={{ 'aria-label': 'email-visibility' }}
                        // labelId="demo-simple-select-label"
                        >
                            <MenuItem value={true}>Public</MenuItem>
                            <MenuItem value={false}>Private</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={8}>
                    <TextField
                        id="phone-number"
                        label="Phone Number"
                        name="phone"
                        value={values.phone}
                        onChange={changeValue}
                        className={classes.contactInput}
                    // error={err.username.length > 0}
                    // helperText={err.username}
                    // disabled={saveBtnStat === "l"}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                        <FormHelperText>Visibility</FormHelperText>
                        <Select
                            id="phone-visibility"
                            name="isPhonePublic"
                            value={values.isPhonePublic}
                            onChange={changeValue}
                            // displayEmpty
                            fullWidth
                            className={classes.contactSelect}
                            inputProps={{ 'aria-label': 'phone-visibility' }}
                        // labelId="demo-simple-select-label"
                        >
                            <MenuItem value={true}>Public</MenuItem>
                            <MenuItem value={false}>Private</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> */}
                {/* <Grid item xs={12} sm={9}>
                    {renderEmailSubmitBtn()}
                </Grid> */}
            </Grid>
            <br />
        </Paper>
    </React.Fragment >
}