import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { securityStyles } from '../securityStyles';
import RenderSessions from './renderSessions';


export default function Sessions() {
    const classes = securityStyles()

    return <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={10}>
            <Paper className={classes.signinPaper}>
                <RenderSessions type="Active" />
            </Paper>
            <Paper className={classes.signinPaper}>
                <RenderSessions type="Past" />
            </Paper>
        </Grid>
    </Grid>
}