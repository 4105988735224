import React from 'react'
import { Typography, Paper, Grid, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from "@material-ui/core";
import { helpCenterStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { useGetSupportTickets } from '../customHooks/useGetSupportTickets'

export default function TicketsList(props) {
    const classes = helpCenterStyles();
    const history = useHistory()

    const [stat, tickets] = useGetSupportTickets(props.type)

    const onIssueClick = (val) => {
        history.push("/dashboard/helpCenter/ticket?Id=" + val)
    }

    const ticketSeenStatus = (row) => {
        if (props.type === "open") {
            if (row.hasuserseen) {
                return classes.issueLink
            } else {
                return classes.issueLinkNew
            }
        }
        return classes.issueLink
    }

    const mapTickets = () => {
        return tickets.map((row) => (
            <TableRow
                key={row.ticketid}
                className={ticketSeenStatus(row)}
                onClick={() => onIssueClick(row.ticketid)}
            >
                <TableCell component="th" scope="row">
                    {row.subject}
                </TableCell>
                <TableCell align="left">{row.tickettype}</TableCell>
                <TableCell align="left">{props.type === "open" ? row.creationtime : row.lastupdatetime}</TableCell>
            </TableRow>
        ))
    }

    const render = () => {
        if (stat === 2) {
            return <center><CircularProgress size={25} /></center>
        } else if (stat === 0) {
            return <>
                <Typography variant="h6" align="left">
                    {props.type} Tickets
                </Typography>
                <br />
                {
                    tickets.length === 0 ? <Typography>
                        No Tickets
                    </Typography> : <Table className={classes.issuesTable} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"><strong>Subject</strong></TableCell>
                                <TableCell align="left"><strong>Ticket Type</strong></TableCell>
                                <TableCell align="left"><strong>{props.type === "open" ? "Creation Time" : "Closing Time"}</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mapTickets()}
                        </TableBody>
                    </Table>
                }
            </>
        } else {
            return <Typography color="textSecondary">
                Something went wrong
            </Typography>
        }
    }

    return <Grid container spacing={3}>
        <Grid item xs={12} className={classes.issueGrid}>
            <Paper className={classes.ciPaper} >
                {render()}
            </Paper>
        </Grid>
    </Grid>
}