import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useStyles } from './styles';
import { CircularProgress, DialogContent } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AuthServiceClient } from '../protos/auth_grpc_web_pb';
import { GeneralRequest } from '../protos/auth_pb';
import { AuthServerAddress } from '../protos/server';
import GetMetadata from './customComponents/getMetadata';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LogOutDialog(props) {
    const classes = useStyles();
    const [stat, setStat] = useState(0);
    const open = props.state;
    const dispatch = useDispatch();
    const token = useSelector(state => state.token)

    const handleClose = () => {
        props.setState(false)
        setStat(0)
    };

    const webLogout = () => {
        setStat(2)
        // send token to logout
        const client = new AuthServiceClient(AuthServerAddress, null, null)
        const req = new GeneralRequest();

        const md = GetMetadata();
        md.authorization = token;
        client.logout(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setStat(-1)
            } else {
                const res = response.toObject()
                if (res.message === "Success") {
                    offlineLogout()
                }
            }
        })
    }

    const offlineLogout = () => {
        localStorage.removeItem('user');
        const setLog = { type: 'LOGOUT' };
        dispatch(setLog);
    }

    const render = () => {
        if (stat === 0) {
            return <React.Fragment>
                <DialogTitle id="logout-dialog-title">{"Are you sure to logout?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={webLogout} className={classes.logoutBtn}>
                        Logout
                    </Button>
                </DialogActions>
            </React.Fragment>
        } else if (stat === 2) {
            return <React.Fragment>
                <DialogTitle id="logout-dialog-title">Logging you out</DialogTitle>
                <DialogContent>
                    <CircularProgress size={30} />
                </DialogContent>
            </React.Fragment>
        } else if (stat === -1) {
            return <React.Fragment>
                <DialogTitle id="logout-dialog-title">Logout failed. Do you want to force logout?</DialogTitle>
                <DialogContent>
                    This will persist your session.
                    <br />
                    You will manually have to clear this session from your DashBoard.
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={offlineLogout} className={classes.logoutBtn}>
                        Force Logout
                    </Button>
                </DialogActions>
            </React.Fragment>
        } else {
            return <React.Fragment />
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="logout-dialog"
                aria-describedby="ask-confirmation-for-logout"
            >
                {render()}
            </Dialog>
        </div>
    );
}
