
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	register: {
		width: '100%',
		height: '100%',
		backgroundColor: theme.palette.background.page,
	},
	card: {
		boxShadow: '0 0 5px 0 ' + theme.palette.divider,
		padding: 10,
	},
	form: {
		maxWidth: 300,
	},
	textField: {
		width: '80%',
		marginBottom: 10,
	},
	margin: {
		margin: theme.spacing(1),
	},
	link: {
		color: 'inherit',
		textDecoration: 'none',
	}
}));