// export const AuthServerAddress = "http://127.0.0.1:8011";
// export const AuthServerAddress = "http://20.198.1.96:8080";
// export const InstiServerAddress = "http://20.198.1.96:8080";
// export const FileOpsAddress = "http://127.0.0.1:8400/customFuncs/uploadID/";
// export const AccountsServerAddress = "http://127.0.0.1:10000";
// export const SupportServerAddress = "http://127.0.0.1:9167";
// export const StorageHTTPAddress = "http://127.0.0.1:7217/mazey.storage.FileService";


export const AuthServerAddress = "https://services.mazeyard.com";
export const InstiServerAddress = "https://services.mazeyard.com";
export const FileOpsAddress = "https://services.mazeyard.com/mazey.auth.AuthService/customFuncs/uploadID/";
export const AccountsServerAddress = "https://services.mazeyard.com";
export const SupportServerAddress = "https://services.mazeyard.com";
export const StorageHTTPAddress = "https://services.mazeyard.com/mazey.storage.FileService";
