import React from 'react';
import { useStyles } from '../styles';
import { Grid, Paper, Typography, CardContent, CircularProgress } from '@material-ui/core';
import { useGetProfile } from '../../../customHooks/useGetProfile'

export default function Account() {
    const classes = useStyles();
    const [stat, profileData] = useGetProfile()

    const render = () => {
        if (stat === 2) {
            return <CircularProgress size={30} />
        } else if (stat === 0) {
            return <>
                <Typography variant="h6" gutterBottom color="textPrimary" align="left">
                    Account Info
                </Typography>
                <CardContent>
                    <Typography variant="body1" color="textSecondary" align="left">
                        User Reference - <strong>{profileData.ueref}</strong>
                        <br />
                        Account Type - <strong>{profileData.accounttype}</strong>
                        <br />
                        Account Validity - <strong>{profileData.validity}</strong>
                    </Typography>
                </CardContent>
            </>
        } else {
            return <Typography variant="body1" color="textSecondary" >
                Unable to load data
            </Typography>
        }
    }

    return <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={10}>
            <Paper className={classes.paper}>
                {render()}
            </Paper>
        </Grid>
    </Grid>
}