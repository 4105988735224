import React from 'react'
import { Typography, Paper, CardContent, CircularProgress, List, ListItemText, ListItem, Divider, ListItemAvatar } from "@material-ui/core";
import { appsLoginStyles } from './styles';
import { Link } from 'react-router-dom';
import { useGetAppLogins } from '../customHooks/useGetAppLogins'

export default function LoginsList(props) {
    const classes = appsLoginStyles();
    const [stat, logins] = useGetAppLogins(props.type)

    const tokenDate = (login) => {
        if (props.type === "Active") {
            return <>
                <strong>Issued At</strong> - {login.createdat}
            </>
        } else if (login.blocked) {
            return <>
                <strong>Blocked At</strong> - {login.blockedat}
            </>
        } else {
            return <>
                <strong>Expired At</strong> - {login.expiredat}
            </>
        }
    }

    const render = () => {
        if (stat === 0) {
            return <>
                <Typography variant="h6">
                    {props.type === "Active" ? "Apps that currently have access to your account." : "Apps you previously logged in into with Service66"}

                </Typography>
                <br />
                <Divider />
                <CardContent>
                    <List>
                        {
                            logins.map(l => {
                                return <ListItem
                                    component={Link}
                                    to={"/dashboard/appLogins/LoginId/" + l.tokenid}
                                    key={l.tokenid}
                                    className={classes.appTitle}
                                >
                                    <ListItemAvatar>
                                        <img src={l.clientlogo} alt="Client logo" className={classes.clientlogo} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <strong>{l.clientname}</strong>
                                        }
                                        secondary={
                                            <>
                                                {tokenDate(l)}
                                                <br />
                                                <strong>Total Permissions Granted</strong> -  {l.permisssionsList.length}
                                            </>
                                        }
                                    />
                                </ListItem>
                            })
                        }
                    </List>
                </CardContent>
            </>
        } else if (stat === 2) {
            return <center>
                <CircularProgress size={30} />
            </center>
        } else if (stat === -1) {
            return <center>
                <Typography variant="body1">
                    Unable to load data
                </Typography>
            </center>
        } else {
            return <></>
        }
    }

    return <Paper className={classes.ciPaper}>
        {render()}
    </Paper>
}