import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralRequest } from '../../protos/support_pb';
import { SupportServiceClient } from '../../protos/support_grpc_web_pb';
import { SupportServerAddress } from '../../protos/server';
import GetMetadata from '../customComponents/getMetadata';

export function useGetSupportTickets(ticketType) {
    const [stat, setStat] = useState(2)
    const [tickets, setTickets] = useState([])

    const userData = useSelector(state => state.userData)
    const supportTickets = useSelector(state => state.supportTickets)

    const dispatch = useDispatch()
    const supportClient = new SupportServiceClient(SupportServerAddress, null, null)
    var md = GetMetadata()

    const fetchTickets = (token) => {
        const req = new GeneralRequest();
        req.setMessage(ticketType)
        md.authorization = token

        supportClient.getTickets(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setStat(-1)
            } else {
                const res = response.toObject()
                const setAppsLogins = { type: 'SET_TICKETS', ticketType: ticketType, tickets: res.ticketsList };
                dispatch(setAppsLogins);
                setTickets(res.ticketsList)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        if (supportTickets[ticketType] === undefined) {
            fetchTickets(userData.token)
        } else {
            setTickets(supportTickets[ticketType])
            setStat(0)
        }
    }, [])

    return [stat, tickets]
}