import React from 'react';
import './index.css';
import UserApps from "./apps";
import { Provider } from 'react-redux';
import { store } from './apps/globalState';

function App() {
  return <Provider store={store}>
    <UserApps />
  </Provider>
}

export default App;
