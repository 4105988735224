import React from 'react'
import { Typography, CardContent, CircularProgress } from '@material-ui/core';
import EachSession from './eachSession';
import { useGetSessions } from '../../customHooks/useGetSessions'

export default function RenderSessions(props) {
    const [stat, sessions] = useGetSessions(props.type)

    const renderEachSession = () => {
        return sessions.map(s => {
            return <EachSession type={props.type} session={s} key={s.tokenid} />
        })
    }

    const render = () => {
        if (stat === 2) {
            return <CircularProgress size={30} />
        } else if (stat === 0) {
            return <CardContent>
                {renderEachSession()}
            </CardContent>
        } else {
            return <Typography align="left" color="textSecondary">
                Something went wrong
            </Typography>
        }
    }

    return <>
        <Typography variant="h6" align="left" color="textPrimary">
            {
                props.type === "Active" ? "Active Sessions" : "Past Sessions"
            }
        </Typography>
        <Typography variant="body1" align="left" color="textSecondary">
            {
                props.type === "Active" ? "Below are the devices you are currently signed in into." : "Below are the devices you signed in into in past."
            }
        </Typography>
        {render()}
    </>
}