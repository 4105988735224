import React from 'react';
import { Grid } from '@material-ui/core';
import HomeGreeting from './greeting';
import { homeStyles } from './homeStyles';
import { useSelector } from 'react-redux';

export default function Home() {
    const classes = homeStyles()
    const userData = useSelector(state => state.userData)

    return <Grid container spacing={3} className={classes.homeGridCont}>
        <Grid item xs={12} sm={8}>
            <HomeGreeting userData={userData} />
        </Grid>
    </Grid>
}