import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import UserbaseRoutes from './routes';

export default function UserApps() {
    const dispatch = useDispatch();

    const getUser = () => {
        const userObj = JSON.parse(localStorage.getItem('user'));
        if (userObj !== null) {
            const setUser = { type: 'USER_RUN', userData: userObj };
            dispatch(setUser);
            const setLog = { type: 'LOGIN', tokenValue: userObj.token };
            dispatch(setLog);
        }
    }

    useEffect(() => {
        getUser();
    }, [])

    return <UserbaseRoutes />
}