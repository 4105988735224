import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, IconButton, Grid, Paper, DialogTitle } from '@material-ui/core';
import { DoneAll, CloudUpload, Close, ErrorOutline } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from '../styles';
import GetMetadata from '../../../customComponents/getMetadata';
import { BorderLinearProgress } from '../../../customComponents/borderLinearProgress'
import axios from 'axios'
import { StorageHTTPAddress } from '../../../../protos/server'
import { ChangeUserPropertyRequest } from '../../../../protos/accounts_pb';
import { AccountsServiceClient } from '../../../../protos/accounts_grpc_web_pb';
import { AccountsServerAddress } from '../../../../protos/server';

export default function ProfilePictureDialog(props) {
    const classes = useStyles()
    const [error, setError] = useState(" ")
    const [uploadStat, setUploadStat] = useState(0)
    const [uploadPercentage, setUploadPercentage] = useState(0)
    const md = GetMetadata()
    const dispatch = useDispatch()
    const [file, setFile] = useState(undefined)
    const toSendFormData = new FormData()
    const userData = useSelector(state => state.userData)
    const accClient = new AccountsServiceClient(AccountsServerAddress, null, null)

    const removeFile = () => {
        setError(" ")
        setFile(undefined)
        setUploadPercentage(0)
        setUploadStat(0)
    }

    const closeDialog = () => {
        removeFile()
        props.CloseProfilePictureDialog()
    }

    const uploadDivClickHandler = event => {
        const file = event.target.files[0]
        if (file.name.length > 32) {
            setError("Max 32 characters are allowed in filename")
            return
        }
        if (file.size / 1000 > 1000) {
            setError("Files exceeding 1 MB are not supported yet.")
            return
        }
        setError(" ")
        setFile(file)
    }

    const dragOverHandler = (event) => {
        event.preventDefault()
    }

    const fileDropHandler = (event) => {
        event.preventDefault()
        setError(" ")
        if (event.dataTransfer.items.length > 0) {
            // accept only file
            if (event.dataTransfer.items[0].kind === 'file') {
                var file = event.dataTransfer.items[0].getAsFile()
                if (file.name.length > 32) {
                    setError("Max 32 characters are allowed in filename")
                    return
                }
                if (file.size / 1000 > 1000) {
                    setError("Files exceeding 1 MB are not supported yet.")
                    return
                }
                setFile(file)
            }
        } else if (event.dataTransfer.files.length > 0) {
            setFile(event.dataTransfer.files[0])
        }
    }

    const uploadPicture = (token) => {
        if (error === " " && file !== undefined) {
            setUploadStat(2)
            setUploadPercentage(0)
            const fileNameArray = file.name.split(".")
            const fileExtension = fileNameArray[fileNameArray.length - 1]

            const currDate = new Date()
            const currTime = "" + currDate.getFullYear() + (currDate.getMonth() + 1) + currDate.getDate() + currDate.getHours() + currDate.getMinutes() + currDate.getSeconds()
            const fileName = "picture_" + currTime + "." + fileExtension
            toSendFormData.append(
                "file",
                file,
                fileName,
            )
            toSendFormData.append('visibility', "MY users");
            toSendFormData.append('directory', "/service66");
            toSendFormData.append('forceDirectoryCreation', true);

            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'platform': md.platform,
                    'vendor': md.vendor,
                    'useragent': md.useragent,
                    'authorization': token,
                },
                onUploadProgress: function (progressEvent) {
                    setUploadPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                },
                validateStatus: () => true
            }
            axios.put(StorageHTTPAddress + '/upload', toSendFormData, axiosConfig)
                .then(res => {
                    if (res.data?.Response === "Success") {
                        changeProfilePicture(token, res.data.FileAddress)
                        return
                    } else if (res.data) {
                        setError(res.data)
                        setUploadStat(-1)
                    } else {
                        setUploadStat(-1)
                    }
                })
                .catch(err => {
                    console.log(err)
                    setUploadStat(-1)
                    if (err.data) {
                        setError(err.data)
                        return
                    }
                    setError("Unknown error")
                })
        }
    }

    const changeProfilePicture = (token, pictureAddress) => {
        const req = new ChangeUserPropertyRequest();
        md.authorization = token
        req.setProperty("profilePicture")
        req.setValue(pictureAddress)

        accClient.changeUserProperty(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setUploadStat(-1)
            } else {
                setUploadStat(1)
                dispatch({ type: 'REMOVE_PROFILE_PIC' })
                dispatch({ type: 'REMOVE_PROFILE' })
                setTimeout(() => {
                    closeDialog()
                    props.setNameCardKey(props.nameCardKey + 1)
                }, 1000)
            }
        })
    }

    const renderPaper = () => {
        if (file === undefined) {
            return <label
                onDrop={fileDropHandler}
                onDragOver={dragOverHandler}
                htmlFor="file_upload_input"
                onChange={uploadDivClickHandler}
            >
                <input
                    type='file'
                    id='file_upload_input'
                    style={{ display: 'none' }}
                    accept="image/*"
                />
                <Typography align="center">
                    Drag and drop file here or click to browse
                </Typography>
                <br />
                <center>
                    <CloudUpload style={{ fontSize: 50 }} color="primary" />
                </center>
            </label>
        } else {
            if (uploadStat === 2) {
                // render progress
                return <BorderLinearProgress variant="determinate" value={uploadPercentage} />
            } else if (uploadStat === -1) {
                return <center>
                    <ErrorOutline style={{ fontSize: 50 }} color="error" />
                </center>
            } else if (uploadStat === 1) {
                // success
                return <center>
                    <DoneAll style={{ fontSize: 50 }} color="primary" />
                </center>
            } else if (uploadStat === 0) {
                return <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Typography noWrap>
                        {file.name}
                    </Typography>
                    <IconButton onClick={removeFile}>
                        <Close />
                    </IconButton>
                </Grid>
            }
        }
    }


    return <Dialog
        open={props.profilePictureDialogState}
        onClose={closeDialog}
        aria-labelledby="profile-picture-dialog-title"
        aria-describedby="profile-picture-dialog-description"
    >
        <DialogTitle id="profile-picture-dialog-title">
            Set New Profile Picture
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
            <Paper
                className={classes.filePaper}
                elevation={0}
            >
                {renderPaper()}
            </Paper>
            <Typography variant="caption" color="error">
                {error}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDialog} color="primary">
                Cancel
            </Button>
            <Button
                onClick={() => uploadPicture(userData.token)}
                color="primary"
                autoFocus
                disabled={error.length > 2}
            >
                Upload
            </Button>
        </DialogActions>
    </Dialog>
}
