import { createStore } from "redux";

// Redux Store
const rootReducer = (state = {
    // loggedIn: "loading",
    loggedIn: "notLogged",
    // loggedIn: "running",
    // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJFQyBVc2VyIEdyb3VwIiwiZXhwIjoxNjE5NzA0OTAxLCJpYXQiOjE2MTcxMTI5MDEsImlzcyI6Ik1hemVZYXJkIElkZW50aXR5IFNlcnZpY2U2NiIsImp0aSI6ImQ5MWY2YTc1LTQ2ZTgtNDc3ZC1hMTA3LWU4ZWZkNWNkNmJhYyIsInN1YiI6IlVzZXIgQWNjZXNzIiwidWN1cCI6Ik1ZLUNVUCIsInVlcmVmIjoxNTE4MjY0OTc5MiwidXIiOiJzIn0.9LAmZnaPTDkCzMJ706VpD_jLBbADfumAQqGG2FxnMMA",
    token: "",
    userData: {
        firstName: "",
        lastName: "",
        token: "",
        ueref: "",
        userRole: "",
        userStatus: "",
    },
    collegeData: {},
    profileData: {},
    profilePicSource: "",
    contactDetails: [{}, {}, {}, {}, {}, {}],
    privacySettings: {
        showInSearch: true,
        showEdDetails: true,
        webLoadStat: 0,
    },
    lastPasswordChange: {
        // changeTime: "13 March 2021 - 12:13:14",
        // changeInterval: "20 days ago",
    },
    securityActivities: [],
    supportTickets: {},
    appLogins: {},
    sessions: {},
}, action) => {
    //response to action
    switch (action.type) {
        // Logged in cases
        case 'LOGIN':
            return { ...state, loggedIn: "running", token: action.tokenValue };
        case 'TEMPLOGIN':
            return { ...state, loggedIn: action.loginStat };
        case 'LOGOUT':
            return {
                loggedIn: "notLogged",
                token: "",
            };

        // Account
        case 'SET_PROFILE':
            return { ...state, profileData: action.profileData };
        case 'SET_CONTACTS':
            return { ...state, contactDetails: action.contacts };
        case 'SET_COLLEGE_DATA':
            return { ...state, collegeData: action.collegeData };
        case 'SET_ABOUT':
            return {
                ...state, profileData: {
                    ...state.profileData, about: action.about
                }
            }
        case 'REMOVE_PROFILE':
            return { ...state, profileData: {} };
        case 'SET_PROFILE_PIC':
            return { ...state, profilePicSource: action.data };
        case 'REMOVE_PROFILE_PIC':
            return { ...state, profilePicSource: "" };

        // Security
        case 'SET_ACTIVITIES':
            return { ...state, securityActivities: action.activities };

        // app logins
        case 'REMOVE_APP_LOGINS':
            return { ...state, appLogins: {} }
        case 'SET_APP_LOGINS':
            return {
                ...state, appLogins: {
                    ...state.appLogins, [action.loginType]: action.loginsData
                }
            }

        case 'USER_RUN':
            return { ...state, userData: action.userData };
        case 'CHANGE_PRIVACY_SETTINGS':
            const newPrivacySettings = { ...state.privacySettings, [action.property]: action.value }
            return { ...state, privacySettings: newPrivacySettings };

        // tickets
        case 'SET_TICKETS':
            return {
                ...state, supportTickets: {
                    ...state.supportTickets, [action.ticketType]: action.tickets
                }
            };
        case 'REMOVE_ACTIVE_TICKETS':
            return {
                ...state, supportTickets: {
                    ...state.supportTickets, open: undefined
                }
            };
        case 'REMOVE_CLOSED_TICKETS':
            return {
                ...state, supportTickets: {
                    ...state.supportTickets, closed: undefined
                }
            };

        // Sessions
        case 'SET_SESSIONS':
            return {
                ...state, sessions: {
                    ...state.sessions, [action.sessionType]: action.sessions
                }
            };
        case 'REMOVE_SESSIONS':
            return { ...state, sessions: {} };

        default:
            return state
    }
};

export const store = createStore(rootReducer);
