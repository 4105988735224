import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            ultraLight: '#CADFFF',
            light: '#83B5FE',
            main: '#1771F6',
            dark: '#5588D3',
            ultraDark: '#243E64',
        },
        secondary: {
            main: '#f67017',
            // main: '#db4437',
        },
        background: {
            page: '#F7FBFF',
        },
        error: {
            main: '#FC2349',
            // main: '#F44336',
        },
        success: {
            main: '#54B258',
        },
        text: {
            primary: '#2C2C2C',
            secondary: '#838383',
            disabled: '#A3A3A3',
        },
        info: {
            main: '#2196F3',
        },
    },
});


// const theme = createMuiTheme({
//     palette: {
//         primary: {
//             ultraLight: '#CBC8FF',
//             light: '#8982FF',
//             main: '#6C63FF',
//             dark: '#5A53D6',
//             ultraDark: '#25225D',
//         },
//         secondary: {
//             main: '#db4437',
//         },
//         background: {
//             page: '#F8FCFF',
//         },
//         error: {
//             main: '#F44336',
//         },
//         success: {
//             main: '#54B258',
//         },
//         text: {
//             primary: '#2C2C2C',
//             secondary: '#838383',
//             disabled: '#A3A3A3',
//         },
//         info: {
//             main: '#2196F3',
//         },
//     },
// });

export default theme;