import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralRequest } from '../../protos/accounts_pb';
import { AccountsServiceClient } from '../../protos/accounts_grpc_web_pb';
import { AccountsServerAddress } from '../../protos/server';
import GetMetadata from '../customComponents/getMetadata';

export function useGetProfile() {
    const [stat, setStat] = useState(2)
    const [profile, setProfile] = useState({})

    const userData = useSelector(state => state.userData)
    const profileData = useSelector(state => state.profileData)

    const dispatch = useDispatch()
    const accClient = new AccountsServiceClient(AccountsServerAddress, null, null)
    var md = GetMetadata()

    const fetchProfile = (token) => {
        const req = new GeneralRequest();
        md.authorization = token

        accClient.getProfile(req, md, (err, response) => {
            if (err) {
                console.log(err)
                setStat(-1)
            } else {
                const res = response.toObject()
                dispatch({ type: 'SET_PROFILE', profileData: res });
                setProfile(res)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        if (profileData.ueref === undefined) {
            fetchProfile(userData.token)
        } else {
            setProfile(profileData)
            setStat(0)
        }
    }, [])

    return [stat, profile]
}