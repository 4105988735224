import { makeStyles } from '@material-ui/core/styles';

export const oauthStyles = makeStyles((theme) => ({
    authorizePaper: {
        width: '400px',
        height: '150px',
        padding: '20px',
    },
    grid: {
        margin: 'auto',
        minHeight: '100vh',
        width: '100%',
    },
    authorizeFormPaper: {
        width: '100%',
        maxWidth: '600px',
        padding: '20px',
        minHeight: '100%',
        margin: '0 auto',
        textAlign: 'left',
    },
    logoCont: {
        height: '60px',
        textAlign: 'left',
        marginBottom: '10px',
        marginTop: 0,
    },
    logoHeader: {
        font: 'Serif',
        fontFamily: 'Times New Roman, Times, serif',
        fontSize: '270%',
        color: theme.palette.primary.main,
        marginTop: '-10px',
        marginBottom: '-7px',
    },
    logoSubHeader: {
        font: 'Serif',
        fontFamily: 'Times New Roman, Times, serif',
        fontSize: '115%',
        marginLeft: '3px',
        color: theme.palette.text.secondary,
    },
    appLogo: {
        height: '80px',
        width: '80px',
        margin: '30px 0 10px 0',
    },
    permMsg: {
        padding: '0',
    },
    permCont: {
        width: '100%',
        height: '350px',
        boxShadow: 'inset 0 0 5px ' + theme.palette.text.disabled,
        margin: '30px 0',
        padding: '20px 40px',
        overflowY: 'auto',
    },
    justGrid: {
        width: '100%',
    }
}));