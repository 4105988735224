import React from "react";
import { Card, CardContent, Typography, Button, Grid } from "@material-ui/core";
import { useStyles } from "./styles.js";


export default function RegisterStep5(props) {
	const classes = useStyles();

	const prevStep = () => {
		props.setStep(4);
	}

	const render = () => {
		return <Card className={classes.card}>
			<CardContent>
				<Typography variant="h6" color="textPrimary" gutterBottom>
					Oh oh!
				</Typography>
				<Typography variant="body1" color="textSecondary">
					Looks like something is wrong.
				</Typography>
			</CardContent>
			<CardContent>
				<Typography variant="body1" color="textPrimary">
					Check previous steps to see if you can spot anything wrong.
					<Typography variant="body1" align="center" color="textPrimary">
						<br />
						Or
					</Typography>
					<br />
					May be just the server is down
				</Typography>
			</CardContent>
			<CardContent>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Button
						color="primary"
						onClick={prevStep}
					>
						Previous
					</Button>
				</Grid>
			</CardContent>
		</Card>
	}

	return render()
}