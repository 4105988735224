import React, { useEffect, useState } from 'react';
import { useStyles } from '../styles';
import { Paper, Avatar, Grid, Typography, CircularProgress } from "@material-ui/core"
import { useDispatch, useSelector } from 'react-redux';
import { GeneralRequest } from '../../../../protos/insti_pb';
import { InstiServiceClient } from '../../../../protos/insti_grpc_web_pb';
import { InstiServerAddress } from '../../../../protos/server';
import GetMetadata from '../../../customComponents/getMetadata';
import { useGetProfile } from '../../../customHooks/useGetProfile'

export default function CollegeInfo() {
    const classes = useStyles()
    const [stat, profileData] = useGetProfile()
    const userData = useSelector(state => state.userData)
    const [collegeData, setCollegeData] = useState({})
    const collegeDataFromState = useSelector(state => state.collegeData)
    const dispatch = useDispatch()

    const getCollegeData = () => {
        if (profileData.institution !== undefined) {
            const instiClient = new InstiServiceClient(InstiServerAddress, null, null)
            const req = new GeneralRequest();
            req.setMessage(profileData.institution.eref)
            var md = GetMetadata()
            md.authorization = userData.token

            instiClient.getInstiBasicInfo(req, md, (err, response) => {
                if (err) {
                    console.log(err)
                } else {
                    const res = response.toObject()
                    setCollegeData(res)
                    dispatch({ type: 'SET_COLLEGE_DATA', collegeData: res })
                }
            })
        }
    }

    useEffect(() => {
        if (collegeDataFromState.name === undefined) {
            getCollegeData()
        } else {
            setCollegeData(collegeDataFromState)
        }
    }, [profileData])

    const renderData = () => {
        if (collegeData.name === undefined) {
            if (profileData.institution === undefined) {
                return <Typography variant="h5" color="textPrimary" gutterBottom align="left" >
                    Unknown
                </Typography>
            } else {
                return <>
                    <Typography variant="h5" color="textPrimary" gutterBottom align="left" >
                        {
                            profileData.instiname
                        }
                    </Typography>
                    <Typography variant="body2" color="textPrimary" align="left">
                        &nbsp;&nbsp;-&nbsp;&nbsp;{profileData.stream === undefined ? " " : profileData.stream.name}
                        <br />
                        &nbsp;&nbsp;-&nbsp;&nbsp;{profileData.field === undefined ? " " : profileData.field.name}
                    </Typography>
                </>
            }
        }
        return <>
            <Typography variant="h5" color="textPrimary" gutterBottom align="left" >
                {
                    collegeData.name + ",  " + collegeData.location
                }
            </Typography>
            <Typography variant="body2" color="textPrimary" align="left">
                &nbsp;&nbsp;-&nbsp;&nbsp;{profileData.stream === undefined ? " " : profileData.stream.name}
                <br />
                &nbsp;&nbsp;-&nbsp;&nbsp;{profileData.field === undefined ? " " : profileData.field.name}
            </Typography>
        </>
    }

    const render = () => {
        if (stat === 2) {
            return <CircularProgress size={30} />
        } else if (stat === 0) {
            return <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={3} lg={2}>
                    <center>
                        {
                            collegeData.logo ? <Avatar alt="Insti" src={collegeData.logo} className={classes.instiLogo} /> : <Avatar alt="Insti" className={classes.instiLogo} />
                        }
                    </center>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={10}>
                    {renderData()}
                    <br />
                </Grid>
            </Grid>
        } else {
            return <Typography variant="body1" color="textSecondary" >
                Unable to load data
            </Typography>
        }
    }

    return <Paper className={classes.paper}>
        {render()}
    </Paper>
}