import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Register from "./register";
import HomePage from "./home";
import AccountPage from "./account";
import ResetPassword from "./resetPass";
import PassLift from "./passLift";
import Mismatch from "./mismatch";
import VerifyEmail from "./verifyEmail";
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Grid, CircularProgress } from "@material-ui/core";
import theme from "./theme";
import Login from "./service66/login";
import Service66 from "./service66";
import { useSelector } from "react-redux";
import Authorize from "./service66/oauth/authorize";

export default function UserbaseRoutes() {
	let loggedIn = false;
	const stateLoggedIn = useSelector(state => state.loggedIn)
	if (stateLoggedIn === "running") {
		loggedIn = true
	}

	const render = () => {
		if (stateLoggedIn === "loading") {
			return <Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				<CircularProgress size={30} />
			</Grid>
		}
		return <BrowserRouter>
			<Switch>
				<Route path="/" exact>
					<HomePage />
				</Route>
				<Route path="/about" exact>
					About
				</Route>
				<Route path="/register" exact>
					<Register />
				</Route>
				<Route path="/account" exact>
					<AccountPage />
				</Route>
				<Route path="/resetPass" exact>
					<ResetPassword />
				</Route>
				<Route path="/passLift/:token/recovery/:qID/" exact>
					<PassLift />
				</Route>
				<Route path="/verifyAccount/:token/:ueref" exact>
					<VerifyEmail />
				</Route>
				<Route path="/service66/" exact>
					{loggedIn ? <Redirect to="/dashboard/home/" /> : <Login />}
				</Route>
				<Route path="/authorize/" exact>
					{loggedIn ? <Authorize /> : <Login />}
				</Route>
				<Route path="/dashboard/" exact>
					<Redirect to="/dashboard/home/" />
				</Route>
				<Route path="/dashboard/:param1/" exact>
					{loggedIn ? <Service66 /> : <Login />}
				</Route>
				<Route path="/dashboard/:param1/:param2/" exact>
					{loggedIn ? <Service66 /> : <Login />}
				</Route>
				<Route path="/dashboard/:param1/:param2/:param3/" exact>
					{loggedIn ? <Service66 /> : <Login />}
				</Route>
				{/* <Route path="/service66/" exact>
					{loggedIn ? <React.Fragment /> : <Login />}
				</Route> */}
				<Route path="/mismatch" exact>
					<Mismatch />
				</Route>
				<Route>
					<Mismatch />
				</Route>
			</Switch>
		</BrowserRouter>
	}
	return <React.Fragment>
		<ThemeProvider theme={theme}>
			<div className="App">
				<CssBaseline />
				{render()}
			</div>
		</ThemeProvider>
	</React.Fragment>
}